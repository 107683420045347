import React, { useEffect, type ReactElement } from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import { type TransactionsQueryVariables } from '../TransactionsComponent'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import DownloadIcon from '@/library/icons/DownloadIcon'
import ErrorInline from '@/library/errors/ErrorInline'
import useInitiateBrowserDownload, { BlobDownloadFileType } from '@/hooks/useInitiateBrowserDownload'
import useFileExport from '@/hooks/useFileExport'

interface TransactionDownloadModalProps {
  isOpen: boolean
  onClose: () => void
  queryVariables?: TransactionsQueryVariables
  totalTransactionCount?: number
  organizationId?: string
  isPageDataLoading?: boolean
}

export default function TransactionDownloadModal ({
  isOpen,
  onClose,
  queryVariables,
  totalTransactionCount,
  organizationId,
  isPageDataLoading
}: TransactionDownloadModalProps): ReactElement {
  const { createExportJob, fileDownloadUrl, error: fileExportError, loading: fileExportLoading } =
  useFileExport({
    transactionExportJobInput: {
      organizationId,
      sort: queryVariables?.sort,
      filter: queryVariables?.filter
    }
  })

  // Trigger Front End File Download
  const {
    setDownloadUrl,
    loading: localFileDownloadLoading,
    error: localFileDownloadError
  } = useInitiateBrowserDownload({
    fileType: BlobDownloadFileType.CSV,
    filename: 'Altir Transactions Export.csv'
  })

  useEffect(() => {
    if (isOpen && fileExportError == null) {
      createExportJob()
    }
  }, [isOpen])

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      size='sm'
    >
      <Flex flexDir='column' gap={8} alignItems='center' textAlign='center'>
        <Heading color={Color.DARK_BLUE} size='md'>
          Are you sure you want to download {totalTransactionCount} transactions?
        </Heading>
        <Flex flexDir='column' gap={4} w='100%'>
          <Button text='Download'
            beforeIcon={<DownloadIcon />}
            isLoading={localFileDownloadLoading || fileExportLoading || isPageDataLoading}
            onClick={() => { setDownloadUrl(fileDownloadUrl) }}
            isDisabled={fileExportError != null || localFileDownloadError != null}
          />
          <ErrorInline error={fileExportError ?? localFileDownloadError}/>
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={onClose}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
