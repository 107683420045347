import { gql } from '@apollo/client'

export const GET_TRANSFER_HISTORY = gql`
    query GetTransferHistory($franchiseGroupId: Int!) {
        transfers(franchiseGroupId: $franchiseGroupId) {
            id
            amount {
                amountFormatted
                currency
                amount
            }
            amplifyAccount {
                name
                lastFour
            }
            counterparty {
                name
                lastFour
                counterparty {
                    counterpartyType
                }
            }
            description
            direction
            error
            initiationType
            effectiveDate
            scheduledSettlementDate
            createdAt
            dateSettled
            status
            type
        }
    }

`
