import React, { useState, type ReactElement } from 'react'
import { Divider, Flex, Radio, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import DisclosureLine from './DisclosureLine'
import ErrorInline from '@/library/errors/ErrorInline'
import Button from '@/library/button/Button'
import {
  FIRST_BANK_CUSTOMER_AGREEMENT_URL,
  FIRST_BANK_TESTING_ADDENDUM_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL
} from '@/theme/urls'
import { Color } from '@/theme/theme'

interface DisclosuresAgreementComponentProps {
  onSubmit: () => void
  isLoading: boolean
  error?: Error
}

export default function DisclosuresFormComponent ({
  onSubmit,
  isLoading,
  error
}: DisclosuresAgreementComponentProps): ReactElement {
  const [hasVerifiedAgreement, setHasVerifiedAgreement] = useState(false)
  return (
    <Flex flexDir='column' gap={6} w='100%'>
      <Flex flexDir='column' gap={1} w='100%'>
        <DisclosureLine name='Customer Agreement (FirstBank)' link={FIRST_BANK_CUSTOMER_AGREEMENT_URL}/>
        <Divider borderColor={Color.MEDIUM_GREY}/>
        <DisclosureLine name='Testing Addendum (FirstBank)' link={FIRST_BANK_TESTING_ADDENDUM_URL}/>
        <Divider borderColor={Color.MEDIUM_GREY}/>
        <DisclosureLine name='Terms of Service (Altir Industries, Inc.)' link={TERMS_OF_SERVICE_URL}/>
        <Divider borderColor={Color.MEDIUM_GREY}/>
        <DisclosureLine name='Privacy Policy (Altir Industries, Inc.)' link={PRIVACY_POLICY_URL}/>
      </Flex>
      <Flex gap={4}>
        <Radio
          isChecked={hasVerifiedAgreement}
          onClick={() => { setHasVerifiedAgreement(!hasVerifiedAgreement) }}
          size='lg'
          colorScheme='selectableInput'
        />
        <Text>
          I have read and agree to the above documents.
        </Text>
      </Flex>
      <Flex flexDir='column' gap={4}>
        <Button
          text='Agree & Sign'
          isDisabled={!hasVerifiedAgreement}
          onClick={onSubmit}
          isLoading={isLoading}
          onClickEventType={Event.APPLICATION_DISCLOSURES_ACCEPTANCE_CLICK}
        />
        <ErrorInline error={error}/>
      </Flex>
    </Flex>
  )
}
