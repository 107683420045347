import React, { type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Center } from '@chakra-ui/react'
import TransferHistoryComponent from '../transfer/components/TransferHistoryComponent'
import { GET_TRANSFER_HISTORY } from '@/graphql/queries/GetTransferHistory'
import { type GetTransferHistoryVariables, type GetTransferHistory } from '@/graphql/__generated__/GetTransferHistory'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function TransferHistoryPage (): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)

  const {
    loading: transferHistoryLoading,
    data: transferHistoryData,
    error: transferHistoryError
  } = useQuery<GetTransferHistory, GetTransferHistoryVariables>(GET_TRANSFER_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      franchiseGroupId: selectedFranchiseGroupId
    }
  })

  return (
    <AltirSkeleton
      isLoading={transferHistoryLoading}
      error={transferHistoryError}
    >
      <Center flexDir='column' w='100%'>
        <TransferHistoryComponent transfers={transferHistoryData?.transfers ?? []}/>
      </Center>
    </AltirSkeleton>
  )
}
