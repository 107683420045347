import { gql } from '@apollo/client'

export const GET_AMPLIFY_PROMOTIONAL_DATA = gql`
    query GetAmplifyPromotionalData {
        amplifyAccountPromotionalOffer {
            subscribedAnnualPercentageYieldFormatted
            unsubscribedAnnualPercentageYieldFormatted
        }
    }
`
