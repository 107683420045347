const ALTIR_CHECKING_ACCOUNT_DESCRIPTION = `
    Through our banking partner, Altir offers you access to a checking account that earns up to 4.00% APY, backed by expanded FDIC insurance.
`

const ALTIR_IS_NOT_A_BANK = `
    Altir is a financial technology company, not a FDIC Insured Depository Institution. Banking Services provided by FirstBank, a Tennessee Corporation, Member FDIC. 
`

const APY_DISCLAIMER = `
    Annual Percentage Yield (APY) of 4.00% is effective as of 7/23/2023. This is a variable rate and may change after the account is opened. There is no minimum opening deposit or minimum average balance required to earn the stated Annual Percentage Yield. Fees could affect earnings on the account.
`

const APY_DISCLAIMER_FOOT_NOTE = `¹${APY_DISCLAIMER}`
const APY_DISCLAIMER_ASTERISK = `*${APY_DISCLAIMER}`

const INTRAFI_DISCLAIMER = `
    Deposit placement through ICS is subject to the terms, conditions, and disclosures in the applicable agreements. Although deposits are placed in increments that do not exceed the FDIC standard maximum deposit insurance amount (“SMDIA”) at any one destination bank, a depositor’s balances at the institution that places deposits may exceed the SMDIA (e.g., before settlement for deposits or after settlement for withdrawals) or be uninsured (if the placing institution is not an insured bank). The depositor must make any necessary arrangements to protect such balances consistent with applicable law and must determine whether placement through ICS satisfies any restrictions on its deposits. A list identifying IntraFi network banks appears at https://www.intrafi.com/network-banks. The depositor may exclude banks from eligibility to receive its funds. IntraFi and ICS are registered service marks, and IntraFi Cash Service is a service mark, of IntraFi Network LLC.
`

export const DisclosureText = {
  ALTIR_CHECKING_ACCOUNT_DESCRIPTION,
  ALTIR_IS_NOT_A_BANK,
  APY_DISCLAIMER_ASTERISK,
  APY_DISCLAIMER_FOOT_NOTE,
  INTRAFI_DISCLAIMER
}
