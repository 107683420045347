import { Box, Flex } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { Color } from '@/theme/theme'
import { CounterpartyType, TransferStatus } from '@/graphql/__generated__/globalTypes'

export interface TransferStatusTagProps {
  status: TransferStatus | null
  counterpartyType?: CounterpartyType
}
export default function TransferStatusTag ({ status, counterpartyType }: TransferStatusTagProps): ReactElement | null {
  if (status == null) return null
  const color = getColor(status, counterpartyType)
  return (
    <Flex
      color={color}
      alignItems='center'
      justifyContent='center'
      display='inline-flex'
    >
      <Box marginRight={2} bg={color} h={3} w={3} borderRadius='100%'/>
      {getStatusDescription(status, counterpartyType)}
    </Flex>
  )
}

function getColor (status: TransferStatus, counterpartyType?: CounterpartyType): string {
  switch (status) {
    case TransferStatus.RECEIVED:
    case TransferStatus.CHECK_CLEARED:
      return Color.SUCCESS_GREEN
    case TransferStatus.CREATED:
    case TransferStatus.PENDING:
    case TransferStatus.PROCESSING:
    case TransferStatus.AWAITING_BALANCE:
    case TransferStatus.CHECK_EXPIRED:
    case TransferStatus.CHECK_STOP_PAYMENT_PENDING:
      return Color.DARK_GREY
    case TransferStatus.SENT:
      return isPlaidCounterparty(counterpartyType) ? Color.DARK_GREY : Color.SUCCESS_GREEN
    case TransferStatus.CANCELED:
    case TransferStatus.ERROR:
    case TransferStatus.RETURNED:
    case TransferStatus.RETURN_SETTLED:
    case TransferStatus.UNKNOWN:
    case TransferStatus.CHECK_STOP_PAYMENT:
      return Color.ERROR_RED
  }
}

function getStatusDescription (status: TransferStatus, counterpartyType?: CounterpartyType): string {
  switch (status) {
    case TransferStatus.RECEIVED:
    case TransferStatus.CHECK_CLEARED:
      return 'Complete'
    case TransferStatus.CREATED:
    case TransferStatus.PENDING:
    case TransferStatus.PROCESSING:
    case TransferStatus.AWAITING_BALANCE:
      return 'Pending'
    case TransferStatus.SENT:
      return isPlaidCounterparty(counterpartyType) ? 'Pending' : 'Complete'
    case TransferStatus.CANCELED:
      return 'Canceled'
    case TransferStatus.ERROR:
    case TransferStatus.UNKNOWN:
      return 'Error'
    case TransferStatus.RETURNED:
    case TransferStatus.RETURN_SETTLED:
      return 'Returned'
    case TransferStatus.CHECK_EXPIRED:
      return 'Expired'
    case TransferStatus.CHECK_STOP_PAYMENT:
      return 'Stop Payment'
    case TransferStatus.CHECK_STOP_PAYMENT_PENDING:
      return 'Stop Payment Pending'
  }
}

function isPlaidCounterparty (counterpartyType?: CounterpartyType): boolean {
  return counterpartyType === CounterpartyType.PLAID
}
