import React, { type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import TransferComponent from './TransferComponent'
import RecipientsCard from './recipients/RecipientsCard'
import AmplifyInfoComponent from './amplify_info/AmplifyInfoComponent'
import CounterpartiesPendingVerificationCard from './recipients/CounterpartiesPendingVerificationCard'
import CreatedAutomatedTransferButton from './CreateAutomatedTransferButton'
import ViewAutomatedTransferRulesCard from './ViewAutomatedTransferRulesCard'
import { type Account } from '@/types/types'
import PasscodeCreationModal from '@/library/modal/passcode/PasscodeCreationModal'
import {
  type GetCounterpartiesPendingVerification,
  type GetCounterpartiesPendingVerificationVariables
} from '@/graphql/__generated__/GetCounterpartiesPendingVerification'
import { GET_COUNTERPARTIES_PENDING_VERIFICATION } from '@/graphql/queries/GetRecipientsPageData'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'

interface TransferContainerComponentProps {
  franchiseGroupId: number
  amplifyAccount?: Account
  counterparties?: Account[]
  doesUserHavePasscode?: boolean
  isRecurringVendorPaymentsEnabled: boolean
  refetch: () => void
}

export default function TransferContainerComponent ({
  franchiseGroupId,
  amplifyAccount,
  counterparties,
  doesUserHavePasscode,
  isRecurringVendorPaymentsEnabled,
  refetch
}: TransferContainerComponentProps): ReactElement {
  const {
    data: unverifiedCounterpartyData,
    loading: unverifiedCounterpartyLoading,
    error: unverifiedCounterpartyError,
    refetch: refetchUnverifiedCounterpartyData
  } = useQuery<GetCounterpartiesPendingVerification, GetCounterpartiesPendingVerificationVariables>(
    GET_COUNTERPARTIES_PENDING_VERIFICATION,
    {
      variables: {
        franchiseGroupId,
        isOwnershipVerified: false,
        counterpartyTypes: [CounterpartyType.EXTERNAL_PERSONAL, CounterpartyType.PLAID]
      },
      fetchPolicy: 'network-only'
    }
  )

  function refetchAllData (): void {
    refetch()
    void refetchUnverifiedCounterpartyData()
  }

  return (
    <Flex gap={6}>
      {doesUserHavePasscode === false && <PasscodeCreationModal/>}
      <TransferComponent
        franchiseGroupId={franchiseGroupId}
        amplifyAccount={amplifyAccount}
        counterparties={counterparties}
        isRecurringVendorPaymentsEnabled={isRecurringVendorPaymentsEnabled}
        refetch={refetchAllData}
      />
      <Flex flexDir='column' gap={6}>
        <BusinessSelectorComponent
          businessFilterParams={{ hasAmplify: true }}
          requireMultipleBusinesses={true}
        />
        <Flex flexDir='column' gap={3}>
          <Text>Transfer Settings</Text>
          <ViewAutomatedTransferRulesCard/>
          <AmplifyInfoComponent/>
          <RecipientsCard
            amplifyAccountId={amplifyAccount?.amplifyAccountId ?? undefined}
            franchiseGroupId={franchiseGroupId}
            onNewRecipientCreation={refetchAllData}
          />
          <CounterpartiesPendingVerificationCard
            data={unverifiedCounterpartyData}
            loading={unverifiedCounterpartyLoading}
            error={unverifiedCounterpartyError}
          />
          <CreatedAutomatedTransferButton/>
        </Flex>
      </Flex>
    </Flex>
  )
}
