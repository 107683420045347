import React, { useState, type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex, Link } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { Event } from 'metrics/metrics'
import HistoricalAccountBalanceComponent from './components/balance/HistoricalAccountBalanceComponent'
import InterestCard from './components/interest/InterestCard'
import InterestCalculatorCard from './components/interest/interest_calculator/InterestCalculatorCard'
import AmplifyRecentTransactionsComponent from './components/transactions/AmplifyRecentTransactionsComponent'
import ChecksCTAComponent from './components/checks/CheckCTAComponent'
import FinancialAccountDetailComponent from '../../library/accounts/FinancialAccountDetailComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import HeaderStatistic from '@/library/text/HeaderStatistic'
import IconCircleButton from '@/library/button/IconCircleButton'
import TransferIcon from '@/library/icons/TransferIcon'
import { Color, IconSize } from '@/theme/theme'
import { ROUTES } from '@/api/routes'
import ClockIcon from '@/library/icons/ClockIcon'
import MetricCard, { MetricCardVariant } from '@/library/card/MetricCard'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import {
  type GetAccountDetailsPageData,
  type GetAccountDetailsPageDataVariables
} from '@/graphql/__generated__/GetAccountDetailsPageData'
import { GET_ACCOUNT_DETAILS_PAGE_DATA } from '@/graphql/queries/amplify_account/GetAccountDetailsPageData'
import { isTreasureEnabled } from '@/utils/featureFlags'
import { EMPTY_BALANCE_VALUE } from '@/utils/constants'
import Page from '@/library/page/Page'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'
import { AuthResource } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function AmplifyAccountDetailPage (): ReactElement {
  const navigate = useNavigate()
  const { ACCOUNT_ID: id } = useParams()
  const [error, setError] = useState<Error | undefined>(undefined)
  const { selectedFranchiseGroupId, selectedOrganizationId } = useAltirStore(state => {
    return {
      selectedFranchiseGroupId: state.selectedFranchiseGroupId,
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId
    }
  })
  const { data, loading, error: fetchError } = useQuery<GetAccountDetailsPageData, GetAccountDetailsPageDataVariables>(
    GET_ACCOUNT_DETAILS_PAGE_DATA,
    {
      variables: {
        accountId: Number(id),
        franchiseGroupId: selectedFranchiseGroupId,
        organizationId: selectedOrganizationId
      }
    }
  )

  function goToStatement (): void {
    navigate(`${ROUTES.TREASURY}/${id ?? ''}`)
  }

  if (id === undefined) setError(Error('Id not provided'))

  // Authorization Checks
  const canTransfer = hasAccess(AuthResource.AMPLIFY_ACCOUNT, AuthAction.WRITE, data?.currentUser?.authorization ?? [])

  const availableBalance = data?.financialAccount.liveBalance?.availableBalance
  const currentBalance = data?.financialAccount.liveBalance?.currentBalance

  return (
    <Page>
      <AltirSkeleton isLoading={loading} error={fetchError ?? error}>
        <Flex flexDir='column' gap={6}>
          <Flex justifyContent='space-between'>
            <Flex gap={8} alignItems='start'>
              <HeaderStatistic
                description='Available Balance'
                descriptionInfoText='Funds you have access to right now'
                statistic={availableBalance?.amountFormatted ?? EMPTY_BALANCE_VALUE}
              />
              <HeaderStatistic description='Current Balance'
                statistic={currentBalance?.amountFormatted ?? EMPTY_BALANCE_VALUE}
              />
            </Flex>
            <Flex>
              <Flex>
                <IconCircleButton
                  icon={<TransferIcon/>}
                  color={Color.WHITE}
                  backgroundColor={Color.BRIGHT_BLUE}
                  hoverBackgroundColor={Color.DARK_BLUE}
                  text='Transfer'
                  onClick={() => { navigate(ROUTES.TRANSFER) }}
                  disabledReasonText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                />
                <IconCircleButton
                  icon={<ClockIcon/>}
                  color={Color.DARK_BLUE}
                  backgroundColor={Color.WHITE}
                  hoverBackgroundColor={Color.GREY}
                  text='Transfer History'
                  onClick={() => { navigate(ROUTES.TRANSFER_HISTORY) }}
                  disabledReasonText={!canTransfer ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={6}>
            <Flex flexDir='column' flex={1} w='100%' gap={6}>

              <FinancialAccountDetailComponent
                accountId={Number(id)}
                statementSelection={null}
              />
              <Flex w='100%' flexDir='column' gap={4}>
                <Button
                  text='View Statements'
                  variant={ButtonVariant.WHITE_OUTLINE}
                  afterIcon={<RightArrowIcon/>}
                  onClick={goToStatement}
                  onClickEventType={Event.VIEW_STATEMENT_CLICK}
                />
                <ChecksCTAComponent accountId={Number(id)}/>
              </Flex>
            </Flex>
            <Flex flexDir='column' flex={2} mt={8} gap={8}>
              <Flex gap={4}>
                <Flex flex={1}>
                  <InterestCard accountId={Number(id)}/>
                </Flex>
                <Flex flex={1}>
                  <InterestCalculatorCard
                    accountId={Number(id)}
                    currentAccountBalance={availableBalance?.amount ?? undefined}
                  />
                </Flex>
                {isTreasureEnabled() &&
                  <MetricCard
                    label='Open a Money Market Account with'
                    metric='5.5% APY'
                    variant={MetricCardVariant.CTA}
                  >
                    <Flex mt={4}>
                      <Link href='https://www.treasurefi.com/' isExternal>
                        <Button
                          text='Visit our Partner'
                          afterIcon={<RightArrowIcon size={IconSize.SMALL}/>}
                          size={ButtonSize.SMALL}
                          onClickEventType={Event.TREASURE_LINK_CLICK}
                        />
                      </Link>
                    </Flex>
                    </MetricCard>
                }
              </Flex>
              <HistoricalAccountBalanceComponent accountId={Number(id)}/>
              <Flex flexDir='column' gap={4}>
                <AmplifyRecentTransactionsComponent accountId={Number(id)}/>
                <Button
                  text='View All'
                  variant={ButtonVariant.WHITE_OUTLINE}
                  afterIcon={<RightArrowIcon/>}
                  onClick={goToStatement}
                  onClickEventType={Event.VIEW_ALL_AMPLIFY_TRANSACTION_CLICK}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Page>
  )
}
