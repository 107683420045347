import { Center, Flex } from '@chakra-ui/react'
import React, { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AvailableStatementsComponent from './AvailableStatmentsComponent'
import StatementTransactionListComponent, { type CustomStatement } from './StatementTransactionListComponent'
import AccountActivityComponent from './AccountActivityComponent'
import { ROUTES } from '@/api/routes'
import FinancialAccountDetailComponent from '@/library/accounts/FinancialAccountDetailComponent'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { ReLinkErrorBanner } from '@/library/errors/ReLinkErrorBanner'
import TransferIcon from '@/library/icons/TransferIcon'
import ListIcon from '@/library/icons/ListIcon'
import { GET_STATEMENT_PAGE_METADATA } from '@/graphql/queries/statements/GetStatementPageMetadata'
import {
  type GetStatementPageMetadataVariables,
  type GetStatementPageMetadata
} from '@/graphql/__generated__/GetStatementPageMetadata'
import {
  type GetAvailableStatements_availableStatements as Statement
} from '@/graphql/__generated__/GetAvailableStatements'
import { IconSize } from '@/theme/theme'
import {
  AccountLinkStatus,
  InstitutionConnectionProvider,
  type LinkedAccountType
} from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export interface TreasuryDetailComponentProps {
  accountType: LinkedAccountType
  accountId: number
  isModalOpen: boolean
  toggle: () => void
  customStatement: CustomStatement
  setCustomStatement: (statement: CustomStatement) => void
}

function AccountStatementComponent (
  {
    accountType,
    accountId,
    isModalOpen,
    toggle,
    customStatement,
    setCustomStatement
  }: TreasuryDetailComponentProps
): ReactElement<string, string> {
  const navigate = useNavigate()
  const franchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const { data, loading, error, refetch } = useQuery<GetStatementPageMetadata, GetStatementPageMetadataVariables>(
    GET_STATEMENT_PAGE_METADATA, { variables: { accountId } }
  )
  const [
    selectedStatement,
    setSelectedStatement
  ] = useState<Statement | null>(null)

  const isAmplifyAccount = data?.financialAccount.amplifyAccount?.id != null
  const needsPlaidReLink = data
    ?.financialAccount
    .connectionProvider === InstitutionConnectionProvider.PLAID &&
    data?.financialAccount?.status !== AccountLinkStatus.HEALTHY
  // TODO PAL: Fix queries to ensure the reLink banner can directly fix link
  const plaidAccessToken = data?.financialAccount?.plaidAccessToken ?? undefined
  const institutionName = data?.financialAccount.institution?.name ?? undefined
  return (
    <AltirSkeleton isLoading={loading} error={error}>
      <Center flexDir='column' gap={6} w='100%'>
        {needsPlaidReLink &&
          <ReLinkErrorBanner
            franchiseGroupId={franchiseGroupId}
            plaidAccessToken={plaidAccessToken}
            refetchData={refetch}
            institutionName={institutionName}
          />
        }
        <Flex
          width='100%'
          flexDirection='row'
          gap={6}
        >
          <Flex
            flexDirection='column'
            flex={1}
            gap={6}
          >
            <FinancialAccountDetailComponent
              accountId={accountId}
              statementSelection={selectedStatement}
            />
            <AccountActivityComponent
              accountId={accountId}
              accountType={accountType}
              statementSelection={selectedStatement}
              isAmplifyAccount={isAmplifyAccount}
            />
            <Flex flexDir='column' gap={4}>
              <Button
                text='Transfer'
                onClick={() => { navigate(ROUTES.TRANSFER) }}
                beforeIcon={<TransferIcon/>}
                size={ButtonSize.MEDIUM}
                onClickEventType={Event.OPEN_TRANSFER_PAGE_CLICK}
              />
              <Button
                text='Transfer History'
                onClick={() => { navigate(ROUTES.TRANSFER_HISTORY) }}
                beforeIcon={<ListIcon size={IconSize.SMALL}/>}
                variant={ButtonVariant.WHITE_OUTLINE}
                onClickEventType={Event.VIEW_ALL_TRANSFERS_CLICK}
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection='column'
            flex={2}
            overflow='auto'
          >
            <AvailableStatementsComponent
              accountId={accountId}
              setSelectedStatement={setSelectedStatement}
              isModalOpen={isModalOpen}
              toggle={toggle}
              setCustomStatement={setCustomStatement}
            />
            <StatementTransactionListComponent
              accountId={accountId}
              selectedStatement={selectedStatement}
              customStatement={customStatement}
              shouldIncludePending={isAmplifyAccount}
            />
          </Flex>
        </Flex>
      </Center>
    </AltirSkeleton>
  )
}

export default AccountStatementComponent
