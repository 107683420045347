import React, { type ReactElement, type ReactNode } from 'react'
import { Badge as BaseBadge, type BadgeProps as BaseBadgeProps } from '@chakra-ui/react'
import { FontWeight, Color } from '@/theme/theme'

export enum BadgeVariant {
  OUTLINE,
  GREY
}

interface BadgeProps extends BaseBadgeProps {
  children: ReactNode
  badgeVariant?: BadgeVariant
}

function getStyleProps (variant: BadgeVariant): BaseBadgeProps {
  switch (variant) {
    case BadgeVariant.OUTLINE:{
      return {
        bg: Color.WHITE,
        color: Color.DARK_GREY,
        border: '1px',
        borderColor: Color.DARK_GREY,
        fontWeight: FontWeight.MEDIUM
      }
    }
    case BadgeVariant.GREY:
    default :{
      return {
        bg: Color.LIGHT_GREY,
        color: Color.DARK_GREY,
        fontWeight: FontWeight.MEDIUM
      }
    }
  }
}

export default function Badge ({ children, badgeVariant = BadgeVariant.GREY, ...props }: BadgeProps): ReactElement {
  const styleProps = getStyleProps(badgeVariant)
  return (
    <BaseBadge
      justifyContent='center'
      textTransform='none'
      fontSize='sm'
      px={3}
      {...styleProps}
      {...props}
    >
      {children}
    </BaseBadge>
  )
}
