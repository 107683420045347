import React, { type ReactElement } from 'react'
import { Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import BalanceRuleConfirmationCard from './BalanceRuleConfirmationCard'
import TimeRuleConfirmationCard from './TimeRuleConfirmationCard'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import { DELETE_TRANSFER_RULE } from '@/graphql/mutations/DeleteTransferRule'
import { type DeleteTransferRule, type DeleteTransferRuleVariables } from '@/graphql/__generated__/DeleteTransferRule'
import { type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule }
  from '@/graphql/__generated__/GetTransferRules'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { getFormattedBalanceRule, getFormattedTimeRule, isBalanceRule } from '@/utils/transferRuleUtils'
import { type ReactivateTransferRule, type ReactivateTransferRuleVariables }
  from '@/graphql/__generated__/ReactivateTransferRule'
import { REACTIVATE_TRANSFER_RULE } from '@/graphql/mutations/ReactivateTransferRule'
import { TransferRuleType } from '@/graphql/__generated__/globalTypes'

export enum ActivationDirection {
  ReActivate = 'Re-Activate',
  Deactivate = 'Deactivate'
}

export interface TransferRuleActivationModalProps {
  transferRule: TransferRule
  isModalOpen: boolean
  onModalClose: () => void
  onUpdate: () => void
  activationDirection: ActivationDirection
}

export default function TransferRuleActivationModal (
  { transferRule, isModalOpen, onModalClose, onUpdate, activationDirection }: TransferRuleActivationModalProps
): ReactElement {
  const toast = useToast()

  const formattedBalanceRule = isBalanceRule(transferRule.transferRuleType)
    ? getFormattedBalanceRule(transferRule)
    : undefined

  const formattedTimeRule = !isBalanceRule(transferRule.transferRuleType)
    ? getFormattedTimeRule(transferRule)
    : undefined
  const [
    deleteTransferMutation,
    { loading: deactivateLoading }
  ] = useMutation<DeleteTransferRule, DeleteTransferRuleVariables>(DELETE_TRANSFER_RULE, {
    onCompleted: (_data) => {
      onUpdate()
      toast(getSuccessToast('Successfully deactivated transfer rule'))
      onModalClose()
    },
    onError: (_error) => {
      toast(getErrorToast('Something went wrong. Please try again.'))
      onModalClose()
    }
  })

  // TODO - consolidate
  const [
    reactivateTransferMutation,
    { loading: reactivateLoading }
  ] = useMutation<ReactivateTransferRule, ReactivateTransferRuleVariables>(REACTIVATE_TRANSFER_RULE, {
    onCompleted: (_data) => {
      onUpdate()
      toast(getSuccessToast('Successfully reactivated transfer rule'))
      onModalClose()
    },
    onError: (_error) => {
      toast(getErrorToast('Something went wrong. Please try again.'))
      onModalClose()
    }
  })

  return (
    <ModalComponent
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      <Flex flexDir='column' w='100%' gap={6}>
        <Heading textAlign='center'
          size='lg'
          color={Color.DARK_BLUE}
          whiteSpace='pre-line'
        >
          {`Are you sure you want to\n${activationDirection} this rule?`}
        </Heading>
        <Text textAlign='center'>
          Note that all in-flight transfers will still be executed.
        </Text>
        {formattedBalanceRule != null &&
        (transferRule.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE ||
        transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE) &&
        <BalanceRuleConfirmationCard
          to={formattedBalanceRule.to}
          from={formattedBalanceRule.from}
          balanceThreshold={formattedBalanceRule.balance}
          type={transferRule.transferRuleType}
        />
        }
        {(formattedTimeRule != null) &&
        <TimeRuleConfirmationCard
          to={formattedTimeRule.to}
          from={formattedTimeRule.from}
          frequency={transferRule.transferRuleType}
          amount={formattedTimeRule.amount}
          dayOfWeek={formattedTimeRule.dayOfWeek}
          dateOfMonth={formattedTimeRule.dateOfMonth}
          startDate={formattedTimeRule.ruleStartDate}
        />
        }

        <Flex gap={3} direction='column'>
          <Button text={`${activationDirection} Rule`}
            variant={ButtonVariant.DESTRUCTIVE}
            onClick={() => {
              if (activationDirection === ActivationDirection.Deactivate) {
                void deleteTransferMutation({ variables: { transferRuleId: transferRule.id } })
              } else {
                void reactivateTransferMutation({ variables: { transferRuleId: transferRule.id } })
              }
            }}
            isLoading={deactivateLoading || reactivateLoading}
          />
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { onModalClose() }}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}
