import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import RoleSelector from '../business_settings/team_settings/RoleSelector'
import ErrorInline from '@/library/errors/ErrorInline'
import { FormInput } from '@/library/form/text/FormInput'
import { type CreateInviteVariables, type CreateInvite } from '@/graphql/__generated__/CreateInvite'
import { CREATE_INVITE } from '@/graphql/mutations/AddNewFranchiseMember'
import { getSuccessToast } from '@/utils/toastUtils'
import { ErrorCopy, getErrorCode, isEmailAlreadyExistsError } from '@/utils/errorUtils'
import { isEmpty } from '@/utils/stringUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import CheckBoxSelectGroup, { type SelectItem } from '@/library/form/checkbox/CheckBoxSelectGroup'
import {
  type GetOrganizationSettings_currentUser_selectedOrganization_franchiseGroups as Business
} from '@/graphql/__generated__/GetOrganizationSettings'
import { getSelectedEntryIds } from '@/library/form/checkbox/utils'
import { GraphQLErrorCode, type Role } from '@/graphql/__generated__/globalTypes'
import { nonNull } from '@/utils/arrayUtils'
import { type ErrorWithContent } from '@/types/types'

const INVITE_USER_TEXT = `
  You can update these preferences later in Settings.
`

interface InviteUserModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  isOrganizationsEnabled: boolean
  organizationId: string
  franchiseGroupId: number | null
  organizationBusinesses: Business[]
}

export default function InviteUserModal ({
  isOpen,
  onClose,
  onSuccess,
  isOrganizationsEnabled,
  organizationId,
  franchiseGroupId,
  organizationBusinesses
}: InviteUserModalProps): ReactElement {
  const toast = useToast()
  // Form variables
  const [invitedUserEmail, setInvitedUserEmail] = useState<string | null>()
  const [inviteUserSubmissionError, setInviteUserSubmissionError] = useState<ErrorWithContent | undefined>()
  const [invitedUserRole, setInvitedUserRole] = useState<Role | undefined>()

  const [
    createInvite,
    { loading: isCreateInviteLoading }
  ] = useMutation<CreateInvite, CreateInviteVariables>(
    CREATE_INVITE, {
      onCompleted: () => {
        onSuccess()
        handleClose()
        toast(getSuccessToast('User Invited'))
      },
      onError: (error) => {
        if (isEmailAlreadyExistsError(error)) {
          setInviteUserSubmissionError({
            customContent: {
              title: 'Unable to invite user',
              subtitle: 'A user with that email has already been invited to Altir.'
            },
            error
          })
        } else {
          setInviteUserSubmissionError({
            customContent: {
              title: ErrorCopy.SOMETHING_WENT_WRONG,
              subtitle: getUserInviteErrorMessage(error, invitedUserEmail)
            },
            error
          })
        }
      }
    })

  const [businessSelections, setBusinessSelections] = useState<Record<string, SelectItem>>(
    createBusinessCheckboxOptions(organizationBusinesses)
  )

  function onInviteEmailChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target
    setInvitedUserEmail(value)
  }

  function handleClose (): void {
    setInvitedUserEmail(null)
    setInviteUserSubmissionError(undefined)
    setInvitedUserRole(undefined)
    onClose()
  }

  function handleUserInviteSubmission (): void {
    const businessIds = isOrganizationsEnabled
      ? getSelectedEntryIds(businessSelections).map(id => Number(id))
      : nonNull([franchiseGroupId])
    if (invitedUserRole == null || invitedUserEmail == null) {
      setInviteUserSubmissionError({
        customContent: {
          title: ErrorCopy.SOMETHING_WENT_WRONG,
          subtitle: 'Unable to invite user'
        },
        error: Error()
      })
      return
    }
    void createInvite({
      variables: {
        deprecatedFranchiseGroupID: franchiseGroupId,
        input: {
          email: invitedUserEmail,
          role: invitedUserRole,
          organizationId,
          businessIds
        }
      }
    })
  }

  const isFormValidForSubmission = invitedUserRole != null && !isEmpty(invitedUserEmail)

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleClose}
      size='sm'
    >
      <Flex flexDir='column' gap={8} alignItems='center' w='100%'>
        <Flex flexDir='column' alignItems='center' gap={1}>
          <Heading>
            Invite a New User
          </Heading>
          <Text>{INVITE_USER_TEXT}</Text>
        </Flex>
        <Flex flexDir='column' gap={4} w='100%'>
          <FormInput
            fieldName='invitedUserEmailAddress'
            value={invitedUserEmail ?? ''}
            label='Email Address'
            onChange={onInviteEmailChange}
            placeholder='i.e. example@mail.com'
          />
          <RoleSelector selectedRole={invitedUserRole} onChange={(role) => { setInvitedUserRole(role) }}/>
        </Flex>
        {isOrganizationsEnabled &&
          <Flex flexDir='column' w='100%' alignItems='start' gap={4}>
            <Text>Select all businesses the user can access</Text>
            <CheckBoxSelectGroup
              options={businessSelections}
              setOptions={(options) => { setBusinessSelections(options) }}
            />
          </Flex>
        }
        <Flex flexDir='column' gap={4} alignItems='center' w='100%'>
          <ErrorInline error={inviteUserSubmissionError}/>
          <Button
            text='Invite New User'
            onClick={handleUserInviteSubmission}
            isLoading={isCreateInviteLoading}
            isDisabled={!isFormValidForSubmission}
          />
          <Button text='Cancel' onClick={() => { handleClose() }} variant={ButtonVariant.WHITE}/>
        </Flex>
      </Flex>
    </ModalComponent>
  )
}

function getUserInviteErrorMessage (error: ApolloError, email?: string | null): string {
  const errorCode = getErrorCode(error)
  switch (errorCode) {
    case GraphQLErrorCode.EMAIL_ALREADY_EXISTS:
      return 'Oops! This user has already been invited.'
    case GraphQLErrorCode.AUTHORIZATION_ERROR:
      return "You don't have permission to invite new users." +
            'Reach out to the account administrator or contact us for help.'
    default: {
      if (isEmpty(email)) return 'Please enter an email address.'
      return `Unable to invite ${String(email)}`
    }
  }
}

function createBusinessCheckboxOptions (businesses: Business[]): Record<string, SelectItem> {
  return businesses.reduce<Record<string, SelectItem>>((record, business) => {
    if (business.name != null) {
      record[`${business.id}`] = { description: business.name, isSelected: true }
    }
    return record
  }, {})
}
