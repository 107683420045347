import React from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { useState, type ReactElement } from 'react'
import { useMutation } from '@apollo/client'
import AltirConnectInfo from './AltirConnectInfo'
import { ModalComponent } from '../ModalComponent'
import { PlaidComponent } from '@/components/clients/plaid/PlaidComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import TellerConnect from '@/components/clients/teller/TellerConnect'
import ErrorInline from '@/library/errors/ErrorInline'
import MenuIconHorizontal from '@/library/icons/MenuIconHorizontal'
import SupportEmailInlineLink from '@/library/text/SupportEmailInlineLink'
import { Graphic } from '@/library/utility/Graphic'
import { ALTIR_LOGO_BLUE, PLAID_LOGO } from '@/theme/graphics'
import { Color } from '@/theme/theme'
import { type ErrorWithContent } from '@/types/types'
import { type RequestAltirConnect } from '@/graphql/__generated__/RequestAltirConnect'
import { REQUEST_ALTIR_CONNECT } from '@/graphql/mutations/RequestAltirConnect'

interface InstitutionConnectionModalProps {
  isOpen: boolean
  onClose: () => void
  franchiseGroupId: number
  handleBankAccountLink: () => void
}

export default function InstitutionConnectionModal ({
  isOpen,
  onClose,
  franchiseGroupId,
  handleBankAccountLink
}: InstitutionConnectionModalProps): ReactElement {
  const [isViewingAltirConnect, setIsViewingAltirConnect] = useState(false)
  const [linkError, setLinkError] = useState<ErrorWithContent | undefined>()
  const [
    requestAltirConnect,
    { loading: isAltirConnectRequestLoading }
  ] = useMutation<RequestAltirConnect>(
    REQUEST_ALTIR_CONNECT,
    {
      onCompleted: () => { setIsViewingAltirConnect(true) },
      onError: () => {}
    }
  )

  function handleModalClose (): void {
    onClose()
    setLinkError(undefined)
    setIsViewingAltirConnect(false)
  }

  // TODO PAL Make error messaging for both Teller and Plaid link issues
  function handleError (error: ErrorWithContent): void {
    setLinkError(error)
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleModalClose}
      trapFocus={false}
      size='sm'
    >
      {!isViewingAltirConnect
        ? <Flex flexDir='column' alignItems='center' textAlign='center' w='100%' gap={8}>
          <Flex justifyContent='center' alignItems='center' gap={2}>
            <Flex borderRadius='100%' bg={Color.WHITE} p={4}>
              <Graphic src={ALTIR_LOGO_BLUE} h='40px'/>
            </Flex>
            <MenuIconHorizontal color={Color.DARK_GREY}/>
            <Flex borderRadius='100%' bg={Color.WHITE} p={4}>
              <Graphic src={PLAID_LOGO} h='40px'/>
            </Flex>
          </Flex>
          <Flex flexDir='column' gap={2} w='100%'>
            <Heading color={Color.DARK_BLUE} size='lg'>Link your Accounts</Heading>
            <Text>
              Altir uses Plaid to securely link your accounts.
              If your institution is incompatible with Plaid,
              you can try again using Teller.
            </Text>
          </Flex>
          <Flex flexDir='column' gap={4} w='100%'>
            <TellerConnect
              franchiseGroupId={franchiseGroupId}
              onSuccess={handleBankAccountLink}
              onError={handleError}
              customButton={
                <Button
                  text='Link with Teller'
                  onClickEventType={Event.TELLER_LINK_OPEN_CLICK}
                />
              }
            />
            <PlaidComponent
              franchiseGroupId={franchiseGroupId}
              onSuccess={handleBankAccountLink}
              onError={handleError}
              customButton={
                <Button
                  text='Link with Plaid'
                  variant={ButtonVariant.WHITE}
                  onClickEventType={Event.PLAID_LINK_OPEN_CLICK}
                />
              }
            />
            <Button
              text='Link with Altir Connect'
              variant={ButtonVariant.WHITE}
              onClick={requestAltirConnect}
              isLoading={isAltirConnectRequestLoading}
            />
            <ErrorInline error={linkError}/>
          </Flex>
          <Text>
            Having trouble? Contact us at
            <SupportEmailInlineLink/>
          </Text>
        </Flex>
        : <AltirConnectInfo onClose={handleModalClose}/>}
    </ModalComponent>
  )
}
