import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import ErrorBanner from '../../errors/ErrorBanner'
import Button, { ButtonVariant } from '../../button/Button'
import MailIcon from '../../icons/MailIcon'

export interface FormBannerErrorProps {
  errorTitle?: string | null
  errorSubTitle?: string | null
  showCTA?: boolean | null
}

export default function FormBannerError ({
  errorTitle,
  errorSubTitle,
  showCTA = false
}: FormBannerErrorProps): ReactElement | null {
  const callToAction = showCTA === true
    ? <Flex px={4} paddingTop={2}>
      <Button
        text='Contact Us'
        beforeIcon={<MailIcon/>}
        variant={ButtonVariant.DESTRUCTIVE}
        onClickEventType={Event.CONTACT_US_CLICK}
      />
    </Flex>
    : null
  return (
    <Flex w='100%' my={4}>
      <ErrorBanner
        errorTitle={errorTitle}
        errorSubTitle={errorSubTitle}
        callToActionComponent={callToAction}
      />
    </Flex>
  )
}
