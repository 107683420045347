
import { gql } from '@apollo/client'
import { LOAN_OFFER_FRAGMENT } from '../fragments/LoanOfferFragment'

export const GET_LENDING_PAGE_DATA = gql`
    ${LOAN_OFFER_FRAGMENT}
    query GetLendingPageData($franchiseGroupId: Int!, $organizationId: String) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                preApprovedLendingOffers {
                    ...LoanOfferFragment
                }
                genericLendingOffers {
                    ...LoanOfferFragment
                }
                hasRequestedLoanOffers
            }
            franchiseGroup(id: $franchiseGroupId) {
                id
                preApprovedLendingOffers {
                    ...LoanOfferFragment
                }
                genericLendingOffers {
                    ...LoanOfferFragment
                }
                hasRequestedLoanOffers
            }
        }
    }
`

export const GET_DOCUMENTS_HUB_DATA = gql`
    query GetDocumentsHubData($franchiseGroupId: Int!, $organizationId: String) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                lendingDocuments {
                    id
                    type
                    fileUrl
                }
            }
            selectedOrganization(organizationId: $organizationId) {
                lendingDocuments {
                    id
                    type
                    fileUrl
                }
            }
        }
        lendingDocumentTypes
    }
`
