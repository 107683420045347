import React, { useState, type ReactElement } from 'react'

import '../../../assets/css/App.css'
import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import { getInstitutionNames, getStoreLocations, sortAndFilterAccounts } from '../treasury/components/treasury/utils'
import AccountsSortAndFilterComponent from '../treasury/components/treasury/AccountsSortAndFilterComponent'
import EmptyAccountsSectionComponent from '../treasury/components/treasury/EmptyAccountsSectionComponent'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import {
  type GetAccountsPageData,
  type GetAccountsPageDataVariables
} from '@/graphql/__generated__/GetAccountsPageData'
import { GET_ACCOUNTS_PAGE_DATA } from '@/graphql/queries/GetAccountsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import FinancialAccountDropDownComponent from '@/library/accounts/FinancialAccountDropDownComponent'
import { type AccountsSortByInput, AccountsSortByType } from '@/types/types'
import { type LinkedAccountType } from '@/graphql/__generated__/globalTypes'

export default function AccountsPage (): ReactElement {
  const { selectedOrganizationId, selectedFranchiseGroupId } = useAltirStore(state => {
    return {
      selectedOrganizationId: state.selectedOrganizationState.selectedOrganizationId,
      selectedFranchiseGroupId: state.selectedFranchiseGroupId
    }
  })
  const { data, loading, error, refetch } = useQuery<GetAccountsPageData, GetAccountsPageDataVariables>(
    GET_ACCOUNTS_PAGE_DATA,
    { variables: { organizationId: selectedOrganizationId } }
  )
  const [accountQueryString, setAccountQueryString] = useState<string | undefined>(undefined)
  const [institutionFilter, setInstitutionFilter] = useState<string[]>([])
  const [locationFilter, setLocationFilter] = useState<string[]>([])
  const [businessFilter, setBusinessFilter] = useState<string[]>([])
  const [accountTypeFilter, setAccountTypeFilter] = useState<LinkedAccountType[]>([])
  const [sortByInput, setSortByInput] = useState<AccountsSortByInput>(
    { type: AccountsSortByType.BALANCE, ascending: false }
  )

  const accounts = sortAndFilterAccounts(
    data?.currentUser?.selectedOrganization?.financialAccounts ?? [],
    sortByInput,
    accountQueryString,
    institutionFilter,
    locationFilter,
    accountTypeFilter,
    businessFilter
  )

  return (
    <Page>
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex flexDir='column' gap={6}>
          <AccountsSortAndFilterComponent
            queryValue={accountQueryString}
            setQueryValue={(value) => { setAccountQueryString(value) }}

            selectedInstitutions={institutionFilter}
            institutionOptions={getInstitutionNames(data?.currentUser?.selectedOrganization?.financialAccounts ?? [])}
            setSelectedInstitutions={(institutions) => { setInstitutionFilter(institutions) }}

            selectedLocations={locationFilter}
            locationOptions={getStoreLocations(data?.currentUser?.selectedOrganization?.financialAccounts ?? [])}
            setSelectedLocations={(locations) => { setLocationFilter(locations) }}

            selectedAccountTypes={accountTypeFilter}
            shouldIncludeAccountTypes={true}
            setSelectedAccountType={(values) => { setAccountTypeFilter(values) }}

            selectedBusinesses={businessFilter}
            businessOptions={data?.currentUser?.selectedOrganization?.franchiseGroups ?? []}
            setSelectedBusinesses={(businesses) => { setBusinessFilter(businesses) }}

            sortByInput={sortByInput}
            setSortByInput={(value) => { setSortByInput(value) }}
          />
          <Flex flexDir='column' gap={2}>
            {
                accounts.length < 1
                  ? <EmptyAccountsSectionComponent
                      franchiseGroupId={selectedFranchiseGroupId}
                      refetch={refetch}
                      hideLinkAccountButton={true}
                      ctaText='Update your search parameters or link an account.'
                    />
                  : accounts.map((account, index) => {
                    return (
                      <FinancialAccountDropDownComponent
                        key={index}
                        account={account}
                        refetch={refetch}
                      />
                    )
                  })
            }
          </Flex>
        </Flex>
      </AltirSkeleton>
    </Page>
  )
}
