import React, { type ReactElement } from 'react'
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { Color } from '@/theme/theme'

interface TransactionRowDropInfoDropdownProps {
  isSelected: boolean
  categoryLabelFormatted: string
  longDescription: string
  fromAccount?: string
  toAccount?: string
  dateFormatted?: string
  timeFormatted?: string
  institutionName?: string
}
const MotionFlex = motion(Flex)
export default function TransactionRowDropInfoDropdown ({
  isSelected,
  categoryLabelFormatted,
  longDescription,
  fromAccount,
  toAccount,
  dateFormatted,
  timeFormatted
}: TransactionRowDropInfoDropdownProps
): ReactElement {
  return (
    <MotionFlex
      w='100%'
      overflow='hidden'
      initial='closed'
      animate={isSelected
        ? 'open'
        : 'closed'}
      transition={{ duration: 0.2 }}
      variants={{
        open: {
          opacity: 1,
          height: 'auto',
          display: 'block'
        },
        closed: {
          opacity: 0.8,
          height: 0,
          transitionEnd: {
            display: 'none'
          }
        }
      }}
    >
      <Flex flexDirection='column'
        gap={4}
      >
        <Flex flexDirection='column'>
          <Grid templateColumns='repeat(6, 1fr)' templateRows='1'>
            {fromAccount != null &&
            <GridItem colSpan={1}>
              <Text color={Color.DARK_GREY} fontSize='xs'>From</Text>
              <Text color={Color.DARK_BLUE} paddingBottom={4}>{fromAccount}</Text>
            </GridItem>}
            {toAccount != null &&
            <GridItem colSpan={1}>
              <Text color={Color.DARK_GREY} fontSize='xs'>To</Text>
              <Text color={Color.DARK_BLUE} paddingBottom={4}>{toAccount}</Text>
            </GridItem>}
            {dateFormatted != null &&
            <GridItem colSpan={1}>
              <Text color={Color.DARK_GREY} fontSize='xs'>Date</Text>
              <Text color={Color.DARK_BLUE} paddingBottom={4}>{dateFormatted}</Text>
            </GridItem>}
            {timeFormatted != null &&
            <GridItem colSpan={1}>
              <Text color={Color.DARK_GREY} fontSize='xs'>Time</Text>
              <Text color={Color.DARK_BLUE} paddingBottom={4}>{timeFormatted}</Text>
            </GridItem>}
          </Grid>
          <Text color={Color.DARK_GREY} fontSize='xs'>Category</Text>
          <Text color={Color.DARK_BLUE} paddingBottom={4}>
            {categoryLabelFormatted}
          </Text>
          <Text color={Color.DARK_GREY} fontSize='xs'>
            Description
          </Text>
          <Text color={Color.DARK_BLUE}>
            {longDescription}
          </Text>
        </Flex>
      </Flex>
    </MotionFlex>
  )
}
