import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import TransferRuleConfirmationModal from './TransferRuleConfirmationModal'
import RecurringRuleFormSection, { type RecurringRuleFormState } from './RecurringRuleFormSection'
import AccountSelectorComponent from '../../transfer/components/account_selector/AccountSelectorComponent'
import { Color } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { TransferDirection, TransferRuleType }
  from '@/graphql/__generated__/globalTypes'
import { type Account } from '@/types/types'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import { getCurrentDate } from '@/utils/dateUtils'
import { isInvalidDate } from '@/utils/formUtils'
import { formatInputAsDate, stripDateFormatting } from '@/library/form/number/utils'

export interface CreateTimeRuleComponentProps {
  transferDirection: TransferDirection
  amplifyAccount?: Account
  counterparties: Account[]
  preSelectedCounterpartyId?: string
}

export default function CreateTimeRuleComponent (
  {
    transferDirection,
    amplifyAccount,
    counterparties,
    preSelectedCounterpartyId
  }: CreateTimeRuleComponentProps
): ReactElement {
  const [amount, setAmount] = useState<string>()
  const preSelectedCounterparty = counterparties.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>(preSelectedCounterparty)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [recurringForm, setRecurringForm] = useState<RecurringRuleFormState>({
    dayOfWeek: 'Monday',
    dayOfMonth: 1,
    transferRuleType: TransferRuleType.DAILY,
    transferDate: stripDateFormatting(getCurrentDate())
  })

  function isValidInput (): boolean {
    if (amplifyAccount?.amplifyAccountId == null) {
      return false
    }
    if (selectedCounterparty?.counterpartyId == null) {
      return false
    }
    if (amount == null || Number(amount) <= 0) {
      return false
    }
    // TODO validate future date
    if (recurringForm.transferDate == null || isInvalidDate(formatInputAsDate(recurringForm.transferDate))) {
      return false
    }
    return true
  }

  return (
    <Flex direction='column' gap={5} w='100%'>
      <AccountSelectorComponent
        amplifyAccount={transferDirection === TransferDirection.DEBIT ? undefined : amplifyAccount}
        counterparties={transferDirection === TransferDirection.DEBIT ? counterparties : undefined}
        transferDirection={TransferDirection.DEBIT /* Patch to filter to personal accounts only */}
        customHeaderText='From'
        onSelectedCounterpartyChange={setSelectedCounterparty}
        selectedCounterparty={selectedCounterparty ?? undefined}
      />
      <AccountSelectorComponent
        amplifyAccount={transferDirection === TransferDirection.CREDIT ? undefined : amplifyAccount}
        counterparties={transferDirection === TransferDirection.CREDIT ? counterparties : undefined}
        transferDirection={TransferDirection.DEBIT /* Patch to filter to personal accounts only */}
        customHeaderText='To'
        onSelectedCounterpartyChange={setSelectedCounterparty}
        selectedCounterparty={selectedCounterparty ?? undefined}
      />
      <FormDollarInput
        fieldName='amount'
        value={amount ?? ''}
        label='Amount'
        onChange={setAmount}
        placeholder='100'
        fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
        backgroundColor={Color.WHITE}
      />
      <RecurringRuleFormSection
        formState={recurringForm}
        setState={setRecurringForm}
      />
      <Button
        text='Submit'
        size={ButtonSize.MEDIUM}
        variant={ButtonVariant.PRIMARY}
        isDisabled={!isValidInput()}
        onClick={() => { setIsConfirmationModalOpen(true) }}
        onClickEventType={Event.TRANSFER_RULE_PAGE_CREATE_RULE}
      />
      <TransferRuleConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        onModalClose={() => { setIsConfirmationModalOpen(false) }}
        onUnhandledSubmissionError={() => {}}
        ruleData={{
          amplifyAccount,
          counterparty: selectedCounterparty ?? undefined,
          transferRuleType: recurringForm.transferRuleType,
          direction: transferDirection,
          transferAmount: amount,
          targetDate:
            recurringForm.transferRuleType === TransferRuleType.MONTHLY ? recurringForm.dayOfMonth : undefined,
          targetDay: recurringForm.transferRuleType === TransferRuleType.WEEKLY ? recurringForm.dayOfWeek : undefined,
          ruleStartDate: formatInputAsDate(recurringForm.transferDate)
        }}
      />
    </Flex>
  )
}
