import { useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import React, {
  type Dispatch,
  type SetStateAction,
  useState,
  type ReactElement
} from 'react'
import StatementSelectionBox from './StatementSelectionBox'
import CustomTimeFrameSelectionBox from './CustomTimeframeSelectionBox'
import { type CustomStatement } from './StatementTransactionListComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  GET_AVAILABLE_STATEMENTS
} from '@/graphql/queries/GetAvailableBankStatements'
import {
  type GetAvailableStatementsVariables,
  type GetAvailableStatements,
  type GetAvailableStatements_availableStatements as Statement
} from '@/graphql/__generated__/GetAvailableStatements'

export interface AvailableStatementsComponentProps {
  accountId: number
  setSelectedStatement: Dispatch<SetStateAction<Statement | null>>
  isModalOpen: boolean
  toggle: () => void
  setCustomStatement: (statement: CustomStatement) => void
}

export default function AvailableStatementsComponent (
  {
    accountId,
    setSelectedStatement,
    toggle,
    setCustomStatement
  }: AvailableStatementsComponentProps
): ReactElement<string, string> {
  const {
    data,
    loading,
    error
  } = useQuery<
  GetAvailableStatements,
  GetAvailableStatementsVariables
  >(GET_AVAILABLE_STATEMENTS,
    {
      variables: {
        accountId
      }
    })
  const [selectedStatementIndex, setSelectedStatementIndex] = useState(0)
  const [isCustomTimeFrameSelected, setCustomTimeFrameSelected] = useState(false)

  function handleStatementSelectionChange (index: number): void {
    const selectedStatement = data?.availableStatements[index]
    setCustomTimeFrameSelected(false)
    setSelectedStatementIndex(index)
    setSelectedStatement(selectedStatement ?? null)
    setCustomStatement({ accountId: '', description: '', startDate: '', endDate: '' })
  }
  function toggleAndClear (): void {
    setCustomTimeFrameSelected(true)
    toggle()
    setSelectedStatementIndex(-1)
  }

  return (
    <Flex flexDirection='column' my={3}>
      {/* Dummy spacer to ensure that this component is vertically aligned despite not having a title */}
      <Flex mt={2}></Flex>
      <AltirSkeleton isLoading={loading} error={error} isCompact marginY={3}>
        <Flex
          width='100%'
          flexDirection='row'
          overflow='auto'
        >
          <CustomTimeFrameSelectionBox
            index={-1}
            isSelected={isCustomTimeFrameSelected}
            setSelected={toggleAndClear}
          />
          {
            data?.availableStatements.map((statement, i) => {
              return (
                <div key={i}>
                  <StatementSelectionBox
                    index={i}
                    statement={statement}
                    isSelected={selectedStatementIndex === i}
                    isCurrent={(i === 0)}
                    setSelected={handleStatementSelectionChange}
                  />
                </div>
              )
            })
        }
        </Flex>
      </AltirSkeleton>
    </Flex>
  )
}
