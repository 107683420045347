import { Divider, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import {
  BorderRadius,
  Color,
  FontWeight
} from '@/theme/theme'

interface StatementSelectionComponentProps {
  index: number
  isSelected: boolean
  setSelected: (i: number) => void
}

export default function CustomTimeFrameSelectionBox (
  {
    index,
    isSelected,
    setSelected
  }: StatementSelectionComponentProps
): ReactElement {
  return (
    (
      <Flex
        key='Custom'
        bg={Color.WHITE}
        marginRight={4}
        px={5}
        py={4}
        borderRadius={BorderRadius.CARD}
        flexDirection='column'
        borderWidth={2}
        borderColor={isSelected ? Color.DARK_GREY : Color.WHITE}
        onClick={() => { setSelected(index) }}
        _hover={{ cursor: 'pointer' }}
        height='100%'
        fontWeight={FontWeight.BOLD}
      >
        <Text
          color={Color.DARK_BLUE}
          width={36}
        >
          Custom Timeframe
        </Text>
        <Divider
          my={2}
          borderWidth={1}
          borderColor={Color.DARK_GREY}
        />
        <Text
          fontSize='sm'
          color={Color.DARK_GREY}
        >
          Select Dates
        </Text>
      </Flex>
    )

  )
}
