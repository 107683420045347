import { gql } from '@apollo/client'

export const GET_STATEMENT_PAGE_METADATA = gql`
    query GetStatementPageMetadata($accountId: Int!) {
        financialAccount(accountId: $accountId) {
            amplifyAccount {
                id
            }
            status
            plaidAccessToken
            institution{
                name
            }
            connectionProvider
        }
    }
`
