import { type ReactElement } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AmplifyFormGroupComponent from '../AmplifyFormGroupComponent'
import { AmplifyApplicationContext } from '../../AmplifyApplicationContainerComponent'
import PersonIcon from '@/library/icons/PersonIcon'
import { Color } from '@/theme/theme'
import { RouteParam, ROUTES } from '@/api/routes'
import { routeWithParams } from '@/utils/routerUtils'
import {
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_personalApplications
  as PersonApplication
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { AmplifyAccountApplicationStatus } from '@/graphql/__generated__/globalTypes'

export interface PersonApplicationComponentProps {
  franchiseGroupId: number
  amplifyApplicationId?: string
  context: AmplifyApplicationContext
  personApplications: PersonApplication[]
}

export default function OwnershipRow (
  {
    franchiseGroupId,
    amplifyApplicationId,
    personApplications,
    context
  }: PersonApplicationComponentProps
): ReactElement {
  const navigate = useNavigate()

  const link = routeWithParams(
    getRouteForContext(context),
    [
      {
        param: RouteParam.BUSINESS_ID,
        value: String(franchiseGroupId)
      },
      {
        param: RouteParam.AMPLIFY_APPLICATION_ID,
        value: String(amplifyApplicationId)
      }
    ]
  )

  return (
    <AmplifyFormGroupComponent
      title='Ownership Information'
      status={getStatus(personApplications)}
      icon={<PersonIcon color={Color.DARK_BLUE}/>}
      handleClick={() => { navigate(link) }}
    />
  )
}

function getStatus (personApplications: PersonApplication[]): AmplifyAccountApplicationStatus | null {
  if (personApplications.length === 0) return AmplifyAccountApplicationStatus.CREATED

  const hasCompletedPersonalApplication = personApplications
    ?.find(p => p.status === AmplifyAccountApplicationStatus.SUBMITTED) != null

  if (hasCompletedPersonalApplication) return AmplifyAccountApplicationStatus.SUBMITTED

  return null
}

function getRouteForContext (context: AmplifyApplicationContext): string {
  switch (context) {
    case AmplifyApplicationContext.DEFAULT:
      return ROUTES.AMPLIFY_APP_OWNERSHIP
    case AmplifyApplicationContext.ONBOARDING:
      return ROUTES.V2_SIGN_UP_AMPLIFY_APP_OWNERSHIP
  }
}
