import React, { useEffect, useState, type ReactElement } from 'react'
import { Center, Flex, Heading, useToast } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import TransferRuleDropdownComponent from './components/TransferRuleDropdownComponent'
import TransferRuleGroup from './components/TransferRuleGroup'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { ContainerWidth, IconSize } from '@/theme/theme'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_TRANSFER_RULES } from '@/graphql/queries/transfer_rules/GetTransferRules'
import {
  type GetTransferRulesVariables,
  type GetTransferRules,
  type GetTransferRules_currentUser_franchiseGroup_transferRules as TransferRule
}
  from '@/graphql/__generated__/GetTransferRules'
import PlusIcon from '@/library/icons/PlusIcon'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import { GenericTransferRuleType, isBalanceRule, isUnaddressedTransferRuleError } from '@/utils/transferRuleUtils'
import { AuthResource, DerivedTransactionCategory } from '@/graphql/__generated__/globalTypes'
import { getSuccessToast } from '@/utils/toastUtils'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { useNavigationState } from '@/hooks/useNavigationState'
import BusinessSelectorComponent from '@/library/state/BusinessSelectorComponent'
import ErrorBanner from '@/library/errors/ErrorBanner'
import { type MarkHasAcknowledgedFraudulentReturnMessage }
  from '@/graphql/__generated__/MarkHasAcknowledgedFraudulentReturnMessage'
import { MARK_HAS_ACKNOWLEDGED_FRAUDULENT_RETURN_MESSAGE }
  from '@/graphql/mutations/MarkHasAcknowledgedFraudulentReturnMessage'
import { AuthAction, hasAccess, INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY } from '@/utils/authUtils'

const RETURN_BANNER_TEXT = `
  Some banks automatically flag transfers as fraudulent by default.  We suggest you contact your institution and approve Altir as a valid partner.
`
export default function AutomatedTransfersPage (): ReactElement {
  const { franchiseGroupId, organizationId } = useAltirStore((state) => {
    return {
      franchiseGroupId: state.selectedFranchiseGroupId,
      organizationId: state.selectedOrganizationState.selectedOrganizationId
    }
  })

  const toast = useToast()
  const navigationState = useNavigationState<{ isFromRuleCreation: boolean | undefined }>()
  const [showRuleCreationToast, setShowRuleCreationToast] = useState(navigationState?.isFromRuleCreation ?? false)

  const [
    markAcknowledgedMutation
  ] = useMutation<MarkHasAcknowledgedFraudulentReturnMessage>(
    MARK_HAS_ACKNOWLEDGED_FRAUDULENT_RETURN_MESSAGE,
    { variables: { franchiseGroupId } }
  )

  // TODO - better abstraction would be to pass in entire toast creation as a callback
  useEffect(() => {
    if (showRuleCreationToast) {
      toast(getSuccessToast('Transfer Rule Successfully Created'))
      setShowRuleCreationToast(false)
    }
  }, [showRuleCreationToast, setShowRuleCreationToast, toast])

  const {
    data,
    loading,
    error,
    refetch
  } = useQuery<GetTransferRules, GetTransferRulesVariables>(
    GET_TRANSFER_RULES,
    {
      variables: { franchiseGroupId, organizationId },
      fetchPolicy: 'network-only'
    }
  )
  const navigate = useNavigate()

  const transferRules = data?.currentUser?.franchiseGroup?.transferRules ?? []
  const canAutoExpand = canAutoExpandRules(transferRules)

  const activeBalanceRules =
    transferRules.filter(rule => isBalanceRule(rule.transferRuleType) && rule.isActive)

  const activeTimeRules =
    transferRules.filter(rule => !isBalanceRule(rule.transferRuleType) && rule.isActive)

  const inactiveRules = transferRules.filter(rule => !rule.isActive)

  const hasAcknowledgedFraudWarning =
    data?.currentUser?.franchiseGroup?.hasAcknowledgedFraudulentReturnMessage === true

  const totalErrors = transferRules.filter(
    rule => rule.isActive && isUnaddressedTransferRuleError(rule)
  )

  // Authorization
  const canEditTransferRules = hasAccess(
    AuthResource.AMPLIFY_ACCOUNT,
    AuthAction.WRITE,
    data?.currentUser?.authorization ?? []
  )

  return (
    <Center w='100%'>
      <Center maxWidth={ContainerWidth.FULL_PAGE}>
        <AltirSkeleton
          isLoading={loading}
          error={error}
        >
          <Flex w='100%' justifyContent='space-between' alignItems='center'>
            <Flex flexDir='column' width='100%' gap={6}>
              <Flex justifyContent='space-between' gap={6}>
                <Flex flexDir='column'>
                  <Flex gap={6} justifyContent='start'>
                    <Button
                      text='View Recent Automated Transfers'
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.GREY}
                      afterIcon={<RightArrowIcon size={IconSize.SCHMEDIUM}/>}
                      onClick={() => {
                        navigate(ROUTES.TRANSACTIONS,
                          { state: { categoryLabels: [DerivedTransactionCategory.AUTOMATED_INTERNAL_TRANSFER] } })
                      }}
                      width='auto'
                    />
                    <Button
                      text='Add a Rule'
                      size={ButtonSize.MEDIUM}
                      beforeIcon={<PlusIcon size={IconSize.SMALL}/>}
                      onClick={() => { navigate(ROUTES.CREATE_AUTOMATED_TRANSFER_RULE) }}
                      width='auto'
                      toolTipText={!canEditTransferRules ? INSUFFICIENT_PERMISSIONS_TOOLTIP_COPY : undefined}
                      isDisabled={!canEditTransferRules}
                    />
                  </Flex>
                </Flex>
                <BusinessSelectorComponent
                  businessFilterParams={{ hasAmplify: true }}
                  requireMultipleBusinesses={true}
                />
              </Flex>
              <Flex flexDir='column' gap={10}>
                <ErrorBanner
                  errorTitle='Avoid Unexpected Returns'
                  errorSubTitle={RETURN_BANNER_TEXT}
                  isCollapsible={true}
                  onClose={() => { void markAcknowledgedMutation() }}
                  isHidden={hasAcknowledgedFraudWarning}
                />
                <ErrorBanner
                  errorTitle='One or more transfer rules need attention.'
                  errorSubTitle={`${totalErrors.length} rule(s) are not executing. Check the rules below for more information.`}
                  isCollapsible={true}
                  isHidden={totalErrors.length <= 0}
                />
                {/* Balance-Based Rules */}
                <TransferRuleGroup
                  transferRules={activeBalanceRules}
                  canAutoExpand={canAutoExpand}
                  type={GenericTransferRuleType.BALANCE}
                  canEditTransferRules={canEditTransferRules}
                  refetch={refetch}
                />
                {/* Time-Based Rules */}
                <TransferRuleGroup
                  transferRules={activeTimeRules}
                  canAutoExpand={canAutoExpand}
                  type={GenericTransferRuleType.TIME}
                  canEditTransferRules={canEditTransferRules}
                  refetch={refetch}
                />
                {/* Inactive Rules */}
                {inactiveRules.length > 0 &&
                  <Flex direction='column' gap={2}>
                    <Heading size='sm'> Inactive & Expired Rules </Heading>
                    {
                      inactiveRules.map(rule => {
                        return (
                          <TransferRuleDropdownComponent
                            key={rule.id}
                            transferRule={rule}
                            isOpenOnStart={canAutoExpand}
                            onUpdate={refetch}
                            isError={isUnaddressedTransferRuleError(rule)}
                            canEditTransferRules={canEditTransferRules}
                          />

                        )
                      })
                    }
                  </Flex>
                }
              </Flex>
            </Flex>
          </Flex>
        </AltirSkeleton>
      </Center>
    </Center>
  )
}

export function canAutoExpandRules (rules: TransferRule[]): boolean {
  return rules.length <= 2
}
