import { Box, Divider, Flex, Tooltip } from '@chakra-ui/react'
import React, { type ReactElement, useState } from 'react'
import { getBackGroundColor, getHeaderTextColor } from './utils'
import { type BaseDropDownProps, DropDownVariant } from './types'
import DropDownHeader from './components/header/DropDownHeader'
import {
  BorderRadius
} from '../../theme/theme'

type DropDownComponentProps = BaseDropDownProps & { isOpenOnStart?: boolean }

export default function DropDownComponent (
  {
    title,
    subtitle,
    icon,
    dropDownContent,
    variant = DropDownVariant.DEFAULT,
    isOpenOnStart = false,
    rightDescription,
    rightCallToActionComponent,
    isComplete = false,
    hasNotification = false,
    isDisabled = false,
    disabledTooltipText,
    customHeader,
    borderStyle,
    padding = 6
  }: DropDownComponentProps
): ReactElement {
  const backgroundColor = getBackGroundColor(variant, isDisabled)
  const headerColor = getHeaderTextColor(variant, isDisabled)

  const [isSelected, setIsSelected] = useState(isOpenOnStart)

  function handleDropDownToggle (): void {
    if (isDisabled) return
    setIsSelected(!isSelected)
  }

  return (
    <Tooltip label={disabledTooltipText}>
      <Box
        bg={backgroundColor}
        borderRadius={BorderRadius.CARD}
        p={padding}
        width='100%'
        border={borderStyle}
      >
        {/* DropDown Header */}
        <DropDownHeader
          title={title}
          subtitle={subtitle}
          icon={icon}
          rightDescription={rightDescription}
          rightCallToActionComponent={rightCallToActionComponent}
          customHeader={customHeader}
          isSelected={isSelected}
          isDisabled={isDisabled}
          isComplete={isComplete}
          hasNotification={hasNotification}
          handleDropDownToggle={handleDropDownToggle}
          headerColor={headerColor}
        />
        {/* DropDown Content */}
        {
          isSelected &&
            <Flex
              direction='column'
              _hover={{ cursor: 'auto' }}
            >
              <Divider my={padding} variant='thick'/>
              {dropDownContent}
            </Flex>
        }
      </Box>
    </Tooltip>
  )
}
