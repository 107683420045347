import React, { type ReactElement } from 'react'
import { TransactionSortType, type TransactionSortInput } from '@/graphql/__generated__/globalTypes'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'

interface TransactionsSortByComponentProps {
  queryValue?: TransactionSortInput
  onChange: (value: TransactionSortInput) => void
}

export default function TransactionsSortByComponent (
  { queryValue, onChange }: TransactionsSortByComponentProps
): ReactElement {
  const initialValue = queryInputToTransactionSortType(queryValue)
  return (
    <PopoverMenu
      popoverMenuTitle='Sort by'
      onSubmit={() => {}}
      subtitle={initialValue}
      shouldMatchWidth={true}
      hasSelection={true}
    >
      <PopoverMenuSingleOptionSelect
        options={Object.values(DropdownSortType)}
        selectedOption={initialValue}
        setSelectedOption={(value: DropdownSortType) => {
          onChange(dropdownSortTypeToTransactionSortInput(value))
        }}
        dividerFrequency={2}
      />
    </PopoverMenu>
  )
}

enum DropdownSortType {
  DATE_NEW_TO_OLD = 'Date (New to Old)',
  DATE_OLD_TO_NEW = 'Date (Old to New)',
  AMOUNT_HIGH_TO_LOW = 'Amount (High to Low)',
  AMOUNT_LOW_TO_HIGH = 'Amount (Low to High)',
}

function dropdownSortTypeToTransactionSortInput (dropdownSortType: DropdownSortType): TransactionSortInput {
  switch (dropdownSortType) {
    case DropdownSortType.AMOUNT_HIGH_TO_LOW:
      return { type: TransactionSortType.Amount, ascending: false }
    case DropdownSortType.AMOUNT_LOW_TO_HIGH:
      return { type: TransactionSortType.Amount, ascending: true }
    case DropdownSortType.DATE_NEW_TO_OLD:
      return { type: TransactionSortType.Date, ascending: false }
    case DropdownSortType.DATE_OLD_TO_NEW:
      return { type: TransactionSortType.Date, ascending: true }
  }
}

function transactionSortInputToDropdownSortType (
  sortType: TransactionSortType,
  sortAscending: boolean
): DropdownSortType {
  switch (sortType) {
    case TransactionSortType.Amount:{
      return sortAscending
        ? DropdownSortType.AMOUNT_LOW_TO_HIGH
        : DropdownSortType.AMOUNT_HIGH_TO_LOW
    }
    case TransactionSortType.Date:{
      return sortAscending
        ? DropdownSortType.DATE_OLD_TO_NEW
        : DropdownSortType.DATE_NEW_TO_OLD
    }
  }
}

function queryInputToTransactionSortType (queryValue?: TransactionSortInput): DropdownSortType {
  return queryValue != null
    ? transactionSortInputToDropdownSortType(queryValue?.type, queryValue?.ascending)
    : DropdownSortType.DATE_NEW_TO_OLD
}
