import React, { type ReactElement } from 'react'
import { Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import TimeRuleConfirmationCard from './TimeRuleConfirmationCard'
import BalanceRuleConfirmationCard from './BalanceRuleConfirmationCard'
import { Color } from '@/theme/theme'
import Button, { ButtonVariant } from '@/library/button/Button'
import { ModalComponent } from '@/library/modal/ModalComponent'
import { CREATE_TRANSFER_RULE } from '@/graphql/mutations/CreateTransferRule'
import { ROUTES } from '@/api/routes'
import { type CreateTransferRule, type CreateTransferRuleVariables } from '@/graphql/__generated__/CreateTransferRule'
import {
  dateTimeFromDateTimeTemplate, DateTimeTemplate,
  dateTimeToISOString, getCurrentDate
} from '@/utils/dateUtils'
import { getErrorToast } from '@/utils/toastUtils'
import { type Account } from '@/types/types'
import { TransferDirection, TransferRuleType } from '@/graphql/__generated__/globalTypes'
import { formatInputAsCurrency } from '@/library/form/number/utils'
import { formatDateOfMonthToString, isBalanceRule } from '@/utils/transferRuleUtils'
import { isMaximumTransferRuleExceededError, isTransferRuleCircularDependencyError, isTransferRuleMissingParamsError }
  from '@/utils/errorUtils'

export interface RuleData {
  amplifyAccount?: Account
  counterparty?: Account
  direction: TransferDirection
  transferRuleType: TransferRuleType
  ruleStartDate?: string
  targetBalance?: string
  transferAmount?: string
  targetDate?: number
  targetDay?: string
  safeguard?: number

}
export interface TransferRuleConfirmationModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  ruleData: RuleData
  onUnhandledSubmissionError: (error: ApolloError) => void
}

export default function TransferRuleConfirmationModal (
  { isModalOpen, onModalClose, ruleData, onUnhandledSubmissionError }:
  TransferRuleConfirmationModalProps
): ReactElement {
  const navigate = useNavigate()
  const toast = useToast()

  const counterpartyName = ruleData.counterparty?.name ?? ''

  const [
    createTransferRule,
    { loading }
  ] = useMutation<CreateTransferRule, CreateTransferRuleVariables>(CREATE_TRANSFER_RULE, {
    onCompleted: (_data) => {
      onModalClose()
      navigate(ROUTES.AUTOMATED_TRANSFERS, { state: { isFromRuleCreation: true } })
    },
    onError: (error) => {
      onModalClose()
      if (isTransferRuleCircularDependencyError(error)) {
        // This should be rendered within the form
        onUnhandledSubmissionError(error)
        return
      }

      let msg = 'Problem Creating Automated Transfer Rule. '
      if (isMaximumTransferRuleExceededError(error)) {
        msg += `You've reached the maximum allowance of automated transfer rules between ${counterpartyName} and the Amplify Account.`
      } else if (isTransferRuleMissingParamsError(error)) {
        msg += 'Check that all fields are valid.'
      } else {
        msg += 'Please try again.'
      }
      toast(getErrorToast(msg))
    }
  })

  return (
    <ModalComponent
      isOpen={isModalOpen}
      onClose={onModalClose}
      size='sm'
    >
      <Flex flexDir='column' w='100%' gap={6}>
        <Heading textAlign='center'
          size='lg'
          color={Color.DARK_BLUE}
          whiteSpace='pre-line'
        >
          Confirm Transfer Rule?
        </Heading>
        {createCardComponent()}
        <Flex gap={3} direction='column'>
          <Button text='Confirm'
            variant={ButtonVariant.PRIMARY}
            onClick={createRule}
            isLoading={loading}
          />
          <Button text='Cancel' variant={ButtonVariant.WHITE} onClick={() => { onModalClose() }}/>
        </Flex>
        <Flex flexDir='column' gap={2}>
          <Text fontSize='xs'>
            Scheduled transfers are executed at 12pm ET.
            Your scheduled transfers can be de-activated at any time on the Transfer Rules page.
          </Text>
          <Text fontSize='xs'>
            If your financial institution flags this transaction as fraudulent, please contact their support team.
          </Text>
        </Flex>
      </Flex>
    </ModalComponent>
  )

  function createCardComponent (): ReactElement {
    if (ruleData.transferRuleType === TransferRuleType.TARGET_BALANCE ||
      ruleData.transferRuleType === TransferRuleType.MIN_TARGET_BALANCE) {
      return (
        <BalanceRuleConfirmationCard
          from={ruleData.direction === TransferDirection.DEBIT ? counterpartyName : 'Amplify Account'}
          to={ruleData.direction === TransferDirection.DEBIT ? 'Amplify Account' : counterpartyName}
          balanceThreshold={formatInputAsCurrency(ruleData.targetBalance ?? '')}
          type={ruleData.transferRuleType}
      // eslint-disable-next-line react/jsx-closing-bracket-location
      />)
    } else {
      return (
        <TimeRuleConfirmationCard
          from={ruleData.direction === TransferDirection.DEBIT ? counterpartyName : 'Amplify Account'}
          to={ruleData.direction === TransferDirection.DEBIT ? 'Amplify Account' : counterpartyName}
          amount={formatInputAsCurrency(ruleData.transferAmount ?? '')}
          frequency={ruleData.transferRuleType}
          dayOfWeek={ruleData.targetDay}
          dateOfMonth={ruleData.targetDate != null ? formatDateOfMonthToString(ruleData.targetDate) : undefined}
          startDate={ruleData.ruleStartDate}

      // eslint-disable-next-line react/jsx-closing-bracket-location
      />)
    }
  }

  function createRule (): void {
    const startDateFormatted = ruleData.ruleStartDate ?? getCurrentDate()
    // Normalizing frame-of-reference to EST and avoid off-by-one date errors with date-conversions,
    // since the job will be comparing dates in EST
    const startDateISO = dateTimeToISOString(
      dateTimeFromDateTimeTemplate(startDateFormatted, DateTimeTemplate.FULL)
        .setZone('America/New_York', { keepLocalTime: true })
    )
    if (startDateISO == null) {
      toast(getErrorToast('Something went wrong. Please try again. If the issue persists, contact us.'))
      return
    }

    void createTransferRule({
      variables: {
        transferRuleInput: {
          // validation is handled beforehand
          amplifyAccountId: ruleData.amplifyAccount?.amplifyAccountId ?? '',
          counterpartyId: ruleData.counterparty?.counterpartyId ?? '',
          direction: ruleData.direction,
          transferRuleType: ruleData.transferRuleType,
          ruleStartDate: startDateISO,
          isActive: true,
          // balance-specific
          targetBalance: isBalanceRule(ruleData.transferRuleType)
            ? Number(ruleData.targetBalance)
            : undefined,
          // time-specific
          transferAmount: !isBalanceRule(ruleData.transferRuleType)
            ? Number(ruleData.transferAmount)
            : undefined,
          targetDate: ruleData.transferRuleType === TransferRuleType.MONTHLY ? ruleData.targetDate : undefined,
          targetDay: ruleData.transferRuleType === TransferRuleType.WEEKLY
            ? dayOfWeekToNumber(ruleData.targetDay)
            : undefined,
          minAccountBalanceBeforeWithdrawal: ruleData.safeguard
        }
      }
    })
  }

  function dayOfWeekToNumber (dayOfWeek?: string): number {
    switch (dayOfWeek) {
      case 'Monday':
        return 1
      case 'Tuesday':
        return 2
      case 'Wednesday':
        return 3
      case 'Thursday':
        return 4
      case 'Friday':
        return 5
    }
    return -1
  }
}
