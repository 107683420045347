import React, { useState, type ReactElement } from 'react'
import { Flex, Radio, Stack, Switch, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import BlackListedCompanyTypesListComponent from './BlackListedCompanyTypesListComponent.tsx'
import ErrorInline from '@/library/errors/ErrorInline'
import AddressForm, { type AddressInput } from '@/library/form/address/AddressForm'
import { FormInput } from '@/library/form/text/FormInput'
import FormTextArea from '@/library/form/text/FormTextArea'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import { fieldRequiredMessage, isEmpty } from '@/utils/stringUtils'
import {
  getHumanReadableBusinessLegalStructure,
  HUMAN_READABLE_LEGAL_STRUCTURES,
  type LegalStructureHumanReadable
} from '@/utils/amplifyApplicationUtils'
import { STATES } from '@/utils/addressUtils'
import { type AmplifyAccountBusinessLegalStructure } from '@/graphql/__generated__/globalTypes'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_businessApplication as BusinessApplicationData
} from '@/graphql/__generated__/GetAmplifyAccountApplication'
import { Color } from '@/theme/theme'
import FormEINInput from '@/library/form/number/FormEINInput'
import { ErrorCopy } from '@/utils/errorUtils'
import { isInvalidAddress } from '@/utils/formUtils'

export interface BusinessApplicationFormData {
  businessDescription?: string | null
  doingBusinessAsName?: string | null
  incorporationState?: string | null
  legalName?: string | null
  legalStructure?: LegalStructureHumanReadable | null
  naicsDescription?: string | null
  naicsSectorCode?: string | null
  phoneNumber?: string | null
  physicalAddress?: AddressInput | null
  mailingAddress?: AddressInput | null
  url?: string | null
  businessIdentificationNumber?: string | null
}

export interface BusinessApplicationErrorState {
  businessDescription?: string | null
  doingBusinessAsName?: string | null
  incorporationState?: string | null
  legalName?: string | null
  legalStructure?: string | null
  naicsDescription?: string | null
  naicsSectorCode?: string | null
  phoneNumber?: string | null
  physicalAddress?: string | null
  mailingAddress?: string | null
  url?: string | null
  businessIdentificationNumber?: string | null
}

export interface BusinessApplicationFormComponentProps {
  businessApplication?: BusinessApplicationData
  handleSubmit: (formData: BusinessApplicationFormData) => void
  handleCancel: () => void
  isSubmissionLoading: boolean
  submissionError?: Error
}

const BUSINESS_ADDRESS_DISCLAIMER_TEXT =
  'This cannot be a P.O. Box, reship address, or commercial address not associated with the business.'

export default function BusinessApplicationFormComponent (
  {
    businessApplication,
    handleSubmit,
    handleCancel,
    isSubmissionLoading,
    submissionError
  }: BusinessApplicationFormComponentProps
): ReactElement {
  const [formData, setFormData] = useState<BusinessApplicationFormData>({
    businessDescription: businessApplication?.businessDescription,
    doingBusinessAsName: businessApplication?.doingBusinessAsName,
    incorporationState: businessApplication?.incorporationState,
    legalName: businessApplication?.legalName,
    legalStructure: getHumanReadableBusinessLegalStructure(
      businessApplication?.legalStructure as AmplifyAccountBusinessLegalStructure
    ),
    naicsDescription: businessApplication?.naicsDescription,
    naicsSectorCode: businessApplication?.naicsSectorCode,
    phoneNumber: businessApplication?.phoneNumber,
    physicalAddress: businessApplication?.physicalAddress,
    mailingAddress: businessApplication?.mailingAddress ?? businessApplication?.physicalAddress,
    url: businessApplication?.urls != null ? businessApplication.urls[0] : null
  })
  const [inlineErrorState, setInlineErrorState] = useState<BusinessApplicationErrorState>({})
  const [inlineError, setInlineError] = useState<Error | undefined>()
  const [isMailingAddressSameAsBusinessAddress, setIsMailingAddressSameAsBusinessAddress] = useState(true)
  const [isAcceptableIndustry, setIsAcceptableIndustry] = useState(false)

  function handleFormSubmit (): void {
    if (!isFormValidForSubmit()) return
    handleSubmit(formData)
  }

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function onChangeTextArea (event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const { name, value } = event.target
    updateForm(name, value)
  }

  function updateForm (name: string, value: string): void {
    updateInlineErrorState(name, undefined)
    setInlineError(undefined)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  function updateInlineErrorState (name: string, value?: string): void {
    setInlineErrorState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  function onIsMailingAddressSameAsBusinessAddressChange (): void {
    // If setting addresses as different, set mailing address null
    // If setting addresses as same, do so
    if (isMailingAddressSameAsBusinessAddress) {
      onMailingAddressChange(null)
    } else {
      onMailingAddressChange(formData.physicalAddress ?? null)
    }
    setIsMailingAddressSameAsBusinessAddress(!isMailingAddressSameAsBusinessAddress)
  }

  function onPhysicalAddressChange (address: AddressInput): void {
    updateAddress('physicalAddress', address)
    if (isMailingAddressSameAsBusinessAddress) {
      onMailingAddressChange(address)
    }
  }

  function onMailingAddressChange (address: AddressInput | null): void {
    updateAddress('mailingAddress', address)
  }

  function updateAddress (name: string, address: AddressInput | null): void {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: address
    }))
  }

  function isFormValidForSubmit (): boolean {
    let hasError = false
    if (isEmpty(formData.businessDescription)) {
      updateInlineErrorState('businessDescription', fieldRequiredMessage('Business Description'))
      hasError = true
    }
    if (isEmpty(formData.businessIdentificationNumber)) {
      updateInlineErrorState('businessIdentificationNumber', fieldRequiredMessage('Business Identification Number'))
      hasError = true
    }
    if (isEmpty(formData.incorporationState)) {
      updateInlineErrorState('incorporationState', fieldRequiredMessage('Incorporation State'))
      hasError = true
    }
    if (isEmpty(formData.legalName)) {
      updateInlineErrorState('legalName', fieldRequiredMessage('Legal Name'))
      hasError = true
    }
    if (isEmpty(formData.legalStructure)) {
      updateInlineErrorState('legalStructure', fieldRequiredMessage('Legal Structure'))
      hasError = true
    }
    if (isEmpty(formData.phoneNumber)) {
      updateInlineErrorState('phoneNumber', fieldRequiredMessage('Phone Number'))
      hasError = true
    }
    if (isEmpty(formData.url)) {
      updateInlineErrorState('url', fieldRequiredMessage('URL'))
      hasError = true
    }

    const {
      isInvalid: isAddressInvalid,
      reason: addressInvalidReason
    } = isInvalidAddress(formData.physicalAddress ?? {})
    if (isAddressInvalid) {
      updateInlineErrorState('physicalAddress', addressInvalidReason ?? fieldRequiredMessage('Physical Address'))
      hasError = true
    }

    if (hasError) {
      setInlineError(Error('Please check your form fields.'))
    }

    return !hasError
  }

  return (
    <Flex flexDir='column' gap={8}>
      <Stack spacing={4}>
        <FormInput
          fieldName='legalName'
          label='Legal Name of Business'
          value={formData.legalName ?? ''}
          fieldError={inlineErrorState.legalName}
          onChange={onChange}
          placeholder='i.e. Acme LLC.'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='doingBusinessAsName'
          label='Doing Business as (if applicable)'
          value={formData.doingBusinessAsName ?? ''}
          fieldError={inlineErrorState.doingBusinessAsName}
          onChange={onChange}
          placeholder='i.e. Acme Corp'
          backgroundColor={Color.WHITE}
        />
        <FormTextArea
          fieldName='businessDescription'
          label='Description of Business'
          value={formData.businessDescription ?? ''}
          fieldError={inlineErrorState.businessDescription}
          onChange={onChangeTextArea}
          placeholder='i.e. Holding company for sandwich chain'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormEINInput
          fieldName='businessIdentificationNumber'
          label='Employer Identification Number (EIN)'
          value={formData.businessIdentificationNumber ?? ''}
          fieldError={inlineErrorState.businessIdentificationNumber}
          onChange={(value) => { updateForm('businessIdentificationNumber', value ?? '') }}
          placeholder='9 Digit Business Tax ID Number'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <SelectorComponent
          fieldName='legalStructure'
          label='Legal Structure'
          options={HUMAN_READABLE_LEGAL_STRUCTURES}
          alignText='start'
          placeHolder='Select Legal Structure'
          value={formData.legalStructure}
          fieldError={inlineErrorState.legalStructure}
          handleSelection={legalStructure => { updateForm('legalStructure', legalStructure) }}
          backgroundColor={Color.WHITE}
          isRequired
        />
        <SelectorComponent
          fieldName='incorporationState'
          label='Incorporation State'
          options={STATES}
          alignText='start'
          placeHolder='Select State'
          value={formData.incorporationState}
          fieldError={inlineErrorState.incorporationState}
          handleSelection={state => { updateForm('incorporationState', state) }}
          backgroundColor={Color.WHITE}
          isRequired
        />
        <FormInput
          fieldName='phoneNumber'
          label='Business Phone Number'
          value={formData.phoneNumber ?? ''}
          fieldError={inlineErrorState.phoneNumber}
          onChange={onChange}
          placeholder='i.e. 999-999-9999'
          backgroundColor={Color.WHITE}
          isRequired
        />
        <AddressForm
          formName='Physical Address'
          subtext={BUSINESS_ADDRESS_DISCLAIMER_TEXT}
          prepulatedAddress={businessApplication?.physicalAddress}
          onUpdate={onPhysicalAddressChange}
          fieldError={inlineErrorState.physicalAddress}
          backgroundColor={Color.WHITE}
          isRequired
        />
        <Flex justifyContent='space-between' py={4}>
          <Text>Mailing Address is the same as Business Address</Text>
          <Switch
            id='mailingAddressEnabled'
            isChecked={isMailingAddressSameAsBusinessAddress}
            onChange={onIsMailingAddressSameAsBusinessAddressChange}
          />
        </Flex>
        {!isMailingAddressSameAsBusinessAddress &&
          <AddressForm
            formName='Mailing Address'
            subtext={BUSINESS_ADDRESS_DISCLAIMER_TEXT}
            prepulatedAddress={businessApplication?.mailingAddress}
            onUpdate={onMailingAddressChange}
            fieldError={inlineErrorState.mailingAddress}
            backgroundColor={Color.WHITE}
          />
          }
        <FormInput
          fieldName='url'
          label='Business URL'
          value={formData.url ?? ''}
          fieldError={inlineErrorState.url}
          onChange={onChange}
          placeholder='https://yourcompany.com'
          isRequired={true}
          backgroundColor={Color.WHITE}
        />
        <Flex alignItems='start' py={4}>
          <Radio
            isChecked={isAcceptableIndustry}
            onClick={() => { setIsAcceptableIndustry(!isAcceptableIndustry) }}
            size='lg'
            colorScheme='selectableInput'
          />
          <Flex flexDir='column' pl={3}>
            <Text>
              I acknowledge that neither this entity nor any of its affiliates or subsidiaries are one of the following:
            </Text>
            <BlackListedCompanyTypesListComponent/>
          </Flex>
        </Flex>
      </Stack>
      <Flex flexDir='column' gap={4}>
        <Button
          text='Save & Continue'
          onClick={handleFormSubmit}
          isLoading={isSubmissionLoading}
          variant={ButtonVariant.PRIMARY}
          isDisabled={!isAcceptableIndustry}
          onClickEventType={Event.BUSINESS_APPLICATION_SUBMISSION_CLICK}
        />
        <Button
          text='Cancel'
          onClick={handleCancel}
          variant={ButtonVariant.WHITE_OUTLINE}
          onClickEventType={Event.BUSINESS_APPLICATION_CANCEL_CLICK}
        />
        <ErrorInline
          error={
            (submissionError != null || inlineError != null)
              ? {
                  customContent: {
                    title: getErrorTitle(inlineError),
                    subtitle: inlineError?.message ?? ErrorCopy.TRY_AGAIN_LATER
                  },
                  error: submissionError ?? inlineError as Error
                }
              : undefined
          }
        />
      </Flex>
    </Flex>
  )
}

/**
 * Error message should reflect the fact that form submission was
 * prevented in the event of an inline error. Otherwise, indicate that
 * we couldn't submit the application as a whole
 */
function getErrorTitle (inlineError?: Error): string {
  if (inlineError != null) return 'Unable to submit form'

  return 'Problem submitting business application'
}
