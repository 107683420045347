import { getFormattedFullName } from './stringUtils'
import {
  type BusinessApplicationFormData
} from
  '../components/pages/amplify_account_application/routes/apply/components/business/BusinessApplicationFormComponent'
import {
  type PersonalApplicationFormData
} from '../components/pages/amplify_account_application/routes/apply/components/person/PersonApplicationFormComponent'
import {
  // eslint-disable-next-line max-len
  type GetAmplifyAccountApplication_currentUser_franchiseGroup_amplifyAccountApplication_personalApplications as Applicant
} from '../graphql/__generated__/GetAmplifyAccountApplication'
import {
  AmplifyAccountApplicationRole,
  AmplifyAccountApplicationStatus,
  AmplifyAccountBusinessLegalStructure,
  type UpdateAddress
} from '../graphql/__generated__/globalTypes'
import {
  type UpdateAmplifyAccountBusinessApplicationVariables
} from '../graphql/__generated__/UpdateAmplifyAccountBusinessApplication'
import {
  type UpdateAmplifyAccountPersonalApplicationVariables
} from '../graphql/__generated__/UpdateAmplifyAccountPersonalApplication'
import { type AddressInput } from '../components/library/form/address/AddressForm'
import {
  ExpectedTransferVolume
} from '@/components/pages/amplify_account_application/routes/apply/components/account/AccountUsageFormComponent'
import {
  AmplifyApplicationContext
} from '@/components/pages/amplify_account_application/routes/apply/AmplifyApplicationContainerComponent'
import { ROUTES } from '@/api/routes'

export enum AmplifyApplicationStep {
  PERSON,
  OWNERSHIP, // Management of all individual people
  OWNERSHIP_ATTESTATION,
  BUSINESS,
  ACCOUNT_USAGE,
  DISCLOSURES
}

export type LegalStructureHumanReadable =
  'C Corporation' |
  'Corporation' |
  'Estate' |
  'Foreign Entity' |
  'Limited Liability Corporation (LLC)' |
  'Limited Liability Partnership (LLP)' |
  'Nonprofit' |
  'Partnership' |
  'Limited Partnership (LP)' |
  'Scheme' |
  'S Corporation' |
  'Sole Proprietorship' |
  'Trust'

export const HUMAN_READABLE_LEGAL_STRUCTURES: LegalStructureHumanReadable[] = [
  'C Corporation',
  'Corporation',
  'Estate',
  'Foreign Entity',
  'Limited Liability Corporation (LLC)',
  'Limited Liability Partnership (LLP)',
  'Nonprofit',
  'Partnership',
  'Limited Partnership (LP)',
  'Scheme',
  'S Corporation',
  'Sole Proprietorship',
  'Trust'
]

const LEGAL_STRUCTURE_TO_HUMAN_READABLE_LEGAL_STRUCTURE:
Record<LegalStructureHumanReadable, AmplifyAccountBusinessLegalStructure> = {
  'C Corporation': AmplifyAccountBusinessLegalStructure.ccorp,
  Corporation: AmplifyAccountBusinessLegalStructure.corp,
  Estate: AmplifyAccountBusinessLegalStructure.estate,
  'Foreign Entity': AmplifyAccountBusinessLegalStructure.foreign_entity,
  'Limited Liability Corporation (LLC)': AmplifyAccountBusinessLegalStructure.llc,
  'Limited Liability Partnership (LLP)': AmplifyAccountBusinessLegalStructure.llp,
  Nonprofit: AmplifyAccountBusinessLegalStructure.nonprofit,
  Partnership: AmplifyAccountBusinessLegalStructure.partnership,
  'Limited Partnership (LP)': AmplifyAccountBusinessLegalStructure.lp,
  Scheme: AmplifyAccountBusinessLegalStructure.scheme,
  'S Corporation': AmplifyAccountBusinessLegalStructure.scorp,
  'Sole Proprietorship': AmplifyAccountBusinessLegalStructure.soleprop,
  Trust: AmplifyAccountBusinessLegalStructure.trust
}

const HUMAN_READABLE_LEGAL_STRUCTURE_TO_LEGAL_STRUCTURE:
Record<AmplifyAccountBusinessLegalStructure, LegalStructureHumanReadable> = {
  [AmplifyAccountBusinessLegalStructure.ccorp]: 'C Corporation',
  [AmplifyAccountBusinessLegalStructure.corp]: 'Corporation',
  [AmplifyAccountBusinessLegalStructure.estate]: 'Estate',
  [AmplifyAccountBusinessLegalStructure.foreign_entity]: 'Foreign Entity',
  [AmplifyAccountBusinessLegalStructure.llc]: 'Limited Liability Corporation (LLC)',
  [AmplifyAccountBusinessLegalStructure.llp]: 'Limited Liability Partnership (LLP)',
  [AmplifyAccountBusinessLegalStructure.lp]: 'Limited Partnership (LP)',
  [AmplifyAccountBusinessLegalStructure.nonprofit]: 'Nonprofit',
  [AmplifyAccountBusinessLegalStructure.partnership]: 'Partnership',
  [AmplifyAccountBusinessLegalStructure.scheme]: 'Scheme',
  [AmplifyAccountBusinessLegalStructure.scorp]: 'S Corporation',
  [AmplifyAccountBusinessLegalStructure.soleprop]: 'Sole Proprietorship',
  [AmplifyAccountBusinessLegalStructure.trust]: 'Trust'
}

export function getBusinessLegalStructure (
  humanReadableLegalStructure?: LegalStructureHumanReadable | null
): AmplifyAccountBusinessLegalStructure | null {
  if (humanReadableLegalStructure == null) return null
  return LEGAL_STRUCTURE_TO_HUMAN_READABLE_LEGAL_STRUCTURE[humanReadableLegalStructure]
}

export function getHumanReadableBusinessLegalStructure (
  legalStructure?: AmplifyAccountBusinessLegalStructure | null
): LegalStructureHumanReadable | null {
  if (legalStructure == null) return null
  return HUMAN_READABLE_LEGAL_STRUCTURE_TO_LEGAL_STRUCTURE[legalStructure]
}

export function getApplicantNameFormatted (
  applicant: Applicant
): string {
  const firstName = applicant.firstName
  const lastName = applicant.lastName
  if (applicant.firstName == null && applicant.lastName == null) {
    return isPrimaryOwner(applicant)
      ? 'The business\'s lead decision maker'
      : 'Additional owner'
  }

  return getFormattedFullName(firstName, lastName)
}

export function isPrimaryOwner (applicant?: Applicant): boolean {
  return (applicant?.roles ?? []).includes(AmplifyAccountApplicationRole.CONTROL_PERSON)
}

export function isAmplifyApplicationActive (
  status?: AmplifyAccountApplicationStatus | null
): boolean {
  return status != null && [
    AmplifyAccountApplicationStatus.CREATED,
    AmplifyAccountApplicationStatus.PROCESSING,
    AmplifyAccountApplicationStatus.SUBMITTED,
    AmplifyAccountApplicationStatus.MANUAL_REVIEW
  ].includes(status)
}

export function isAmplifyApplicationInProgress (
  status?: AmplifyAccountApplicationStatus | null
): boolean {
  return status != null && [
    AmplifyAccountApplicationStatus.CREATED
  ].includes(status)
}

export function isAmplifyApplicationSubmitted (
  status?: AmplifyAccountApplicationStatus | null
): boolean {
  return status != null && [
    AmplifyAccountApplicationStatus.APPROVED,
    AmplifyAccountApplicationStatus.MANUAL_REVIEW,
    AmplifyAccountApplicationStatus.PROCESSING,
    AmplifyAccountApplicationStatus.REJECTED,
    AmplifyAccountApplicationStatus.SUBMITTED
  ].includes(status)
}

export function createUpdateBusinessApplicationVariables (
  formData: BusinessApplicationFormData,
  businessApplicationId?: string | null
): UpdateAmplifyAccountBusinessApplicationVariables {
  if (businessApplicationId == null) throw Error('Invalid CreateBusinessApplication input: id is null')

  return {
    input: {
      id: businessApplicationId,
      businessDescription: formData.businessDescription,
      doingBusinessAsName: formData.doingBusinessAsName,
      incorporationState: formData.incorporationState,
      legalName: formData.legalName,
      legalStructure: getBusinessLegalStructure(formData.legalStructure),
      naicsDescription: formData.naicsDescription,
      naicsSectorCode: formData.naicsSectorCode,
      phoneNumber: formData.phoneNumber,
      physicalAddress: createUpdateAddressVariable(formData.physicalAddress),
      mailingAddress: createUpdateAddressVariable(formData.mailingAddress),
      urls: formData.url != null ? [formData.url] : [],
      businessIdentificationNumber: formData.businessIdentificationNumber
    }
  }
}

export function createUpdatePersonalApplicationVariables (
  formData: PersonalApplicationFormData,
  personalApplicationId?: string | null
): UpdateAmplifyAccountPersonalApplicationVariables {
  if (personalApplicationId == null) throw Error('Invalid CreatePersonalApplication input: id is null')

  return {
    input: {
      id: personalApplicationId,
      businessTitle: formData.businessTitle,
      citizenship: formData.citizenship,
      dateOfBirth: formData.dateOfBirth,
      emailAddress: formData.emailAddress,
      firstName: formData.firstName,
      lastName: formData.lastName,
      mailingAddress: createUpdateAddressVariable(formData.mailingAddress),
      middleName: formData.middleName,
      occupation: formData.occupation,
      ownershipPercentage: Number(formData.ownershipPercentage),
      phoneNumber: formData.phoneNumber,
      physicalAddress: createUpdateAddressVariable(formData.physicalAddress),
      roles: createRoleInput(formData.isPrimaryOwner ?? false),
      socialSecurityNumber: formData.socialSecurityNumber
    }
  }
}

export function hasBeneficialOwner (personalApplications: Applicant[]): boolean {
  return personalApplications
    .some(applicant => applicant.ownershipPercentage != null && applicant.ownershipPercentage >= 25)
}

function createUpdateAddressVariable (addressInput?: AddressInput | null): UpdateAddress | null {
  if (addressInput == null || addressInput.streetAddressLine1 == null) return null
  return {
    streetAddressLine1: addressInput.streetAddressLine1,
    streetAddressLine2: addressInput.streetAddressLine2,
    city: addressInput.city,
    state: addressInput.state,
    country: addressInput.country,
    postalCode: addressInput.postalCode
  }
}

function createRoleInput (isOwner: boolean): AmplifyAccountApplicationRole[] {
  if (isOwner) {
    return [AmplifyAccountApplicationRole.CONTROL_PERSON, AmplifyAccountApplicationRole.SIGNER]
  }

  return [AmplifyAccountApplicationRole.SIGNER]
}

export function getExpectedTransferVolumeDescription (expectedTransferVolume: ExpectedTransferVolume): string {
  switch (expectedTransferVolume) {
    case ExpectedTransferVolume.ZERO_TO_TEN:
      return '0 to 10 transfers per month'
    case ExpectedTransferVolume.TEN_TO_TWENTY_FIVE:
      return '10 - 25 transfers per month'
    case ExpectedTransferVolume.MORE_THAN_TWENTY_FIVE:
      return 'More than 25 transfers per month'
  }
}

export function getAmplifyApplicationRouteForContext (
  step: AmplifyApplicationStep,
  context: AmplifyApplicationContext
): string {
  switch (step) {
    case AmplifyApplicationStep.OWNERSHIP: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_OWNERSHIP
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_OWNERSHIP
      }
      break
    }
    case AmplifyApplicationStep.PERSON: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_PERSON
      }
      break
    }
    case AmplifyApplicationStep.OWNERSHIP_ATTESTATION: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_PERSON_CONFIRMATION
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_PERSON_CONFIRMATION
      }
      break
    }
    case AmplifyApplicationStep.BUSINESS: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_BUSINESS
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_BUSINESS
      }
      break
    }
    case AmplifyApplicationStep.ACCOUNT_USAGE: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_USAGE
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_USAGE
      }
      break
    }
    case AmplifyApplicationStep.DISCLOSURES: {
      switch (context) {
        case AmplifyApplicationContext.ONBOARDING:
          return ROUTES.V2_SIGN_UP_AMPLIFY_APP_DISCLOSURES
        case AmplifyApplicationContext.DEFAULT:
          return ROUTES.AMPLIFY_APP_DISCLOSURES
      }
    }
  }
}

export function getAmplifyApplicationCTA (status: AmplifyAccountApplicationStatus | null): string {
  if (status == null) return 'Apply for an Amplify Account'
  switch (status) {
    case AmplifyAccountApplicationStatus.DOES_NOT_EXIST:
      return 'Apply for an Amplify Account'
    case AmplifyAccountApplicationStatus.CREATED:
      return 'Continue Amplify Account Application'
    case AmplifyAccountApplicationStatus.SUBMITTED:
    case AmplifyAccountApplicationStatus.MANUAL_REVIEW:
    case AmplifyAccountApplicationStatus.PROCESSING:
      return 'Amplify Account Application Under Review'
    case AmplifyAccountApplicationStatus.ERROR:
    case AmplifyAccountApplicationStatus.DELETED:
      return 'Amplify Account Application Error'
    case AmplifyAccountApplicationStatus.REJECTED:
      return 'Amplify Account Application Rejected'
    case AmplifyAccountApplicationStatus.APPROVED:
      return 'Amplify Account Application Approved'
  }
}
