import {
  InputGroup,
  Textarea
} from '@chakra-ui/react'
import
React, {
  type ChangeEvent,
  type ReactElement
} from 'react'
import FormLabelWrapper from '../FormLabelWrapper'
import { BorderRadius, Color, FontWeight, Height } from '../../theme/theme'
import { type BaseFormInputProps } from '../types'

export type FormInputProps = {
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
} & BaseFormInputProps

export default function FormTextArea (
  {
    fieldName,
    label,
    labelSize = 'medium',
    onChange,
    value,
    placeholder,
    isDisabled = false,
    buttonLinkPadding,
    backgroundColor = Color.WHITE,
    fieldError,
    isRequired = false
  }: FormInputProps
): ReactElement {
  const isInErrorState = fieldError != null
  return (
    <FormLabelWrapper
      fieldName={fieldName}
      label={label ?? ''}
      fontSize={labelSize}
      isRequired={isRequired}
    >
      <InputGroup>
        <Textarea
          name={fieldName}
          value={value}
          _hover={{ backgroundColor }}
          _focus={{ backgroundColor: Color.WHITE, borderColor: Color.DARK_GREY }}
          variant='filled'
          onChange={onChange}
          placeholder={placeholder}
          backgroundColor={backgroundColor}
          borderRadius={BorderRadius.CARD}
          w='100%'
          h={Height.BUTTON}
          px='24px'
          pr={buttonLinkPadding}
          isDisabled={isDisabled}
          color={Color.DARK_BLUE}
          fontWeight={FontWeight.NORMAL}
          borderColor={isInErrorState ? Color.ERROR_RED : backgroundColor}
          borderStyle={isInErrorState ? 'solid' : 'transparent'}
        />
      </InputGroup>
    </FormLabelWrapper>
  )
}
