import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Spacer } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import VerticalNavBar from './VerticalNavBar'
import DisclosureFooter from './DisclosureFooter'
import HelpCenterPopoverComponent from './help_center/HelpCenterPopoverComponent'
import HorizontalNavBar from './horizontal_nav_bar/HorizontalNavBar'
import { Height, Color } from '../theme/theme'
import UserInactivityWarningComponent from '../utility/UserInactivityWarningComponent'
import {
  type GetCurrentUserVariables,
  type GetCurrentUser
} from '@/graphql/__generated__/GetCurrentUser'
import { GET_CURRENT_USER } from '@/graphql/queries/GetCurrentUser'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { shouldShowDisclosureFooter } from '@/utils/routerUtils'

export default function NavContainerLayout (): ReactElement {
  const location = useLocation()
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [isVerticalNavBarExpanded, setIsVerticalNavBarExpanded] = useState(true)
  const { data, loading } = useQuery<GetCurrentUser, GetCurrentUserVariables>(
    GET_CURRENT_USER,
    { variables: { organizationId: selectedOrganizationId } }
  )
  const shouldShowInActivityBlur = data?.currentUser?.rolloutConfiguration?.enableInActivityBlur === true

  return (
    <>
      <Flex height='100vh' bg={Color.LIGHT_GREY}>
        <VerticalNavBar
          currentUser={data?.currentUser}
          amplifyAccounts={data?.amplifyAccounts ?? []}
          isLoading={loading}
          isExpanded={isVerticalNavBarExpanded}
          onToggle={() => { setIsVerticalNavBarExpanded(!isVerticalNavBarExpanded) }}
        />
        <Flex
          overflowY='auto'
          paddingTop={Height.NAVBAR}
          width='100%'
          flexDir='column'
          justifyContent='start'
          alignItems='center'
        >
          <Flex
            alignItems='start'
            justifyContent='center'
            w='100%'
          >
            <Outlet/>
          </Flex>
          <Spacer/>
          {shouldShowDisclosureFooter(location) && <DisclosureFooter/>}
        </Flex>
      </Flex>
      <HorizontalNavBar currentUser={data?.currentUser} isVerticalNavBarExpanded={isVerticalNavBarExpanded}/>
      <HelpCenterPopoverComponent/>
      {shouldShowInActivityBlur && <UserInactivityWarningComponent/>}
    </>
  )
}
