import React, {
  type ReactElement
} from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import {
  type GetAvailableStatements_availableStatements as Statement
} from '@/graphql/__generated__/GetAvailableStatements'
import { dateTimeFromDateTimeTemplate, DateTimeTemplate, getISODateOrUndefined } from '@/utils/dateUtils'
import { Color } from '@/theme/theme'
import { FileCenterComponent } from '@/library/utility/FileCenterComponent'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import TransactionsRowListComponent from '@/library/transactions/TransactionsRowListComponent'
import NoTransactionsFoundComponent from '@/components/pages/transactions/components/NoTransactionsFoundComponent'
import { useFetchAccountTransactions } from '@/hooks/data/useFetchAccountTransactions'

export interface TransactionListComponentProps {
  accountId: number
  selectedStatement: Statement | null
  customStatement?: CustomStatement
  shouldIncludePending: boolean
}

export interface CustomStatement {
  accountId?: string
  startDate: string
  endDate: string
  description: string
}

export default function StatementTransactionListComponent (
  {
    accountId,
    selectedStatement,
    customStatement,
    shouldIncludePending
  }: TransactionListComponentProps
): ReactElement {
  const { startDate, endDate } = getDateRange(selectedStatement, customStatement)
  const {
    data: transactions,
    loading,
    error
  } = useFetchAccountTransactions(
    accountId,
    // TODO (PJ): Add pagination components
    {
      limit: 1000,
      startDate: convertDateTemplateToISO(startDate),
      endDate: convertDateTemplateToISO(endDate),
      shouldIncludePending
    }
  )

  return (
    <Flex flexDirection='column' >
      <Flex justifyContent='space-between' alignItems='center'>
        <Heading
          size='sm'
          color={Color.DARK_GREY}
        >
          Transaction History
        </Heading>
        {/* TODO (PJ): Migrate to new file export component */}
        <FileCenterComponent
          accountId={String(accountId)}
          startDate={startDate}
          endDate={endDate}
        />
      </Flex>
      <AltirSkeleton isLoading={loading} error={error}>
        <Flex
          width='100%'
          flexDirection='column'
        >
          {transactions.length < 1
            ? <NoTransactionsFoundComponent/>
            : <TransactionsRowListComponent
                transactionsData={transactions}
              />
          }
        </Flex>
      </AltirSkeleton>
    </Flex>
  )

  function getDateRange (
    selectedStatement: Statement | null,
    customStatement?: CustomStatement
  ): { startDate?: string, endDate?: string } {
    const customStatementIsSelected = customStatement?.startDate != null && customStatement.startDate !== ''
    const startDate = customStatementIsSelected
      ? customStatement.startDate
      : selectedStatement?.startDate ?? undefined
    const endDate = customStatementIsSelected
      ? customStatement.endDate
      : selectedStatement?.endDate ?? undefined
    return { startDate, endDate }
  }
}

// This helper is only for maintaining backwards compatibility with the old date format,
// this should not be used going forward
function convertDateTemplateToISO (dateTimeString?: string): string | undefined {
  if (dateTimeString == null) {
    return undefined
  }
  return getISODateOrUndefined(dateTimeFromDateTimeTemplate(dateTimeString, DateTimeTemplate.M_D_YY))
}
