import { gql } from '@apollo/client'
import { FINANCIAL_ACCOUNT_FRAGMENT } from './financial_accounts/FinancialAccountFragment'

export const TRANSACTION_FRAGMENT = gql`
    ${FINANCIAL_ACCOUNT_FRAGMENT}
    fragment TransactionFragment on Transaction {
        id
        description
        originalDescription
        amount
        merchantFormatted
        bankAccountId
        isPendingTransaction
        categoryLabel
        date
        originatingTransfer {
            direction
            amplifyAccount {
                ...FinancialAccountFragment
            }
            counterparty {
                ...FinancialAccountFragment
            }
        }
    }
`
