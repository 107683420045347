import { gql } from '@apollo/client'
import { TRANSACTION_FRAGMENT } from '../../fragments/TransactionFragment'

export const GET_STATEMENT = gql`
    ${TRANSACTION_FRAGMENT}
    query GetStatement(
        $organizationId: String!,
        $accountId: Int!,
        $startDate: String,
        $endDate: String,
        $shouldIncludePendingTransactions: Boolean
        $sort: TransactionSortInput,
        $filter:TransactionFilterInput,
        $offset: Int,
        $limit: Int
    ) { 
        statement(
            accountId: $accountId, 
            startDate: $startDate, 
            endDate: $endDate, 
            shouldIncludePendingTransactions: $shouldIncludePendingTransactions
        ) {
            startingBalance {
                amountFormatted
            }
            endingBalance {
                amountFormatted
            }
            totalDeposits {
                amountFormatted
            }
            totalWithdrawals {
                amountFormatted
            }
            startDate
            endDate
            financialAccount {
                accountId
                name
                nameOfficial
                accountType
                accountSubTypeFormatted
                lastFour
                institution {
                    name
                    logoAssetUrl
                }
                amplifyAccount {
                    interestRateFormatted
                    earningsToDate {
                        amountFormatted
                    }
                }
                achAccountNumber
                achRoutingNumber
            }
        }
        currentUser{
            selectedOrganization(organizationId: $organizationId) {
                transactions(sort: $sort, filter: $filter, offset: $offset, limit: $limit) {
                    transactions {
                        ...TransactionFragment
                    }
                }
            }
        }
    }
`
