import React, { useState, type ReactElement } from 'react'
import { Center, FormControl, Heading, useToast } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { FormInput } from '@/library/form/text/FormInput'
import ErrorInline from '@/library/errors/ErrorInline'
import { type ErrorWithContent } from '@/types/types'
import { type UpdateUser, type UpdateUserVariables } from '@/graphql/__generated__/UpdateUser'
import { UPDATE_USER } from '@/graphql/mutations/UpdateUser'
import { getSuccessToast } from '@/utils/toastUtils'
import { type NullableUser } from '@/library/layout/user_row/UserInfoRow'
import { getUpdateUserError } from '@/utils/errorUtils'
import { ModalComponent } from '@/library/modal/ModalComponent'
import Button, { ButtonVariant } from '@/library/button/Button'

interface EditUserModalProps {
  isOpen: boolean
  onClose: () => void
  user?: NullableUser
  refetch: () => void
}

export default function EditUserModal ({ isOpen, onClose, user, refetch }: EditUserModalProps): ReactElement {
  const [editUserSubmissionError, setEditUserSubmissionError] = useState<ErrorWithContent>()
  const [editUserFormData, setEditUserFormData] = useState<NullableUser | undefined>(user)
  const toast = useToast()

  const [
    updateUser,
    { loading: isUpdateUserLoading }
  ] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER,
    {
      onCompleted: () => {
        refetch()
        onClose()
        toast(getSuccessToast('User Updated'))
      },
      onError: (error) => {
        setEditUserSubmissionError(getUpdateUserError(error))
      }
    }
  )

  function onUserEdit (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    setEditUserFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value
      }
      setEditUserFormData(updatedFormData)
      return updatedFormData
    })
  }

  function handleUserEditSubmission (): void {
    if (editUserFormData?.id == null) {
      setEditUserSubmissionError({
        error: Error('invalid form state -- userId is null')
      })
      return
    }
    void updateUser({
      variables: {
        userId: editUserFormData.id,
        input: {
          firstName: editUserFormData.firstName,
          lastName: editUserFormData.lastName,
          email: editUserFormData.email
        }
      }
    })
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
    >
      <Center flexDir='column' gap={8} w='100%'>
        <Heading>Edit User</Heading>
        <FormControl>
          <Center w='100%' flexDir='column' gap={2}>
            <FormInput
              fieldName='firstName'
              value={editUserFormData?.firstName ?? ''}
              label='First Name'
              onChange={onUserEdit}
              placeholder=''
            />
            <FormInput
              fieldName='lastName'
              value={editUserFormData?.lastName ?? ''}
              label='Last Name'
              onChange={onUserEdit}
              placeholder=''
            />
            <FormInput
              fieldName='email'
              value={editUserFormData?.email ?? ''}
              label='Email Address'
              onChange={onUserEdit}
              placeholder=''
            />
          </Center>
        </FormControl>
        <ErrorInline error={editUserSubmissionError}/>
        <Center flexDir='column' gap={4} w='100%'>
          <Button
            text='Save Changes'
            onClick={handleUserEditSubmission}
            isLoading={isUpdateUserLoading}
          />
          <Button
            text='Cancel'
            onClick={onClose}
            variant={ButtonVariant.WHITE}
          />
        </Center>
      </Center>
    </ModalComponent>
  )
}
