import React, { type ReactElement } from 'react'
import { Flex, Text, type ResponsiveValue } from '@chakra-ui/react'
import { type Property } from 'csstype'
import { IconFacing } from '@/types/types'
import { Color, FontWeight, IconSize } from '@/theme/theme'
import CarrotComponent from '@/library/utility/toggle/CarrotComponent'

export enum PopOverMenuButtonVariant {
  DEFAULT,
  VERTICAL
}

interface MenuButtonStyles {
  alignItems: string
  justifyContent: string
  flexDir: ResponsiveValue<Property.FlexDirection>
  gap: number
  titleSize: string
  subtitleSize: string
}

interface PopoverMenuButtonContentProps {
  isOpen: boolean
  title: string
  subtitle?: string
  beforeIcon?: ReactElement

  // Styles
  variant?: PopOverMenuButtonVariant
  shouldRotateIcon?: boolean
  height?: string
}

export default function PopoverMenuButtonContent ({
  isOpen,
  title,
  subtitle,
  beforeIcon,
  height,
  variant = PopOverMenuButtonVariant.DEFAULT,
  shouldRotateIcon = true
}: PopoverMenuButtonContentProps): ReactElement {
  const carrotDirection = (isOpen || !shouldRotateIcon) ? IconFacing.DOWN : IconFacing.RIGHT

  const { alignItems, justifyContent, flexDir, gap, subtitleSize, titleSize } = getStyles(variant)

  return (
    <Flex
      flexDir='row'
      w='100%'
      h='100%'
      align='center'
      gap={beforeIcon != null ? 12 : 2}
      pl={beforeIcon != null ? 4 : 6}
      pr={4}
      height={height}
    >
      <Flex
        alignItems={alignItems}
        justifyContent={justifyContent}
        flexDir={flexDir}
        gap={gap}
        overflowX='hidden'
        w='100%'
      >
        {beforeIcon}
        <Text color={Color.DARK_BLUE} noOfLines={1} fontSize={titleSize} >{title}</Text>
        <Text
          color={Color.DARK_BLUE}
          noOfLines={1}
          fontSize={subtitleSize}
          fontWeight={FontWeight.NORMAL}
        >
          {subtitle}
        </Text>
      </Flex>
      <Flex ml='auto'>
        <CarrotComponent facing={carrotDirection} size={IconSize.SMALL} color={Color.DARK_BLUE}/>
      </Flex>
    </Flex>
  )
}

function getStyles (
  variant: PopOverMenuButtonVariant
): MenuButtonStyles {
  switch (variant) {
    case PopOverMenuButtonVariant.DEFAULT:
      return {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDir: 'row',
        gap: 2,
        titleSize: 'md',
        subtitleSize: 'sm'
      }
    case PopOverMenuButtonVariant.VERTICAL:
      return {
        alignItems: 'start',
        justifyContent: 'center',
        flexDir: 'column',
        gap: 0,
        titleSize: 'sm',
        subtitleSize: 'xs'
      }
  }
}
