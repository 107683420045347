import React, { type ReactNode, type ReactElement, useState } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color, FontWeight, IconSize } from '../theme/theme'
import ModalCloseHeader from '../modal/ModalCloseHeader'
import CircleExclamationIcon from '../icons/CircleExclamationIcon'

export interface ErrorBannerProps {
  errorTitle?: string | null
  errorSubTitle?: string | null
  callToActionComponent?: ReactNode
  inlineCallToActionComponent?: ReactNode
  isCollapsible?: boolean
  onClose?: () => void
  isHidden?: boolean
}

export default function ErrorBanner ({
  errorTitle,
  errorSubTitle,
  callToActionComponent,
  inlineCallToActionComponent,
  isCollapsible,
  isHidden = false,
  onClose = () => {}
}: ErrorBannerProps): ReactElement | null {
  const [isOpen, setIsOpen] = useState(!isHidden)

  function onModalClose (): void {
    onClose()
    setIsOpen(false)
  }

  if (errorTitle == null || errorSubTitle == null || !isOpen) return null
  return (
    <Flex
      borderRadius={BorderRadius.CARD}
      py={4}
      flexDirection='column'
      width='100%'
      backgroundColor={Color.WHITE}
    >
      <Flex
        flexDirection='row'
        px={4}
      >
        <Flex gap={4} justifyContent='flex-start' flex={9}>
          <Flex justifyContent='center' alignItems='center'>
            <CircleExclamationIcon color={Color.ERROR_RED} size={IconSize.LARGE}/>
          </Flex>
          <Flex flexDirection='column' color={Color.ERROR_RED} gap={2} padding={2}>
            <Heading size='sm' textColor={Color.DARK_BLUE}>{errorTitle}</Heading>
            <Text fontWeight={FontWeight.MEDIUM} textColor={Color.DARK_GREY}>{errorSubTitle}</Text>
          </Flex>
        </Flex>
        {inlineCallToActionComponent}
        {isCollapsible === true &&
        <Flex flex={1}>
          <ModalCloseHeader onClose={() => { onModalClose() }} iconSize={IconSize.SMALL}/>
        </Flex>}
      </Flex>
      {callToActionComponent}
    </Flex>
  )
}
