import { gql } from '@apollo/client'

export const GET_TRANSFER_PAGE_DATA = gql`
    query GetTransferPageData($franchiseGroupId: Int!) {
        counterparties(franchiseGroupId: $franchiseGroupId) {
            accountId
            accountSubTypeFormatted
            accountType
            liveBalance {
                availableBalance {
                    amount
                    amountFormatted
                    currency
                }
                updatedAt
            }
            institution {
                logoAssetUrl
                name
            }
            lastFour
            name
            status
            counterpartyId
            achAccountNumber
            achRoutingNumber
            wireAccountNumber
            wireRoutingNumber
            counterparty {
                nickname
                nameOnAccount
                achAccountNumber
                isOwnershipVerified
                counterpartyType
            }
            connectionProvider
        }
        amplifyAccount(franchiseGroupId: $franchiseGroupId) {
            accountId
            accountSubTypeFormatted
            accountType
            liveBalance {
                availableBalance {
                    amount
                    amountFormatted
                    currency
                }
                updatedAt
            }
            institution {
                logoAssetUrl
                name
            }
            lastFour
            name
            status
            counterpartyId
            amplifyAccount {
                id
            }
        }
        currentUser {
            hasPasscode
            rolloutConfiguration {
                enableRecurringVendorPayments
            }
        }
    }
`

export const GET_AMPLIFY_ACCOUNT_INFO = gql`
    query GetAmplifyAccountInfo($franchiseGroupId: Int!) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                amplifyAccount {
                    achAccountNumber
                    achRoutingNumber
                    amplifyAccount {
                        people {
                            firstName
                            lastName
                        }
                    }
                    institution {
                        name
                    }
                    accountSubTypeFormatted
                }
            }
        }
    }
`
