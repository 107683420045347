import React, { type ReactElement } from 'react'
import { Flex, type PlacementWithLogical, Tooltip } from '@chakra-ui/react'
import InfoIcon from '../icons/InfoIcon'
import { BorderRadius, Color, IconSize } from '../theme/theme'

interface MoreInfoTooltipProps {
  tooltipText: string
  placement?: PlacementWithLogical
  iconSize?: IconSize
}

export default function MoreInfoTooltip ({
  tooltipText,
  placement = 'right',
  iconSize = IconSize.SMALL
}: MoreInfoTooltipProps): ReactElement {
  return (
    <Tooltip
      label={tooltipText}
      bg={Color.BRIGHT_BLUE}
      color={Color.WHITE}
      borderRadius={BorderRadius.CARD}
      py={2}
      px={4}
      placement={placement}
    >
      <Flex>
        <InfoIcon color={Color.DARK_GREY} size={iconSize}/>
      </Flex>
    </Tooltip>
  )
}
