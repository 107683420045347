import React, { type ReactElement } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import SearchBar from '@/library/form/text/SearchBar'
import {
  type GetTreasuryPageData_currentUser_franchiseGroup_financialAccounts_defaultStoreLocation as StoreLocation
} from '@/graphql/__generated__/GetTreasuryPageData'
import PopoverMenuMultiSelector from '@/library/popoverMenu/PopoverMenuMultiSelector'
import { AccountsSortByType, type AccountsSortByInput } from '@/types/types'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import {
  type GetAccountsPageData_currentUser_selectedOrganization_franchiseGroups as FranchiseGroup
} from '@/graphql/__generated__/GetAccountsPageData'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { ButtonHeight } from '@/theme/theme'
import { PopOverMenuButtonVariant } from '@/library/popoverMenu/components/button/PopoverMenuButtonContent'

enum DropdownSortType {
  BALANCE_HIGH_TO_LOW = 'Balance (High to Low)',
  BALANCE_LOW_TO_HIGH = 'Balance (Low to High)',
}

const ACCOUNT_TYPE_OPTIONS = [
  { id: LinkedAccountType.CARD, label: 'Card' },
  { id: LinkedAccountType.BANK, label: 'Bank Account' }
]

interface AccountsSortAndFilterComponentProps {
  // Text Search
  queryValue?: string
  setQueryValue: (value: string) => void

  // Institution Search
  selectedInstitutions: string[]
  institutionOptions: string[]
  setSelectedInstitutions: (values: string[]) => void

  // Location Search
  selectedLocations: string[]
  locationOptions: StoreLocation[]
  setSelectedLocations: (values: string[]) => void

  // Business Search
  selectedBusinesses?: string[]
  businessOptions?: FranchiseGroup[]
  setSelectedBusinesses?: (values: string[]) => void

  // Account Type Search
  selectedAccountTypes?: LinkedAccountType[]
  shouldIncludeAccountTypes?: boolean
  setSelectedAccountType?: (values: LinkedAccountType[]) => void

  // Sort
  sortByInput: AccountsSortByInput
  setSortByInput: (value: AccountsSortByInput) => void
}

export default function AccountsSortAndFilterComponent ({
  queryValue,
  setQueryValue,
  institutionOptions,
  selectedInstitutions,
  setSelectedInstitutions,
  selectedLocations,
  locationOptions,
  setSelectedLocations,
  selectedBusinesses = [],
  businessOptions = [],
  setSelectedBusinesses = () => {},
  selectedAccountTypes = [],
  shouldIncludeAccountTypes = false,
  setSelectedAccountType = () => {},
  sortByInput,
  setSortByInput
}: AccountsSortAndFilterComponentProps): ReactElement {
  const hasStoreLocation = locationOptions.length > 0
  const hasBusiness = businessOptions.length > 0

  function dropdownSortTypeToSortByInput (dropdownSortType: DropdownSortType): AccountsSortByInput {
    switch (dropdownSortType) {
      case DropdownSortType.BALANCE_HIGH_TO_LOW:
        return { type: AccountsSortByType.BALANCE, ascending: false }
      case DropdownSortType.BALANCE_LOW_TO_HIGH:
        return { type: AccountsSortByType.BALANCE, ascending: true }
    }
  }

  function sortByInputToDropdownSortType (accountsSortByInput: AccountsSortByInput): DropdownSortType {
    switch (accountsSortByInput.type) {
      case AccountsSortByType.BALANCE:
        return accountsSortByInput.ascending
          ? DropdownSortType.BALANCE_LOW_TO_HIGH
          : DropdownSortType.BALANCE_HIGH_TO_LOW
    }

    // Note that this code path should never be hit since we don't yet support AccountsSortByType.NAME
    throw Error('Unsupported sort type')
  }

  function getLinkedAccountType (accountType: string): LinkedAccountType {
    if (accountType === 'CARD') return LinkedAccountType.CARD
    if (accountType === 'BANK') return LinkedAccountType.BANK

    // Note that this code path should never be hit, see @ACCOUNT_TYPE_OPTIONS
    throw Error('Unsupported account type')
  }

  const sortByDescription = sortByInputToDropdownSortType(sortByInput)

  return (
    <Grid templateColumns='repeat(4, 1fr)' gap={4} alignItems='center'>
      <GridItem colSpan={hasBusiness ? 3 : !hasStoreLocation ? 2 : 1}>
        <SearchBar queryValue={queryValue} onChange={setQueryValue} height={ButtonHeight.MEDIUM}/>
      </GridItem>
      <PopoverMenu
        popoverMenuTitle='Sort by'
        onSubmit={() => {}}
        subtitle={sortByDescription}
        shouldMatchWidth={true}
        hasSelection={true}
        buttonVariant={PopOverMenuButtonVariant.VERTICAL}
      >
        <PopoverMenuSingleOptionSelect
          options={Object.values(DropdownSortType)}
          selectedOption={sortByDescription}
          setSelectedOption={(value: DropdownSortType) => {
            setSortByInput(dropdownSortTypeToSortByInput(value))
          }}
        />
      </PopoverMenu>
      {
        hasBusiness &&
        <GridItem colSpan={1}>
          <PopoverMenuMultiSelector
            label='Business'
            selectedOptionIds={selectedBusinesses}
            options={businessOptions.map(business => { return { id: business.id, label: business.name ?? '' } })}
            onChange={setSelectedBusinesses}
            allowInteriorScroll={true}
            shouldMatchWidth
          />
        </GridItem>
        }
      {
        shouldIncludeAccountTypes &&
        <GridItem colSpan={1}>
          <PopoverMenuMultiSelector
            label='Account Type'
            selectedOptionIds={selectedAccountTypes}
            options={ACCOUNT_TYPE_OPTIONS}
            onChange={(values) => { setSelectedAccountType(values.map(getLinkedAccountType)) }}
            allowInteriorScroll={true}
            shouldMatchWidth
          />
        </GridItem>
        }
      <GridItem colSpan={1}>
        <PopoverMenuMultiSelector
          label='Institution'
          selectedOptionIds={selectedInstitutions}
          options={institutionOptions.map(institution => { return { id: institution, label: institution } })}
          onChange={setSelectedInstitutions}
          allowInteriorScroll={true}
          shouldMatchWidth
        />
      </GridItem>
      {
        hasStoreLocation &&
        <GridItem colSpan={1}>
          <PopoverMenuMultiSelector
            label='Location'
            selectedOptionIds={selectedLocations}
            options={locationOptions.map(location => { return { id: location.id, label: location.name } })}
            onChange={setSelectedLocations}
            allowInteriorScroll={true}
            shouldMatchWidth
          />
        </GridItem>
        }
    </Grid>
  )
}
