import { useEffect, type ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

/*
 * This component ensures that when a user hits a new route,
 * the scroll state is reset to the top of the page
 */
export default function ScrollToTopComponent (): ReactElement | null {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return null
}
