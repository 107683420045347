import { gql } from '@apollo/client'
import { TEAM_MEMBER_FRAGMENT } from '@/graphql/fragments/TeamMemberFragment'

export const GET_ORGANIZATION_USERS = gql`
    ${TEAM_MEMBER_FRAGMENT}
    query GetOrganizationUsers($organizationId: String) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                users {
                    ...TeamMemberFragment
                }
            }
        }
    }
`
