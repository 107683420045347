import React, { type ReactElement, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import '../../../assets/css/App.css'
import { StatementSelectorModalContainer } from './components/StatementSelectorModalContainer'
import
AccountStatementComponent
  from '../treasury/components/treasury/AccountStatementComponent'
import useModal from '../../../hooks/useModal'
import CustomTimeFrameFormComponent from '../treasury/components/treasury/CustomTimeframeFormComponent'
import { type CustomStatement } from '../treasury/components/treasury/StatementTransactionListComponent'
import { getAccountTypeFromPath } from '@/utils/routerUtils'
import Page from '@/library/page/Page'

function AccountStatementPage (): ReactElement<string, string> {
  const location = useLocation()
  const { id } = useParams()

  const accountType = getAccountTypeFromPath(location)
  const { isOpen: isModalOpen, toggle } = useModal()
  const [customStatement, setCustomStatement] = useState<CustomStatement>(
    { accountId: '', description: '', startDate: '', endDate: '' })

  return (
    <Page>
      <StatementSelectorModalContainer isOpen={isModalOpen} toggle={toggle}>
        <CustomTimeFrameFormComponent accountId={id} toggle={toggle} setCustomStatement={setCustomStatement} />
      </StatementSelectorModalContainer>
      <AccountStatementComponent
        accountType={accountType}
        accountId={Number(id)}
        isModalOpen={isModalOpen}
        toggle={toggle}
        customStatement={customStatement}
        setCustomStatement={setCustomStatement}
      />
    </Page>
  )
}

export default AccountStatementPage
