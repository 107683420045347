import React, { type ReactNode, type ReactElement } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import ModalCloseHeader from './ModalCloseHeader'
import {
  BorderRadius,
  Color,
  type ModalHeight
} from '../theme/theme'

export interface ModalComponentProps {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode

  // Styles
  height?: ModalHeight
  size?: string
  trapFocus?: boolean
}

export function ModalComponent (
  {
    isOpen,
    onClose,
    children,
    height,
    trapFocus = true,
    size = 'md'
  }: ModalComponentProps
): ReactElement {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      preserveScrollBarGap
      trapFocus={trapFocus}
      size={size}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        pb={8}
        pt={4}
        px={6}
        gap={2}
      >
        <ModalCloseHeader onClose={onClose}/>
        <ModalBody height={height} w='100%'>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
