import { Box, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import InstitutionLogo from '../logo/InstitutionLogo'
import RecurringRuleBalanceIcon from '../icons/RecurringRuleBalanceIcon'
import RecurringRuleTimeIcon from '../icons/RecurringRuleTimeIcon'
import {
  type GetTreasuryPageData_currentUser_franchiseGroup_financialAccounts as FinancialAccount
} from '@/graphql/__generated__/GetTreasuryPageData'
import { AccountLinkStatus, LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import {
  BorderRadius,
  Color,
  Opacity
} from '@/theme/theme'
import { isBalanceRule } from '@/utils/transferRuleUtils'
import { getMask } from '@/utils/financialAccountUtils'

interface FinancialAccountRowProps {
  account: FinancialAccount
}

export default function FinancialAccountRow (
  {
    account
  }: FinancialAccountRowProps
): ReactElement {
  const isLoginRequired = account.status === AccountLinkStatus.LOGIN_REQUIRED
  const isBank = account.accountType === LinkedAccountType.BANK
  const opacity = isLoginRequired ? Opacity.INACTIVE : Opacity.ACTIVE

  const transferRule = account.transferRules[0]

  const displayedAccountBalance = isBank
    ? account.liveBalance?.availableBalance?.amountFormatted
    : account.liveBalance?.currentBalance?.amountFormatted

  return (
    <Flex
      justifyContent='space-between'
      grow={1}
      borderRadius={BorderRadius.CARD}
      alignItems='center'
    >
      <Flex flex={4} align='flex-start' alignItems='center' opacity={opacity}>
        <InstitutionLogo
          src={account.institution?.logoAssetUrl ?? undefined}
          height='6'
        />
        <Box flex={1}>
          <Text color={Color.DARK_GREY} px={3}>
            {getMask(account.lastFour)}
          </Text>
        </Box>
      </Flex>
      <Box flex={6} opacity={opacity}>
        <Text color={Color.DARK_BLUE} px={3} noOfLines={1}>
          {account.name}
        </Text>
      </Box>
      <Box flex={6} opacity={opacity}>
        <Text color={Color.DARK_BLUE} px={3} noOfLines={1}>
          {account.accountSubTypeFormatted}
        </Text>
      </Box>
      <Flex flex={2} align='center' justifyContent='flex-end' opacity={opacity}>
        {(transferRule != null) && isBalanceRule(transferRule.transferRuleType) && <RecurringRuleBalanceIcon/>}
        {(transferRule != null) && !isBalanceRule(transferRule.transferRuleType) && <RecurringRuleTimeIcon/>}
      </Flex>
      <Flex flex={3} align='center' justifyContent='flex-end' opacity={opacity}>
        <Text color={Color.DARK_BLUE} textAlign='end' >
          {displayedAccountBalance}
        </Text>
      </Flex>
    </Flex>
  )
}
