import React, { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import FinancialAccountRow from './FinancialAccountRow'
import DropDownComponent from '../layout/drop_down/DropDownComponent'
import MockDropDownComponent from '../layout/drop_down/MockDropDownComponent'
import {
  type GetTreasuryPageData_currentUser_franchiseGroup_financialAccounts as FinancialAccount
} from '@/graphql/__generated__/GetTreasuryPageData'
import TreasuryAccountDropdownContent
  from '@/components/pages/treasury/components/treasury/treasury_row_dropdown/TreasuryAccountDropdownContent'
import { AccountLinkStatus, LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import { ERROR_BORDER } from '@/utils/errorUtils'
import { ROUTES } from '@/api/routes'

interface FinancialAccountDropDownComponentProps {
  account: FinancialAccount
  refetch: () => void
}

export default function FinancialAccountDropDownComponent ({
  account,
  refetch
}: FinancialAccountDropDownComponentProps): ReactElement {
  const navigate = useNavigate()
  const isBank = account.accountType === LinkedAccountType.BANK
  const header = <FinancialAccountRow account={account}/>
  const borderStyle = account.status === AccountLinkStatus.LOGIN_REQUIRED ? ERROR_BORDER : undefined

  function onCardClick (): void {
    navigate(`${ROUTES.CARDS}/${String(account.accountId)}`)
  }

  // For now, only enable DropDowns for bank accounts, not cards
  return isBank
    ? <DropDownComponent
        borderStyle={borderStyle}
        customHeader={header}
        dropDownContent={
          <TreasuryAccountDropdownContent
            account={account}
            refetch={refetch}
          />
        }
      />
    : <MockDropDownComponent
        borderStyle={borderStyle}
        customHeader={header}
        onClick={onCardClick}
      />
}
