import React, {
  type ChangeEvent,
  type ReactElement,
  type KeyboardEvent
} from 'react'
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react'
import { type Path, type FieldValues, type UseFormRegister } from 'react-hook-form'
import { type BaseFormInputProps } from '../types'
import FormLabelWrapper from '../FormLabelWrapper'
import {
  BorderRadius,
  Color,
  FormInputHeight
} from '../../theme/theme'

export type FormInputProps<HookFormValues extends FieldValues> = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void
  allowAutoComplete?: boolean
  registerLabel?: Path<HookFormValues>
  register?: UseFormRegister<HookFormValues>
  registerRequired?: string | { value: boolean, message: string }
} & BaseFormInputProps

export function FormInput<HookFormValues extends FieldValues> ({
  fieldName,
  label,
  labelSize = 'md',
  labelSubtext,
  onChange,
  onKeyPress,
  value,
  placeholder,
  isDisabled = false,
  buttonLinkText,
  buttonLinkPadding,
  onButtonClick,
  backgroundColor = Color.WHITE,
  fieldError,
  fieldInteriorLabel,
  isRequired = false,
  register,
  registerLabel,
  registerRequired,
  allowAutoComplete = true,
  height = FormInputHeight.DEFAULT
}: FormInputProps<HookFormValues>): ReactElement {
  const hasSideButton = buttonLinkText != null
  const hasInteriorLabel = fieldInteriorLabel != null
  const isInErrorState = fieldError != null

  const registeredForm = registerLabel != null && register != null &&
    register(registerLabel, { required: registerRequired })
  return (
    <FormLabelWrapper
      fieldName={fieldName}
      label={label}
      isRequired={isRequired}
      fontSize={labelSize}
      labelSubtext={labelSubtext}
      errorMessage={fieldError}
    >
      <InputGroup >
        {hasInteriorLabel &&
        <InputLeftElement pointerEvents='none' px='1px'>
          {fieldInteriorLabel}
        </InputLeftElement>
        }
        <Input
          type='text'
          variant='filled'
          _hover={{ backgroundColor }}
          _focus={{ backgroundColor: Color.WHITE, borderColor: Color.DARK_GREY }}
          name={fieldName}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          backgroundColor={backgroundColor}
          borderColor={isInErrorState ? Color.ERROR_RED : backgroundColor}
          borderRadius={BorderRadius.CARD}
          w='100%'
          h={height}
          borderStyle={isInErrorState ? 'solid' : 'transparent'}
          fontSize='md'
          px='24px'
          pr={buttonLinkPadding}
          isDisabled={isDisabled}
          autoComplete={allowAutoComplete ? 'on' : 'off'}
          color={Color.DARK_BLUE}
          sx={{
            '::placeholder': {
              color: Color.DARK_GREY
            }
          }}
          {...registeredForm}
        />
        {hasSideButton &&
        <InputRightElement height='100%' px='40px' pointerEvents='none'>
          <Button
            variant='unstyled'
            onClick={onButtonClick}
          >
            Save
          </Button>
        </InputRightElement>
      }
      </InputGroup>
    </FormLabelWrapper>
  )
}
