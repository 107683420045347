
import { gql } from '@apollo/client'
import { TRANSACTION_FRAGMENT } from '../fragments/TransactionFragment'

export const GET_TRANSACTIONS_PAGE_DATA = gql`
    query GetTransactionsPageData($organizationId: String!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                id
                financialAccounts{
                    accountId
                    institution {
                        name
                    }
                    accountType
                    accountSubTypeFormatted
                    name
                    lastFour
                    bankAccountType
                }
                franchiseGroups {
                  id
                  name
                }
            }
        }
    }
`

export const GET_TRANSACTIONS = gql`
    ${TRANSACTION_FRAGMENT}
    query GetTransactionsData($organizationId: String!, $sort: TransactionSortInput, $filter:TransactionFilterInput, $offset: Int, $limit: Int, $includePending: Boolean) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                transactions(sort: $sort, filter: $filter, offset: $offset, limit: $limit, includePending: $includePending) {
                    transactions {
                        ...TransactionFragment
                    }
                }
            }
        }
    }
`
export const GET_TRANSACTIONS_META = gql`
    query GetTransactionsMetaData($organizationId: String!, $sort: TransactionSortInput, $filter:TransactionFilterInput) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                transactions(sort: $sort, filter: $filter) {
                    meta {
                        totalIncomingValue
                        totalOutgoingValue
                        totalTransactions
                    }
                }
            }
        }
    }
`
