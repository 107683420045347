/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CloseIcon ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path stroke={color} fill={color} fillRule='evenodd' clipRule='evenodd' d='M5.80353 4.12453C5.42044 3.72672 4.79932 3.72672 4.41623 4.12453C4.03313 4.52235 4.03313 5.16734 4.41623 5.56515L10.6129 11.9999L4.41645 18.4344C4.03335 18.8322 4.03335 19.4772 4.41645 19.875C4.79954 20.2729 5.42066 20.2729 5.80376 19.875L12.0002 13.4405L18.195 19.8734C18.5781 20.2712 19.1992 20.2712 19.5823 19.8734C19.9654 19.4756 19.9654 18.8306 19.5823 18.4328L13.3875 11.9999L19.5826 5.56677C19.9656 5.16896 19.9656 4.52397 19.5826 4.12615C19.1995 3.72834 18.5783 3.72834 18.1952 4.12615L12.0002 10.5593L5.80353 4.12453Z'/>
      </svg>
    </Icon>
  )
}
