import React, { type ReactElement } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import MetricCardRow from './MetricCardRow'
import DownloadStatementButton from '../../../account_statement/DownloadStatementButton'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { GET_STATEMENT } from '@/graphql/queries/statements/GetStatement'
import {
  type GetAvailableStatements_availableStatements as Statement
} from '@/graphql/__generated__/GetAvailableStatements'
import {
  BorderRadius,
  Color
} from '@/theme/theme'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { LinkedAccountType } from '@/graphql/__generated__/globalTypes'
import {
  type GetStatement,
  type GetStatementVariables,
  type GetStatement_statement
} from '@/graphql/__generated__/GetStatement'

export interface AccountActivityComponentProps {
  accountType: LinkedAccountType
  accountId: number
  statementSelection: Statement | null
  isAmplifyAccount: boolean
}

function getBankAccountLineItems (statement?: GetStatement_statement): ReactElement {
  return (
    <>
      <MetricCardRow
        label={`Starting Balance (${statement?.startDate ?? ''})`}
        value={statement?.startingBalance?.amountFormatted ?? ''}
      />
      <Divider my={2} />
      <MetricCardRow
        label='Total Withdrawals:'
        value={statement?.totalWithdrawals?.amountFormatted ?? ''}
        valueColor={Color.ERROR_RED}
      />
      <Divider my={2} />
      <MetricCardRow
        label='Total Deposits:'
        value={statement?.totalDeposits?.amountFormatted ?? ''}
        valueColor={Color.SUCCESS_GREEN}
      />
      <Divider my={2} />
      <MetricCardRow
        label='Ending Balance'
        value={statement?.endingBalance?.amountFormatted ?? ''}
        isHeaderRow
      />
    </>
  )
}
function getCreditCardLineItems (statement?: GetStatement_statement): ReactElement {
  return (
    <>
      <MetricCardRow
        label='Statement Balance'
        value={statement?.endingBalance?.amountFormatted ?? ''}
        isHeaderRow
      />
    </>
  )
}

export default function AccountActivityComponent (
  {
    accountType,
    accountId,
    statementSelection,
    isAmplifyAccount
  }: AccountActivityComponentProps
): ReactElement<string, string> {
  const organizationId = useAltirStore((state) => state.selectedOrganizationState.selectedOrganizationId)

  const {
    data,
    loading,
    error
  } = useQuery<GetStatement, GetStatementVariables>(
    GET_STATEMENT,
    {
      variables: {
        organizationId,
        accountId,
        startDate: statementSelection?.startDate,
        endDate: statementSelection?.endDate
      }
    })

  const statement = data?.statement
  return (
    <Flex flexDirection='column'>
      <Text>Account Activity Overview</Text>
      {/* TODO (PAL): Add Month description */}
      <AltirSkeleton isLoading={loading} error={error} marginY={3}>
        <Flex
          width='100%'
          flexDirection='column'
          py={5}
          px={5}
          borderRadius={BorderRadius.CARD}
          bg={Color.WHITE}
          gap={3}
        >
          <Flex flexDirection='column' width='100%'>
            {accountType === LinkedAccountType.BANK
              ? getBankAccountLineItems(statement)
              : getCreditCardLineItems(statement)}
          </Flex>
          {statementSelection?.startDate != null && statementSelection?.endDate != null &&
          <DownloadStatementButton
            accountId={accountId}
            isAmplifyAccount={isAmplifyAccount}
            startDate={statementSelection?.startDate}
            endDate={statementSelection?.endDate}
          />}
        </Flex>
      </AltirSkeleton>
    </Flex>
  )
}
