import React, { type ReactElement, type ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import CloseIcon from '@/library/icons/CloseIcon'
import { Color } from '@/theme/theme'
/**
 * General component for a modal or 'popup'.  Can be customized with child components for the specific use-case.
 * Usage:
 *
 * '''
 *  const { isOpen: isModalOpen, toggle } = useModal()

 *  <Modal isOpen={isModalOpen} toggle={toggle}>
      <div> Inner component </div>
    </Modal>
 * '''

*/

interface ModalType {
  children?: ReactNode
  isOpen: boolean
  toggle: () => void
}

// DEPRECATED: use src/components/modal/ModalComponent.tsx which relis on Chakra's native modal functionality
export function StatementSelectorModalContainer (props: ModalType): ReactElement | null {
  if (!props.isOpen) { return null }

  return (
    <>
      <Box
        w='100vw'
        h='100vh'
        pos='absolute'
        top='0'
        left='0'
        zIndex={9999}
        bg='#060F2580'
        display='flex'
        justifyContent='center'
        alignItems='center'
        onClick={props.toggle}
      >
        <Box
          p={8}
          display='block'
          bg='#F6F6F6'
          w='30%'
          h='40%'
          borderRadius='15'
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Flex
            float='right'
            onClick={() => { props.toggle() }}
            _hover={{ cursor: 'pointer' }}
          >
            <CloseIcon color={Color.DARK_GREY}/>
          </Flex>
          {props.children}
        </Box>
      </Box>
    </>
  )
}
