import React, { type ReactElement } from 'react'
import { GridItem, Text } from '@chakra-ui/react'
import { TransferType } from '@/graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { ALTIR_WIRE_PROCESSING_FEE } from '@/utils/transferUtils'

export default function TransferFeeLineItem ({ transferType }: { transferType: TransferType }): ReactElement {
  return (
    transferType === TransferType.WIRE
      ? <GridItem justifySelf='end'>
        <Text>${ALTIR_WIRE_PROCESSING_FEE}</Text>
      </GridItem>
      : <GridItem justifySelf='end'>
        <Text color={Color.SUCCESS_GREEN}>Free</Text>
      </GridItem>
  )
}
