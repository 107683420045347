import { gql } from '@apollo/client'

export const GET_CHECK_ENABLEMENT_STATUS = gql`
    query GetCheckEnablementStatus($organizationId: String!) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                checkEnablementStatus
            }
            rolloutConfiguration {
                enableCheckIssuance
            }
        }
    }
`
