import { gql } from '@apollo/client'

export const FINANCIAL_ACCOUNT_FRAGMENT = gql`
    fragment FinancialAccountFragment on FinancialAccount {
        accountId
        counterpartyId
        franchiseGroupId
        name
        accountType
        accountSubTypeFormatted
        lastFour
        achAccountNumber
        achRoutingNumber
        institution {
            name
            logoAssetUrl
        }
        transactionsUpdatedAt
        status
        connectionProvider
        plaidItemId
        plaidAccessToken
        counterparty {
            nickname
            nameOnAccount
            achAccountNumber
            achRoutingNumber
            isOwnershipVerified
            counterpartyType
        }
    }
`
