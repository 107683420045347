import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color } from '@/theme/theme'

interface EmptyStateCardProps {
  title: string
  subTitle: string
  children?: ReactElement
}

export default function EmptyStateCard ({ title, subTitle, children }: EmptyStateCardProps): ReactElement {
  return (
    <Flex
      borderRadius={BorderRadius.CARD}
      backgroundColor={Color.GREY}
      direction='column'
      alignItems='center'
      padding={10}
      gap={4}
    >
      <Flex gap={4} flexDir='column' alignItems='center'>
        <Flex flexDir='column' alignItems='center' textAlign='center'>
          <Heading size='sm'>{title}</Heading>
          <Text>{subTitle}</Text>
        </Flex>
        <Flex px={5}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}
