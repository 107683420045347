import { gql } from '@apollo/client'

export const GET_AGGREGATE_EOD_BALANCES_FOR_ORGANIZATION = gql`
    query GetAggregateEODBalancesForOrganization(
        $organizationId: String!, 
        $dateRange: DateRangeInput, 
        $ascending: Boolean,
        $financialAccountFilter: FinancialAccountFilterInput
    ) {
        currentUser {
            selectedOrganization(organizationId: $organizationId) {
                aggregateEODBalances(
                    dateRange: $dateRange, 
                    ascending: $ascending,
                    financialAccountFilter: $financialAccountFilter
                ) {
                    balance{
                        amount
                    }
                    date
                }
                aggregateAccountBalance(financialAccountFilter: $financialAccountFilter) {
                    availableBalance {
                        amount
                    }
                }
            }
        }
    }
`
