import { TransferDirection } from '@/graphql/__generated__/globalTypes'
import {
  type TransactionFragment_originatingTransfer as OriginatingTransfer
} from '@/graphql/__generated__/TransactionFragment'
import { type Transaction } from '@/library/transactions/TransactionRowComponent'
import { getBankAccountShortName, getCounterpartyShortName } from '@/utils/financialAccountUtils'

interface BaseFinancialAccount {
  accountId: number | null
  institution: {
    name: string | null
  } | null
  lastFour: string | null
}

export function generateSubtitle (startValue?: string, endValue?: string): string {
  return startValue != null && endValue != null
    ? [startValue, endValue].join(' \u2013 ')
    : startValue ?? endValue ?? ''
}

export type BankAccountIdMap = Map<number | undefined, { institutionName?: string, lastFour?: string }>

export function createBankAccountIdMap<T extends BaseFinancialAccount > (financialAccounts: T[]): BankAccountIdMap {
  return new Map(financialAccounts.map(financialAccount => {
    return [financialAccount.accountId ?? undefined,
      {
        institutionName: getFormattedInstitutionName(financialAccount.institution?.name),
        lastFour: financialAccount.lastFour ?? undefined
      }]
  }))
}

/**
 * When transaction has an originating transfer, populate both based on amplify + counterparty
 *
 * When generic transaction, populate EITHER the "from" or "to" based on the transaction's direction
 */
export function getFromAndToAccounts (
  transaction: Transaction,
  bankAccount?: { institutionName?: string, lastFour?: string }
): { fromAccount?: string, toAccount?: string } {
  if (transaction.originatingTransfer != null) {
    return getFromAndToAccountsForOriginatingTransfer(transaction.originatingTransfer)
  }

  const mask = getBankAccountShortName(bankAccount?.lastFour ?? null, bankAccount?.institutionName ?? null)
  const isIncoming = (transaction?.amount ?? 0) > 0
  return { fromAccount: isIncoming ? undefined : mask, toAccount: isIncoming ? mask : undefined }
}

function getFromAndToAccountsForOriginatingTransfer (
  transfer: OriginatingTransfer
): { fromAccount?: string, toAccount?: string } {
  const counterpartFormatted = getCounterpartyShortName(
    transfer.counterparty != null ? { ...transfer.counterparty, liveBalance: null } : undefined
  )
  const amplifyFormatted = getBankAccountShortName(
    transfer.amplifyAccount?.lastFour ?? null, transfer.amplifyAccount?.institution?.name ?? null
  )
  const isCredit = transfer.direction === TransferDirection.CREDIT
  return {
    fromAccount: isCredit ? amplifyFormatted : counterpartFormatted,
    toAccount: isCredit ? counterpartFormatted : amplifyFormatted
  }
}

/**
 * For clarity, we re-map First Bank -> Altir.
 * TODO (PJ): Align on how we want to handle this case in the long term
 */
function getFormattedInstitutionName (institutionName?: string | null): string | undefined {
  if (institutionName === 'First Bank') return 'Altir'

  return institutionName ?? undefined
}
