import React, { useEffect, useState, type ReactElement } from 'react'
import { nanoid } from 'nanoid'
import { Flex, Heading, Modal, ModalBody, ModalContent, ModalOverlay, Text, Tooltip } from '@chakra-ui/react'
import { filterCounterparties, COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW, getCounterpartyTypeOptions } from './utils'
import { SelectableAccountRow } from './account_row/SelectableAccountRow'
import EmptyAccountsSectionComponent from '../../../treasury/components/treasury/EmptyAccountsSectionComponent'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { type Account } from '@/types/types'
import { AccountLinkStatus, TransferDirection } from '@/graphql/__generated__/globalTypes'
import SearchBar from '@/library/form/text/SearchBar'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export enum CounterpartyTypeSearchCriteria {
  ALL = 'All',
  VENDOR = 'Vendor',
  ACCOUNT = 'Bank Account'
}

export interface AccountSelectorModalProps {
  isOpen: boolean
  onClose: () => void
  onSelectCounterParty: (counterparty: Account) => void
  counterparties?: Account[]
  selectedCounterparty?: Account
  transferDirection?: TransferDirection
  refetch: () => void
}

export function AccountSelectorModal (
  {
    isOpen,
    onClose,
    counterparties = [],
    onSelectCounterParty,
    transferDirection,
    refetch
  }: AccountSelectorModalProps
): ReactElement {
  const selectedFranchiseGroupId = useAltirStore(state => state.selectedFranchiseGroupId)
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>()
  const [searchQuery, setSearchQuery] = useState('')
  const [counterpartyTypeFilter, setCounterpartyTypeFilter] = useState(CounterpartyTypeSearchCriteria.ALL)

  useEffect(() => {
    setCounterpartyTypeFilter(transferDirection === TransferDirection.CREDIT
      ? CounterpartyTypeSearchCriteria.ALL
      : CounterpartyTypeSearchCriteria.ACCOUNT)
  }, [transferDirection])

  function handleCounterpartySelection (counterparty: Account): void {
    const isAccountLoginRequired = counterparty.status === AccountLinkStatus.LOGIN_REQUIRED
    if (isAccountLoginRequired) return

    setSelectedCounterparty(counterparty)
    setSearchQuery('')
    onSelectCounterParty(counterparty)
  }

  function getAccountSelectorContent (): ReactElement {
    const hasCounterparty = counterparties.length > 0
    if (!hasCounterparty) {
      return (
        <EmptyAccountsSectionComponent
          franchiseGroupId={selectedFranchiseGroupId}
          ctaText='Connect your accounts in order to make a transfer.'
          refetch={refetch}
        />
      )
    }

    const filteredCounterparties = filterCounterparties(
      counterparties,
      counterpartyTypeFilter,
      searchQuery,
      transferDirection
    )

    return (
      <Flex flexDirection='column' width='100%'>
        {filteredCounterparties?.map(counterparty => {
          const isSelected = counterparty.counterpartyId === selectedCounterparty?.counterpartyId
          const isAccountLoginRequired = counterparty.status === AccountLinkStatus.LOGIN_REQUIRED
          return (
            <Tooltip
              key={nanoid()}
              label={
                (counterparty.counterparty?.isOwnershipVerified === true)
                  ? undefined
                  : COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW
              }
              bg={Color.DARK_BLUE}
              hasArrow={true}
            >
              <Flex
                width='100%'
                my={1}
                onClick={() => { handleCounterpartySelection(counterparty) }}
              >
                <SelectableAccountRow
                  account={counterparty}
                  isClickable={!isAccountLoginRequired}
                  isSelected={isSelected}
                  isDisabled={isAccountLoginRequired}
                />
              </Flex>
            </Tooltip>
          )
        })}
      </Flex>
    )
  }

  const transferDirectionHeaderText = transferDirection === TransferDirection.CREDIT ? 'To' : 'From'
  const transferDirectionBodyText = transferDirectionHeaderText.toLowerCase()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => { setSearchQuery(''); onClose() }}
      size='3xl'
      preserveScrollBarGap
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='100%'
        backgroundColor={Color.LIGHT_GREY}
        borderRadius={BorderRadius.CARD}
      >
        <Flex
          width='100%'
          borderRadius={BorderRadius.CARD}
          alignItems='center'
          justifyContent='center'
        >
          <ModalBody
            display='flex'
            flexDirection='column'
            borderRadius={BorderRadius.CARD}
            alignItems='center'
            justifyContent='center'
            my={4}
          >
            <Flex flexDir='column' w='100%' px={2}>
              <Flex flexDirection='column' justifyContent='center' alignItems='center' my={6} gap={2}>
                <Heading
                  color={Color.DARK_BLUE}
                  fontWeight={FontWeight.MEDIUM}
                  size='lg'
                >Transfer {transferDirectionHeaderText}</Heading>
                <Text>
                  Select which account to transfer {transferDirectionBodyText}
                </Text>
              </Flex>
              <Flex flexDir='column' w='100%' gap={4}>
                <Flex w='100%' gap={4}>
                  <SearchBar queryValue={searchQuery} onChange={setSearchQuery}/>
                  <SelectorComponent
                    value={counterpartyTypeFilter}
                    options={getCounterpartyTypeOptions(transferDirection)}
                    alignText='start'
                    handleSelection={(value) => { setCounterpartyTypeFilter(value) }}
                  />
                </Flex>
                {getAccountSelectorContent()}
              </Flex>
            </Flex>
          </ModalBody>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
