import React, { type ReactElement } from 'react'
import '../../../assets/css/App.css'
import { useQuery } from '@apollo/client'
import TransactionsComponent from './components/TransactionsComponent'
import { GET_TRANSACTIONS_PAGE_DATA } from '@/graphql/queries/GetTransactionsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import {
  type GetTransactionsPageDataVariables,
  type GetTransactionsPageData
} from '@/graphql/__generated__/GetTransactionsPageData'
import Page from '@/library/page/Page'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export default function TransactionsPage (): ReactElement {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data: transactionsPageData,
    loading: isPageDataLoading,
    error: pageDataError
  } = useQuery<GetTransactionsPageData, GetTransactionsPageDataVariables>(GET_TRANSACTIONS_PAGE_DATA, {
    variables: {
      organizationId
    }
  })

  return (
    <Page>
      <AltirSkeleton
        isLoading={isPageDataLoading || organizationId == null}
        error={pageDataError}
      >
        {organizationId != null &&
        <TransactionsComponent
          transactionsPageData={transactionsPageData}
          isPageDataLoading={isPageDataLoading}
          organizationId={organizationId}
        />}
      </AltirSkeleton>
    </Page>
  )
}
