import React, { type ReactElement, type ReactNode } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/react'
import FormInlineError from './error/FormInlineError'
import { Color, FontWeight } from '@/theme/theme'

interface FormLabelWrapperProps {
  children: ReactNode
  fieldName: string
  errorMessage?: string | null
  label?: string
  labelSubtext?: string
  isRequired?: boolean
  fontSize?: string
}

export default function FormLabelWrapper ({
  children,
  errorMessage,
  label,
  labelSubtext,
  fieldName,
  isRequired = false,
  fontSize = 'md'
}: FormLabelWrapperProps): ReactElement {
  const isInErrorState = errorMessage != null
  return (
    <FormControl id={fieldName} >
      {label != null &&
      <FormLabel
        color={isInErrorState ? Color.ERROR_RED : Color.DARK_GREY}
        fontWeight={FontWeight.MEDIUM}
        fontSize={fontSize}
      >
        {label}{isRequired ? '*' : ''}
      </FormLabel>}
      {
      labelSubtext != null &&
        <FormLabel
          color={isInErrorState ? Color.ERROR_RED : Color.DARK_GREY}
          fontSize='xs'
        >
          {labelSubtext}
        </FormLabel>
      }
      <FormInlineError errorMessage={errorMessage}/>
      {children}
    </FormControl>
  )
}
