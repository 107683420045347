
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { type ReactElement, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Flex, useToast } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import Button, { ButtonVariant } from '@/library/button/Button'
import DownloadIcon from '@/library/icons/DownloadIcon'
import { GET_EXPORT_JOB_STATUS } from '@/graphql/queries/GetExportJobStatus'
import { CREATE_EXPORT_JOB } from '@/graphql/mutations/CreateExportJob'
import { type CreateExportJob, type CreateExportJobVariables } from '@/graphql/__generated__/CreateExportJob'
import { getErrorToast } from '@/utils/toastUtils'
import { TransactionSortType } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { type ExportJobStatusVariables } from '@/graphql/__generated__/ExportJobStatus'

export interface FileCenterComponentProps {
  startDate?: string
  endDate?: string
  accountId: string
}
export interface ExportJob {
  export: ExportJobData
}
export interface ExportJobStatus {
  exportJob: ExportJobData
}
export interface ExportJobData {
  id: string
  status: string
  fileUrl: string
}

/** @deprecated Use useFileExport instead */
export function FileCenterComponent (
  { startDate, endDate, accountId }: FileCenterComponentProps
): ReactElement {
  const toast = useToast()
  const selectedOrganizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const [_file, setFile] = useState<string | null>()
  const [jobId, setJobId] = useState<string>('No Job ID')
  const [loading, setLoading] = useState(false)
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const { refetch } = useQuery<ExportJobStatus, ExportJobStatusVariables>(GET_EXPORT_JOB_STATUS, {
    skip: jobId == null,
    variables: {
      jobId
    },
    onCompleted: (_data) => {
      setFile(_data.exportJob.fileUrl)
    }
  })

  const [_exportJob] = useMutation<CreateExportJob, CreateExportJobVariables>(CREATE_EXPORT_JOB, {
    variables: {
      transactionExportJobInput: {
        organizationId: selectedOrganizationId,
        sort: { type: TransactionSortType.Date, ascending: false },
        filter: {
          dateRange: {
            startDate: start as unknown as string,
            endDate: end as unknown as string
          },
          bankAccountIds: [Number(accountId)]
        }
      }
    },
    onCompleted: (data) => {
      setLoading(true)
      setJobId(data.export.id)
    },
    onError: () => {
      toast(getErrorToast('Unable to initiate download. Please try again or contact support.'))
    }
  })

  useEffect(() => {
    if (startDate != null && endDate != null) {
      if (startDate !== null && endDate !== null) {
        const _startDate = new Date(startDate)
        const _endDate = new Date(endDate)

        setStart(_startDate)
        setEnd(_endDate)
      }
    }
  }, [startDate, endDate])

  useEffect(() => {
    async function checkExportStatusAndMaybeDownload (): Promise<void> {
      // Early return if job wasn't successully created
      if (jobId == null) {
        return
      }

      const { data } = await refetch({ jobId })

      if (data !== undefined && data.exportJob.status === 'SUCCESS') {
        const file = data.exportJob.fileUrl
        const link = document.createElement('a')

        setFile(file)

        link.download = file
        link.href = file

        link.click()
        setLoading(false)
      }
    }
    if (loading) {
      const id = setInterval(() => {
        void checkExportStatusAndMaybeDownload()
      }, 1000)
      return () => { clearInterval(id) }
    }
  }, [loading, jobId, refetch])

  return (
    <Flex>
      <Button text='Download CSV'
        variant={ButtonVariant.GHOST}
        onClick={async () => { await _exportJob() }}
        beforeIcon={<DownloadIcon/>}
        isLoading={loading}
        onClickEventType={Event.FILE_CENTER_DOWNLOAD_CLICK}
      />
    </Flex>
  )
}
