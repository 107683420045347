import { gql } from '@apollo/client'

export const GET_RECENT_RECIPIENTS_PAGE = gql`
    query GetRecentRecipientsPage(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
        $transferLimit: Int 
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    id
                    counterpartyType
                    isOwnershipVerified
                    createdAt
                    name
                    achAccountNumber
                    transfers(limit: $transferLimit) {
                      amount {
                        amountFormatted
                      }
                      createdAt
                      status
                    }
                }
            }
            rolloutConfiguration {
                enableWires
            }
        }
    }
`

export const GET_RECENT_RECIPIENTS = gql`
    query GetRecentRecipients(
        $counterpartyTypes: [CounterpartyType!], 
        $franchiseGroupId: Int!,
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes) {
                    id
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
            rolloutConfiguration {
                enableWires
            }
        }
    }
`

export const GET_COUNTERPARTIES_PENDING_VERIFICATION = gql`
    query GetCounterpartiesPendingVerification(
        $counterpartyTypes: [CounterpartyType!], 
        $isOwnershipVerified: Boolean,
        $franchiseGroupId: Int!
    ) {
        currentUser {
            franchiseGroup(id: $franchiseGroupId) {
                id
                amplifyAccount {
                    amplifyAccount {
                        id
                    }
                }
                counterparties(counterpartyTypes: $counterpartyTypes, isOwnershipVerified: $isOwnershipVerified) {
                    id
                    isOwnershipVerified
                    nameOnAccount
                    name
                }
            }
        }
    }
`
