import React, { type ReactElement } from 'react'
import {
  Text, Grid, GridItem
} from '@chakra-ui/react'
import TransactionRowDropInfoDropdown from './TransactionRowDropInfoDropdown'

import Badge, { BadgeVariant } from '../badge/Badge'
import AnimatedDropDownRow, {
  AnimatedDropDownRowExpandedContent,
  AnimatedDropDownRowRowContent
} from '../layout/AnimatedDropDownRow'
import {
  Color
} from '@/theme/theme'
import { getCategoryLabelFormatted, getCurrencyFormatted } from '@/utils/stringUtils'
import { getFormattedDateString } from '@/utils/dateUtils'
import { getFromAndToAccounts, type BankAccountIdMap } from '@/components/pages/transactions/components/utils'
import { type DerivedTransactionCategory } from '@/graphql/__generated__/globalTypes'
import {
  type TransactionFragment_originatingTransfer as OriginatingTransfer
} from '@/graphql/__generated__/TransactionFragment'

export interface Transaction {
  // Current Form
  id: number | null
  description: string | null
  originalDescription: string | null
  amount: number | null
  merchantFormatted: string | null
  bankAccountId: number | null
  isPendingTransaction: boolean
  categoryLabel: DerivedTransactionCategory
  date: string | null
  originatingTransfer: OriginatingTransfer | null

  // Backwards Compatibility
  rawDescription?: string | null
  amountFormatted?: string | null
  dateFormatted?: string | null
  categoryLabelFormatted?: string | null
  rawBankTransactionId?: number | null
  accountType?: string | null
  EOMonthFormatted?: string | null
  checkId?: string | null
  achRoutingNumber?: string | null
  achWireRoutingNumber?: string | null
  achAccountNumber?: string | null
  lastFour?: string | null
  name?: string | null
}

interface TransactionRowProps {
  index: number
  isSelected: boolean
  transaction: Transaction
  setSelectedTransactionIndex: (index: number | null) => void
  bankAccountIdMap?: BankAccountIdMap
}

export default function TransactionRow (
  {
    index,
    transaction,
    isSelected,
    setSelectedTransactionIndex,
    bankAccountIdMap
  }: TransactionRowProps
): ReactElement {
  const amountFormatted = transaction?.amount != null
    ? getCurrencyFormatted(transaction?.amount, { minimumFractionDigits: 2 })
    : undefined
  const categoryLabelFormatted = getCategoryLabelFormatted(transaction.categoryLabel)
  const dateFormatted = getFormattedDateString(transaction.date)
  const longDescription = transaction.originalDescription ?? '' // TODO Ensure this field is non-null
  const merchantFormatted = transaction.merchantFormatted
  const isPending = transaction.isPendingTransaction
  const baseFontColor = isPending ? Color.DARK_GREY : Color.DARK_BLUE

  const bankAccount = transaction.bankAccountId != null
    ? bankAccountIdMap?.get(transaction.bankAccountId)
    : { institutionName: undefined, lastFour: undefined }

  const institutionName = bankAccount?.institutionName

  const { fromAccount, toAccount } = getFromAndToAccounts(transaction, bankAccount)
  const headerRowTemplateColumns = isPending
    ? '7rem 9fr 6rem 2fr'
    : '7rem 9fr 2fr'

  return (
    <AnimatedDropDownRow
      isSelected={isSelected}
      onClick={() => { setSelectedTransactionIndex(isSelected ? null : index) }}
      isRowClickable={true}
    >
      <AnimatedDropDownRowRowContent>
        <Grid
          w='100%'
          gridAutoFlow='column'
          templateColumns={headerRowTemplateColumns}
          alignContent='center'
          gap={6}
        >
          {/* Date */}
          <GridItem alignSelf='center'>
            <Text color={Color.DARK_GREY}>
              {dateFormatted}
            </Text>
          </GridItem>

          {/* Description */}
          <GridItem alignSelf='center' overflow='hidden'>
            <Text
              color={baseFontColor}
              noOfLines={1}
            >
              {merchantFormatted ?? longDescription}
            </Text>
          </GridItem>

          {/* Pending Badge */}
          {
            isPending &&
              <GridItem alignSelf='center' justifySelf='right'>
                <Badge badgeVariant={BadgeVariant.GREY}>Pending</Badge>
              </GridItem>
          }

          {/* Amount */}
          <GridItem alignSelf='center'>
            <Text color={baseFontColor} textAlign='right' >
              {amountFormatted}
            </Text>
          </GridItem>
        </Grid>
      </AnimatedDropDownRowRowContent>
      <AnimatedDropDownRowExpandedContent>
        <TransactionRowDropInfoDropdown
          isSelected={isSelected}
          categoryLabelFormatted={categoryLabelFormatted}
          longDescription={longDescription}
          fromAccount={fromAccount}
          toAccount={toAccount}
          dateFormatted={dateFormatted ?? undefined}
          institutionName={institutionName}
        />
      </AnimatedDropDownRowExpandedContent>
    </AnimatedDropDownRow>
  )
}
