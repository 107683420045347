import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { BorderRadius, Color, FontWeight } from '@/theme/theme'
import { MAGNIFYING_GLASS_GRAPHIC } from '@/theme/graphics'
import { Graphic } from '@/library/utility/Graphic'

export default function NoTransactionsFoundComponent (): ReactElement {
  const logoHeight = '106px'
  const verticalPadding = 48
  return (
    <Flex
      flexDirection='column'
      width='100%'
      bg={Color.GREY}
      borderRadius={BorderRadius.CARD}
      justifyContent='center'
      alignItems='center'
      py={verticalPadding}
      gap={4}
    >
      <Graphic src={MAGNIFYING_GLASS_GRAPHIC} h={logoHeight}/>
      <Flex flexDir='column' align='center'>
        <Heading
          size='lg'
          color={Color.DARK_BLUE}
          fontWeight={FontWeight.MEDIUM}
        > No transactions here.</Heading>
        <Text>Try a different filter</Text>
      </Flex>

    </Flex>
  )
}
