import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import AddNewRecipientForm, { type AddRecipientFormData } from './AddNewRecipientForm'
import { BorderRadius, Color } from '@/theme/theme'
import ModalCloseHeader from '@/library/modal/ModalCloseHeader'
import SuccessConfettiAnimation from '@/library/animations/SuccessConfettiAnimation'
import Button from '@/library/button/Button'
import {
  type CreateExternalCounterparty,
  type CreateExternalCounterpartyVariables
} from '@/graphql/__generated__/CreateExternalCounterparty'
import { CREATE_EXTERNAL_COUNTERPARTY } from '@/graphql/mutations/CreateExternalCounterparty'
import { type WireParams } from '@/graphql/__generated__/globalTypes'

interface AddNewRecipientModalProps {
  amplifyAccountId: string | undefined
  franchiseGroupId: number
  isOpen: boolean
  isWiresEnabled: boolean
  onSuccess: () => void
  onClose: () => void
}

export default function AddNewRecipientModal ({
  isOpen,
  onSuccess,
  onClose,
  amplifyAccountId,
  franchiseGroupId,
  isWiresEnabled
}: AddNewRecipientModalProps): ReactElement {
  const [
    createCounterparty,
    { loading }
  ] = useMutation<CreateExternalCounterparty, CreateExternalCounterpartyVariables>(CREATE_EXTERNAL_COUNTERPARTY)
  const [formSubmissionError, setFormSubmissionError] = useState<Error | undefined>()
  const [isSubmitted, setIsSubmitted] = useState(false)

  function onSubmit (formData: AddRecipientFormData): void {
    if (
      formData.accountNumber == null ||
      formData.routingNumber == null ||
      formData.accountNickname == null ||
      formData.accountType == null ||
      formData.counterpartyType == null ||
      formData.accountHolderName == null ||
      formData.fileUploadId == null ||
      amplifyAccountId == null
    ) {
      setFormSubmissionError(Error('Please check your form fields and try again.'))
      return
    }

    void createCounterparty({
      variables: {
        accountNickname: formData.accountNickname,
        accountHolderFullName: formData.accountHolderName,
        accountNumber: formData.accountNumber,
        routingNumber: formData.routingNumber,
        counterpartyType: formData.counterpartyType,
        documentLink: formData.fileUploadId,
        achSpecificFields: {
          accountType: formData.accountType,
          accountNumber: formData.accountNumber,
          routingNumber: formData.routingNumber
        },
        wireSpecificFields: parseWireInput(formData),
        amplifyAccountId
      }
    })
      .then(() => {
        setIsSubmitted(true)
        setTimeout(() => {
          onPostSubmitModalClose()
        }, 3000)
      }).catch(() => {
        setFormSubmissionError(Error())
      })
  }

  function onPostSubmitModalClose (): void {
    onClose()
    setIsSubmitted(false)
    onSuccess()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={false}
      preserveScrollBarGap
      size='xl'
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        flexDirection='column'
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        alignItems='center'
        justifyContent='center'
        width='100%'
        px={4}
        pt={4}
        pb={8}
      >
        <ModalCloseHeader onClose={onClose}/>
        <Flex flexDir='column' gap={8} alignItems='center' w='100%' px={6}>
          <Heading
            color={Color.DARK_BLUE}
            size='lg'
          >{isSubmitted ? 'Recipient Added!' : 'Add a New Recipient'}</Heading>
          {!isSubmitted && <AddNewRecipientForm
            isSubmissionLoading={loading}
            submissionError={formSubmissionError}
            onSubmit={onSubmit}
            franchiseGroupId={franchiseGroupId}
            onCancel={onClose}
            isWiresEnabled={isWiresEnabled}
                           />}
          {isSubmitted && <Flex pt={24} pb={36}>
            <SuccessConfettiAnimation/>
          </Flex>}
          {isSubmitted && <Button text='Done' onClick={onPostSubmitModalClose}/>}
        </Flex>
      </ModalContent>
    </Modal>
  )
}

// For now, we only pass along the wire params if all the fields are complete
// TODO - add support for writing partial state in the backend
function parseWireInput (formData: AddRecipientFormData): WireParams | undefined {
  if (formData.wireAccountNumber == null || formData.wireRoutingNumber == null ||
    formData.address == null || formData.bankAddress == null || formData.bankName == null
  ) {
    return undefined
  }

  if (formData.address.city == null || formData.address.country == null || formData.address.postalCode == null ||
    formData.address.state == null || formData.address.streetAddressLine1 == null) {
    return undefined
  }

  if (formData.bankAddress.city == null || formData.bankAddress.country == null ||
    formData.bankAddress.postalCode == null || formData.bankAddress.state == null ||
    formData.bankAddress.streetAddressLine1 == null) {
    return undefined
  }

  return {
    accountNumber: formData.wireAccountNumber,
    routingNumber: formData.wireRoutingNumber,
    address: {
      city: formData.address?.city,
      country: formData.address?.country,
      postal_code: formData.address?.postalCode,
      state: formData.address?.state,
      street_line_1: formData.address?.streetAddressLine1,
      street_line_2: formData.address?.streetAddressLine2

    },
    bankAddress: {
      city: formData.bankAddress?.city,
      country: formData.bankAddress?.country,
      postal_code: formData.address?.postalCode,
      state: formData.address?.state,
      street_line_1: formData.address?.streetAddressLine1,
      street_line_2: formData.address?.streetAddressLine2
    },
    bankName: formData.bankName
  }
}
