import React, { useState, type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import TransactionRow, { type Transaction } from './TransactionRowComponent'
import { type BankAccountIdMap } from '@/components/pages/transactions/components/utils'

interface TransactionsRowListComponentProps {
  transactionsData?: Transaction[]
  bankAccountIdMap?: BankAccountIdMap
}

export default function TransactionsRowListComponent (
  { transactionsData, bankAccountIdMap }: TransactionsRowListComponentProps
): ReactElement {
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState<number | null>(null)
  return (
    <Flex gap={2} flexDir='column'>
      {transactionsData?.map((transaction, index) => {
        return (
          <TransactionRow
            key={index}
            index={index}
            transaction={transaction}
            isSelected={selectedTransactionIndex === index}
            setSelectedTransactionIndex={setSelectedTransactionIndex}
            bankAccountIdMap={bankAccountIdMap}
          />
        )
      })}
    </Flex>
  )
}
