import React, { useEffect, useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { type ApolloError } from '@apollo/client'
import TransferRuleConfirmationModal from './TransferRuleConfirmationModal'
import AccountSelectorComponent from '../../transfer/components/account_selector/AccountSelectorComponent'
import { Color, IconSize } from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import { TransferDirection, TransferRuleType }
  from '@/graphql/__generated__/globalTypes'
import { type Account } from '@/types/types'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import SelectorComponent from '@/library/form/select/SelectorComponent'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import { getErrorMetadata, isTransferRuleCircularDependencyError } from '@/utils/errorUtils'
import { getCurrencyAmountFormatted } from '@/utils/transferUtils'
import RecurringXIcon from '@/library/icons/RecurringXIcon'

export interface CreateBalanceRuleComponentProps {
  transferDirection: TransferDirection
  amplifyAccount?: Account
  counterparties: Account[]
  preSelectedCounterpartyId?: string
  updateRuleType: () => void
}

export default function CreateBalanceRuleComponent (
  {
    transferDirection,
    amplifyAccount,
    counterparties,
    preSelectedCounterpartyId,
    updateRuleType
  }: CreateBalanceRuleComponentProps
): ReactElement {
  const [amount, setAmount] = useState<string>()
  const preSelectedCounterparty = counterparties.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>(preSelectedCounterparty)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [transferRuleType, setTransferRuleType] = useState(TransferRuleType.TARGET_BALANCE)
  const [safeguardValue, setSafeguardValue] = useState(0)
  const [amountError, setAmountError] = useState<string | undefined>()

  useEffect(() => {
    clearErrors()
  }, [selectedCounterparty, transferRuleType, safeguardValue])

  const accountToFund = isCounterpartyTopOption(transferDirection, transferRuleType)
    ? selectedCounterparty?.name ?? '(account to fund)'
    : 'Amplify'
  const sourceAccount = !isCounterpartyTopOption(transferDirection, transferRuleType)
    ? selectedCounterparty?.name ?? '(source account)'
    : 'Amplify'

  function onRuleTypeChange (ruleType: TransferRuleType): void {
    setTransferRuleType(ruleType)
    updateRuleType()
  }

  return <Flex direction='column' gap={5}>
    <AccountSelectorComponent
      amplifyAccount={
        isCounterpartyTopOption(transferDirection, transferRuleType)
          ? undefined
          : amplifyAccount}
      counterparties={
        isCounterpartyTopOption(transferDirection, transferRuleType)
          ? counterparties
          : undefined}
      transferDirection={TransferDirection.DEBIT /* Patch to filter to personal accounts only */}
      customHeaderText='When the balance of'
      headerColor={Color.DARK_BLUE}
      onSelectedCounterpartyChange={setSelectedCounterparty}
      selectedCounterparty={selectedCounterparty ?? undefined}
    />
    <Flex width='100%' align='flex-end' gap={5}>
      <SelectorComponent
        alignText='start'
        options={[TransferRuleType.TARGET_BALANCE, TransferRuleType.MIN_TARGET_BALANCE]}
        value={transferRuleType}
        handleSelection={ruleType => {
          onRuleTypeChange(ruleType)
        }}
        formatOptions={option => { return option === TransferRuleType.TARGET_BALANCE ? 'Exceeds' : 'Falls Below' }}
      />
      <FormDollarInput
        fieldName='amount'
        value={amount ?? ''}
        onChange={setAmount}
        placeholder='100'
        fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
        backgroundColor={Color.WHITE}
        // We render the error message outside this component. However, getting error state colors is tightly coupled
        // to passing an error message so passing a dummy string.
        fieldError={amountError != null ? ' ' : undefined}
      />
    </Flex>
    {amountError != null && <Text textColor={Color.ERROR_RED}> {amountError} </Text>}
    <AccountSelectorComponent
      amplifyAccount={
        isCounterpartyTopOption(transferDirection, transferRuleType)
          ? amplifyAccount
          : undefined}
      counterparties={
        isCounterpartyTopOption(transferDirection, transferRuleType)
          ? undefined
          : counterparties}
      transferDirection={TransferDirection.DEBIT /* Patch to filter to personal accounts only */}
      customHeaderText={transferRuleType === TransferRuleType.TARGET_BALANCE
        ? 'Transfer excess funds into'
        : 'Pull funds from'}
      headerColor={Color.DARK_BLUE}
      onSelectedCounterpartyChange={setSelectedCounterparty}
      selectedCounterparty={selectedCounterparty ?? undefined}
    />
    {
    transferRuleType === TransferRuleType.MIN_TARGET_BALANCE &&
    <DropDownComponent
      customHeader={
        <Flex gap={3} grow={1} alignItems='center'>
          <RecurringXIcon color={Color.DARK_BLUE} size={IconSize.MEDIUM}/>
          <Text textColor={Color.DARK_BLUE} fontSize='md'>
            Set a limit on how much you can pull from this account
          </Text>
          <Text textColor={Color.DARK_GREY} fontSize='md'> (Optional) </Text>
        </Flex>
      }
      dropDownContent={
        <Flex direction='column' gap={2}>
          <Text color={Color.DARK_BLUE}>
            When funding {accountToFund}, prevent {sourceAccount} from falling below
          </Text>
          <FormDollarInput
            value={String(safeguardValue)}
            onChange={(value) => { setSafeguardValue(Number(value)) }}
            fieldName=''
            placeholder=''
          />
        </Flex>
      }
    />
    }
    <Button
      text='Submit'
      size={ButtonSize.MEDIUM}
      variant={ButtonVariant.PRIMARY}
      onClick={() => { setIsConfirmationModalOpen(true) }}
      onClickEventType={Event.TRANSFER_RULE_PAGE_CREATE_RULE}
      isDisabled={!isValidInput()}
    />
    <TransferRuleConfirmationModal
      isModalOpen={isConfirmationModalOpen}
      onModalClose={() => { setIsConfirmationModalOpen(false) }}
      onUnhandledSubmissionError={(error) => { handleError(error) }}
      ruleData={{
        amplifyAccount,
        counterparty: selectedCounterparty ?? undefined,
        transferRuleType,
        direction: transferDirection,
        targetBalance: amount,
        safeguard: transferRuleType === TransferRuleType.MIN_TARGET_BALANCE ? safeguardValue : undefined
      }}
    />
  </Flex>

  function handleError (error: ApolloError): void {
    if (isTransferRuleCircularDependencyError(error)) {
      const amount: string = getErrorMetadata(error).targetBalance ?? ''
      const direction = transferRuleType === TransferRuleType.MIN_TARGET_BALANCE ? 'less' : 'greater'
      setAmountError(`This value needs to be equal to or ${direction} than ${getCurrencyAmountFormatted(amount)} to avoid conflicting with an existing rule. Either update this value or disable that rule.`)
    }
  }

  function clearErrors (): void {
    setAmountError(undefined)
  }

  function isValidInput (): boolean {
    if (amplifyAccount?.amplifyAccountId == null) {
      return false
    }
    if (selectedCounterparty?.counterpartyId == null) {
      return false
    }
    if (amount == null || Number(amount) <= 0) {
      return false
    }
    return true
  }
}

function isCounterpartyTopOption (transferDirection: TransferDirection, transferRuleType: TransferRuleType): boolean {
  return (transferDirection === TransferDirection.DEBIT &&
    transferRuleType === TransferRuleType.TARGET_BALANCE) ||
    (transferDirection === TransferDirection.CREDIT &&
      transferRuleType === TransferRuleType.MIN_TARGET_BALANCE)
}
