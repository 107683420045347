import { DateTime } from 'luxon'
import { dateIsBeforeTime, getCurrentDateTimeInUserTimeZone, parseDate } from './dateUtils'
import {
  type GetTransferPageData_amplifyAccount as AmplifyAccount,
  type GetTransferPageData_counterparties as Counterparty
} from '../graphql/__generated__/GetTransferPageData'
import { type Account } from '../types/types'
import { InstitutionConnectionProvider, TransferStatus, TransferType } from '@/graphql/__generated__/globalTypes'
import { ACH_AUTHORIZATION_URL, WIRE_AUTHORIZATION_URL } from '@/theme/urls'

export const ALTIR_WIRE_PROCESSING_FEE = 25
// Indicates the time that First Bank initiates all transfers for a given day
export const ACH_TRANSFER_PROCESSING_TIME_CUTOFF = 15 // 3pm

export const WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_HR = 17 // 5pm
export const WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_MIN = 30

export const ACH_TOOLTIP_MESSAGE = `
ACH transfers initiated before 3:00pm EST will be accessible the following business day.
ACH transfers initiated after 3:00pm EST will be accessible in two business days.
`
export const WIRE_TOOLTIP_MESSAGE = `
Wire transfers initiated before 5:30pm EST will be accessible the same business day.
Wire transfers initiated after 5:30pm EST will be accessible the next business day.
`

export interface TransferComponentContent {
  transferTotal: number
  agreementUrl: string
  transferTypeFormatted: string
}

export function calculateSettleETA (transferType: TransferType): DateTime {
  const estTime = DateTime.now().setZone('America/New_York')
  const userDateTime = getCurrentDateTimeInUserTimeZone()
  let daysForTransferToSettle
  // If a transfer is initiated the daily processing window, it will add a day to delivery.
  // * Wire takes 0-1 business days
  // * ACH takes 1-2 business days
  if (transferType === TransferType.WIRE) {
    daysForTransferToSettle = dateIsBeforeTime(
      estTime, { hour: WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_HR, minute: WIRE_TRANSFER_PROCESSING_TIME_CUTOFF_MIN })
      ? 0
      : 1
  } else {
    daysForTransferToSettle = estTime.hour < ACH_TRANSFER_PROCESSING_TIME_CUTOFF ? 1 : 2
  }

  // TODO account for weekends
  return userDateTime.plus({
    day: daysForTransferToSettle
  })
}

export function getTransferTooltipMessage (transferType: TransferType): string {
  return transferType === TransferType.WIRE
    ? WIRE_TOOLTIP_MESSAGE
    : ACH_TOOLTIP_MESSAGE
}

export function getTransferComponentContent (amount: number, transferType: TransferType): TransferComponentContent {
  if (transferType === TransferType.WIRE) {
    return {
      transferTotal: amount + ALTIR_WIRE_PROCESSING_FEE,
      agreementUrl: WIRE_AUTHORIZATION_URL,
      transferTypeFormatted: 'Wire'
    }
  }
  return {
    transferTotal: amount,
    agreementUrl: ACH_AUTHORIZATION_URL,
    transferTypeFormatted: 'ACH'
  }
}

export function convertCounterpartiesToAccountObjects (
  counterparties: Counterparty[] = []
): Account[] {
  return counterparties.map(counterparty => {
    return {
      ...counterparty,
      counterpartyId: counterparty.counterpartyId ?? undefined,
      wireAccountNumber: counterparty.wireAccountNumber ?? undefined,
      wireRoutingNumber: counterparty.wireRoutingNumber ?? undefined,
      achAccountNumber: counterparty.achAccountNumber ?? undefined,
      achRoutingNumber: counterparty.achRoutingNumber ?? undefined,
      liveBalance: {
        // We are not currently querying for currentBalance in Counterparties, so left null
        currentBalance: null,
        availableBalance: counterparty.liveBalance?.availableBalance ?? null,
        updatedAt: parseDate(counterparty.liveBalance?.updatedAt)
      }
    }
  })
}

export function isPendingTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING,
    TransferStatus.SENT
  ].includes(status)
}

export function isPendingExternalTransfer (status: TransferStatus | null): boolean {
  if (status == null) return false
  return [
    TransferStatus.PENDING,
    TransferStatus.CREATED,
    TransferStatus.PROCESSING
  ].includes(status)
}

export function convertAmplifyAccountToAccountObject (
  amplifyAccount?: AmplifyAccount
): Account | undefined {
  if (amplifyAccount == null) return undefined
  return {
    ...amplifyAccount,
    amplifyAccountId: amplifyAccount.amplifyAccount?.id ?? undefined,
    counterpartyId: undefined,
    liveBalance: {
      // We are not currently querying for currentBalance in Amplify accounts, so left null
      currentBalance: null,
      availableBalance: amplifyAccount.liveBalance?.availableBalance ?? null,
      updatedAt: parseDate(amplifyAccount.liveBalance?.updatedAt)
    },
    connectionProvider: InstitutionConnectionProvider.TREASURY_PRIME
  }
}

export function createInsufficientFundsErrorMessage (amount: string): string {
  const amountFormatted = getCurrencyAmountFormatted(amount)
  return `Your account does not have sufficient funds to complete this transfer. Please ensure that there is at least ${amountFormatted} in your account.`
}

export function getCurrencyAmountFormatted (amount: string): string {
  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD'
  })
}

export function isAltirConnectAccount (account: Account): boolean {
  return account.connectionProvider === InstitutionConnectionProvider.ALTIR
}
