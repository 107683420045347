import React, { type ReactElement } from 'react'
import { Badge, Divider, Flex, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import { type Txn } from './RecipientDropDown'
import { RecipientTransferStatus } from '../RecipientsPage'
import { BorderRadius, Color } from '@/theme/theme'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { getMask } from '@/utils/financialAccountUtils'

interface RecipientPaymentHistoryProps {
  transactions: Txn[]
}

export default function RecipientPaymentHistory ({ transactions }: RecipientPaymentHistoryProps): ReactElement {
  if (transactions.length < 1) {
    return <div></div>
  }
  return (
    <Flex flexDir='column' gap={2} bg={Color.LIGHT_GREY} p={6} borderRadius={BorderRadius.CARD}>
      {
            transactions.map((txn, i) => {
              const isPending = txn.transferStatus === RecipientTransferStatus.PENDING
              const isError = txn.transferStatus === RecipientTransferStatus.ERROR
              const textColor = isPending ? Color.DARK_GREY : Color.DARK_BLUE
              return (
                <Flex key={nanoid()} gap={2} flexDir='column'>
                  {i !== 0 && <Divider/>}
                  <Flex justifyContent='space-between'>
                    <Flex flex={1}><Text color={textColor}>{txn.date}</Text></Flex>
                    <Flex flex={1} alignItems='center' gap={1}>
                      <Text>Altir Amplify</Text>
                      <RightArrowIcon color={Color.DARK_GREY}/>
                      <Text>{getMask(txn.recipientAccountLastFour)}</Text>
                    </Flex>
                    <Flex flex={1} justifyContent='end'>
                      {
                        isPending &&
                          <Badge
                            bg={Color.GREY}
                            color={Color.DARK_GREY}
                            justifyContent='center'
                            textTransform='none'
                            fontSize='sm'
                            px={3}
                          >
                            <Text fontSize='xs'>Pending</Text>
                          </Badge>
                      }
                      {
                        isError &&
                          <Badge
                            border='1px'
                            borderColor={Color.ERROR_RED}
                            backgroundColor={Color.WHITE}
                            justifyContent='center'
                            textTransform='none'
                            fontSize='sm'
                            px={3}
                          >
                            <Text fontSize='xs' textColor={Color.ERROR_RED}>Error</Text>
                          </Badge>
                      }
                    </Flex>
                    <Flex flex={1} justifyContent='end'>
                      <Text color={textColor}>{txn.amount}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              )
            })
        }
    </Flex>
  )
}
