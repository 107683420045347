import { useState } from 'react'

export default function useModal (): { isOpen: boolean, toggle: () => void } {
  const [isOpen, setisOpen] = useState(false)

  const toggle = (): void => {
    setisOpen(!isOpen)
  }

  return {
    isOpen,
    toggle
  }
}
