import React, { useEffect, useState, type ReactElement } from 'react'
import { Center, Flex, Heading, Radio, Text } from '@chakra-ui/react'
import { type ApolloError, useMutation } from '@apollo/client'
import { Event } from 'metrics/metrics'
import AccountSelectorComponent from './account_selector/AccountSelectorComponent'
import { ConfirmTransferModal } from './confirmation_modal/ConfirmTransferModal'
import { TransferSuccessComponent } from './TransferSuccessComponent'
import RecentTransfersComponent from './RecentTransfersComponent'
import AddWireCounterpartyModal from './recipients/AddWireCounterpartyModal'
import RecurringRoolFormSection, { type RecurringRuleFormState }
  from '../../transfer_rules/components/RecurringRuleFormSection'
import TransferRuleConfirmationModal from '../../transfer_rules/components/TransferRuleConfirmationModal'
import {
  ALTIR_WIRE_PROCESSING_FEE,
  createInsufficientFundsErrorMessage
} from '@/utils/transferUtils'
import { getCurrentDate } from '@/utils/dateUtils'
import { getErrorCode } from '@/utils/errorUtils'
import ErrorBanner from '@/library/errors/ErrorBanner'
import Button, { ButtonSize } from '@/library/button/Button'
import TransferIconFilled from '@/library/icons/TransferIconFilled'
import { BorderRadius, Color } from '@/theme/theme'
import { type Account } from '@/types/types'
import { CREATE_TRANSFER } from '@/graphql/mutations/CreateTransfer'
import { CounterpartyType, GraphQLErrorCode, TransferDirection, TransferRuleType, TransferType }
  from '@/graphql/__generated__/globalTypes'
import { type CreateTransferVariables, type CreateTransfer } from '@/graphql/__generated__/CreateTransfer'
import FormDollarInput from '@/library/form/number/FormDollarInput'
import SlideSelect from '@/library/slide_select/SlideSelect'
import PasscodeVerificationModal from '@/library/modal/passcode/PasscodeVerificationModal'
import { isCounterpartyTypeLinkedBankAccount } from '@/utils/financialAccountUtils'
import { useNavigationState } from '@/hooks/useNavigationState'
import PopoverMenu from '@/library/popoverMenu/components/PopoverMenu'
import PopoverMenuSingleOptionSelect, { type PopoverMenuItemContent }
  from '@/library/popoverMenu/PopoverMenuSingleOptionSelect'
import { NumberInputSizeVariant } from '@/library/form/number/FormNumberInput'

export interface TransferComponentProps {
  franchiseGroupId: number
  amplifyAccount?: Account
  counterparties?: Account[]
  isRecurringVendorPaymentsEnabled: boolean
  refetch: () => void
}

const ACH_DROPDOWN_TITLE = 'ACH (Free, Next Business Day)'
const ACH_DROPDOWN_SUBTITLE = `
  ACH transfers initiated before 3:00pm EST will be accessible the following business day.
  ACH transfers initiated after 3:00pm EST will be accessible in two business days.
`

const WIRE_DROPDOWN_TITLE = `Wire ($${ALTIR_WIRE_PROCESSING_FEE} Fee, Same Business Day)`
const WIRE_DROPDOWN_SUBTITLE = `
  Wire transfers initiated before 5:30pm EST will be accessible the same business day. 
  Wire transfers initiated after 5:30pm EST will be accessible the next business day.
`

export default function TransferComponent (
  {
    franchiseGroupId,
    amplifyAccount,
    counterparties,
    isRecurringVendorPaymentsEnabled,
    refetch
  }: TransferComponentProps
): ReactElement {
  const [
    createTransfer,
    { loading: isTransferCreationLoading }
  ] = useMutation<CreateTransfer, CreateTransferVariables>(
    CREATE_TRANSFER, {
      onCompleted: handleTransferSuccess,
      onError: handleAsyncTransferError
    }
  )

  // If url param contains counterpartyId, set as CREDIT and initialize component with CP selected
  const stateOnNavigate = useNavigationState<{ recipientCounterpartyId: string | undefined }>()

  const [preSelectedCounterpartyId, setPreSelectedCounterpartyId] = useState(stateOnNavigate?.recipientCounterpartyId)
  const [transferDirection, setTransferDirection] = useState(
    preSelectedCounterpartyId != null ? TransferDirection.CREDIT : TransferDirection.DEBIT
  )
  const [selectedCounterparty, setSelectedCounterparty] = useState<Account | null>(
    counterparties?.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null
  )

  useEffect(() => {
    setSelectedCounterparty(counterparties?.find(cp => cp.counterpartyId === preSelectedCounterpartyId) ?? null)
  }, [counterparties, preSelectedCounterpartyId])

  const [transferError, setTransferError] = useState<string | null>(null)
  const [amount, setAmount] = useState<string>()
  const [isRecurringTransfer, setIsRecurringTransfer] = useState<boolean>(false)

  const [transferType, setTransferType] = useState<TransferType>(TransferType.SAMEDAY_ACH)
  const selectableTransferTypes = transferDirection === TransferDirection.CREDIT
    ? [TransferType.SAMEDAY_ACH, TransferType.WIRE]
    : [TransferType.SAMEDAY_ACH]

  useEffect(() => {
    if (transferType === TransferType.WIRE) {
      setIsRecurringTransfer(false)
    }
  }, [transferType])

  // If user selects WIRE and flips back to 'Into Amplify', we need to revert this back to ACH.
  useEffect(() => {
    if (transferDirection === TransferDirection.DEBIT) {
      setTransferType(TransferType.SAMEDAY_ACH)
    }
  }, [transferDirection])

  // Transfer completion
  const [isTransferConfirmationModalOpen, setIsTransferConfirmationModalOpen] = useState(false)
  const [isRecurringTransferConfirmationModalOpen, setIsRecurringTransferConfirmationModalOpen] = useState(false)

  const [isTransferComplete, setIsTransferComplete] = useState(false)

  // Passcode verification
  const [isPasscodeVerificationModalOpen, setIsPasscodeVerificationModalOpen] = useState(false)
  const [isPasscodeVerified, setIsPasscodeVerified] = useState(false)

  // Wire Modal
  const [isWireDetailsModalOpen, setIsWireDetailsModalOpen] = useState<boolean>(false)

  function handleTransferDirectionSelection (value: string): void {
    if (value === TransferDirection.DEBIT || value === TransferDirection.CREDIT) {
      setTransferDirection(value)
    }

    // Non-Plaid accounts can't be DEBIT counterparties. If the user switches transfer direction
    // while a non-Plaid account is selected, we clear the selection
    if (
      value === TransferDirection.DEBIT &&
      selectedCounterparty?.counterparty?.counterpartyType !== CounterpartyType.PLAID) {
      setSelectedCounterparty(null)
    }
  }

  function handleAsyncTransferError (error: ApolloError): void {
    const errorCode = getErrorCode(error)
    switch (errorCode) {
      case GraphQLErrorCode.AUTHORIZATION_ERROR:
        handleTransferError(
          "You don't have permission to make this transfer. " +
          'Reach out to the account administrator or contact us for help.'
        )
        return
      case GraphQLErrorCode.DUPLICATE_TRANSFER_ERROR:
        handleTransferError(
          'This transfer is identical to one you initiated a few minutes ago. ' +
          'If this was intentional, you can try again in 5 minutes or contact support.'
        )
        return
      case GraphQLErrorCode.INSUFFICIENT_FUNDS:
        handleTransferError(createInsufficientFundsErrorMessage(String(amount)))
        return
      case GraphQLErrorCode.UNVERIFIED_COUNTERPARTY:
        handleTransferError('We need to verify that you own this account. Contact us with questions.')
        return
      case GraphQLErrorCode.TRANSFER_LIMIT_EXCEEDED:
        handleTransferError('This transfer exceeds your ACH limits. Try again later or contact us for help.')
        return
      default:
        handleTransferError('Try again later or contact us for help.')
    }
  }

  function handleTransferError (message: string): void {
    setTransferError(message)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  async function handleTransferCreation (): Promise<void> {
    const amplifyAccountId = amplifyAccount?.amplifyAccountId
    if (amplifyAccountId == null) {
      handleTransferError('Please make sure that your Amplify Account is selected.'); return
    }
    if (selectedCounterparty?.counterpartyId == null) {
      handleTransferError('Please select a counterparty.'); return
    }

    // Validate amount
    if (amount == null) {
      handleTransferError('Amount is required.'); return
    }
    if (transferDirection === TransferDirection.CREDIT) {
      if (Number(amount) > (
        amplifyAccount?.liveBalance?.availableBalance?.amount ?? 0
      )) {
        handleTransferError(createInsufficientFundsErrorMessage(amount)); return
      }
    } else {
      if (Number(amount) > (selectedCounterparty.liveBalance?.availableBalance?.amount ?? 0)) {
        handleTransferError(createInsufficientFundsErrorMessage(amount)); return
      }
    }

    await createTransfer({
      variables: {
        input: {
          amount: Number(amount),
          amplifyAccountId,
          counterpartyId: selectedCounterparty?.counterpartyId,
          direction: transferDirection,
          type: transferType
        }
      }
    })
  }

  function handleTransferSuccess (): void {
    setIsTransferComplete(true)
    setIsTransferConfirmationModalOpen(false)
    setIsPasscodeVerified(false)
  }

  function handleTransferInitiation (): void {
    if (
      !isCounterpartyTypeLinkedBankAccount(selectedCounterparty?.counterparty?.counterpartyType)
    ) {
      setIsPasscodeVerificationModalOpen(true)
    } else {
      openTransferConfirmationModal()
    }
  }

  function onPasscodeVerificationModalClose (): void {
    setIsPasscodeVerificationModalOpen(false)
    if (isPasscodeVerified) {
      // Setting timeout to avoid jumpy modal to modal transition
      setTimeout(() => {
        openTransferConfirmationModal()
      }, 200)
    }
  }

  function openTransferConfirmationModal (): void {
    // TODO - refactor the flow of passcode + wire + confirmation modals
    if (needsWireVerification(transferType, selectedCounterparty)) {
      setIsWireDetailsModalOpen(true)
    } else if (isRecurringTransfer) {
      setIsRecurringTransferConfirmationModalOpen(true)
    } else {
      setIsTransferConfirmationModalOpen(true)
    }
  }

  function onWireInfoSubmitted (newCounterpartyId: string): void {
    setIsWireDetailsModalOpen(false)
    // Refetch to pull the updated wire info to prevent re-prompting user for same info
    refetch()
    // Reset the selected counterparty to avoid counterparty from reverting to null
    setPreSelectedCounterpartyId(newCounterpartyId)
    setIsTransferConfirmationModalOpen(true)
  }

  const isFlipped = transferDirection === TransferDirection.CREDIT

  function isFormValid (): boolean {
    const isMainFormValid = amount != null && Number(amount) > 0 && selectedCounterparty?.counterpartyId != null &&
           amplifyAccount?.amplifyAccountId != null
    if (!isMainFormValid) {
      return false
    }
    // By default, recurring transfers are only supported to accounts that user owns
    // Users can opt-in to enable recurring payments to vendors (managed by feature flag)
    // TODO Add more clear messaging in UI when recurring disabled due to external counterparty selection
    if (
      isRecurringTransfer &&
      selectedCounterparty.counterparty?.counterpartyType !== CounterpartyType.PLAID &&
      !isRecurringVendorPaymentsEnabled
    ) {
      return false
    }
    return true
  }

  const fromAccount = transferDirection === TransferDirection.DEBIT ? selectedCounterparty : amplifyAccount
  const toAccount = transferDirection === TransferDirection.DEBIT ? amplifyAccount : selectedCounterparty

  const [recurringForm, setRecurringForm] = useState<RecurringRuleFormState>({
    dayOfWeek: 'Monday',
    dayOfMonth: 1,
    transferRuleType: TransferRuleType.DAILY,
    transferDate: getCurrentDate()
  })

  if (isTransferComplete && amount != null) {
    return (
      <TransferSuccessComponent
        amount={Number(amount)}
        fromAccount={fromAccount ?? undefined}
        toAccount={toAccount ?? undefined}
        transferType={transferType}
      />
    )
  }
  return (
    <Flex flexDirection='column' width='100%' justifyContent='center' gap={6}>
      <ConfirmTransferModal
        isOpen={isTransferConfirmationModalOpen}
        onClose={() => { setIsTransferConfirmationModalOpen(false) }}
        amount={Number(amount)}
        date={getCurrentDate()}
        fromAccount={fromAccount ?? undefined}
        toAccount={toAccount ?? undefined}
        onConfirm={handleTransferCreation}
        isTransferCreationLoading={isTransferCreationLoading}
        transferDirection={transferDirection}
        transferType={transferType}
      />
      <TransferRuleConfirmationModal
        isModalOpen={isRecurringTransferConfirmationModalOpen}
        onModalClose={() => { setIsRecurringTransferConfirmationModalOpen(false) }}
        onUnhandledSubmissionError={() => {}}
        ruleData={{
          amplifyAccount,
          counterparty: selectedCounterparty ?? undefined,
          transferRuleType: recurringForm.transferRuleType,
          direction: transferDirection,
          transferAmount: amount,
          targetDate:
            recurringForm.transferRuleType === TransferRuleType.MONTHLY ? recurringForm.dayOfMonth : undefined,
          targetDay: recurringForm.transferRuleType === TransferRuleType.WEEKLY ? recurringForm.dayOfWeek : undefined,
          ruleStartDate: recurringForm.transferDate
        }}
      />
      <PasscodeVerificationModal
        isOpen={isPasscodeVerificationModalOpen}
        onClose={onPasscodeVerificationModalClose}
        onVerificationStatusChange={(isVerified) => { setIsPasscodeVerified(isVerified) }}
      />
      <AddWireCounterpartyModal
        isOpen={isWireDetailsModalOpen}
        onSuccess={(newCounterpartyId) => {
          onWireInfoSubmitted(newCounterpartyId)
        }}
        onClose={() => { setIsWireDetailsModalOpen(false) }}
        counterpartyId={selectedCounterparty?.counterpartyId ?? ''}
        achAccountNumber={selectedCounterparty?.achAccountNumber ?? ''}
        achRoutingNumber={selectedCounterparty?.achRoutingNumber ?? ''}
      />
      <ErrorBanner
        errorTitle='Problem Making Transfer'
        errorSubTitle={transferError}
      />
      <Heading
        color={Color.DARK_BLUE}
        size='md'
      >
        Make a Transfer
      </Heading>
      <SlideSelect
        selectedValue={transferDirection}
        options={[TransferDirection.DEBIT, TransferDirection.CREDIT]}
        handleSelection={handleTransferDirectionSelection}
        formatValue={(value: string) => {
          return value === TransferDirection.CREDIT ? 'Out of Amplify' : 'Into Amplify'
        }}
      />
      <Center flexDir='column' w='100%' gap={4}>
        <FormDollarInput
          fieldName='amount'
          value={amount ?? ''}
          label='Amount'
          onChange={setAmount}
          placeholder='100'
          fieldInteriorLabel={<Text color={Color.DARK_BLUE}>$</Text>}
          backgroundColor={Color.WHITE}
          variant={NumberInputSizeVariant.HERO}
        />
        <Flex direction='column' gap={2} w='100%'>
          <Text size='sm'> Method </Text>
          <PopoverMenu
            popoverMenuTitle={getFormattedDropdownForType(transferType).title}
            onSubmit={() => {}}
            shouldMatchWidth={true}
            hasSelection={true}
            paddingY={7}
            paddingX={2}
            borderRadius={BorderRadius.CARD}
            border='0px'
          >
            <PopoverMenuSingleOptionSelect
              options={selectableTransferTypes}
              selectedOption={transferType}
              formattingFunction={type => { return getFormattedDropdownForType(type) }}
              setSelectedOption={(transferType: TransferType) => {
                setTransferType(transferType)
              }}
              dividerFrequency={0}
              maxSubtitleWidth={undefined}
            />
          </PopoverMenu>
        </Flex>
        <Flex width='100%' alignItems='center' justifyContent='center'>
          <Flex
            w='100%'
            gap={4}
            flexDirection={isFlipped ? 'column-reverse' : 'column'}
            transition='flexDirection 0.2s'
          >
            <AccountSelectorComponent
              counterparties={counterparties}
              onSelectedCounterpartyChange={setSelectedCounterparty}
              selectedCounterparty={selectedCounterparty ?? undefined}
              transferDirection={transferDirection}
            />
            <AccountSelectorComponent
              amplifyAccount={amplifyAccount}
              transferDirection={transferDirection}
            />
          </Flex>
        </Flex>
        {
          transferType === TransferType.SAMEDAY_ACH &&
            <Flex gap={4} width='100%' padding={2}>
              <Radio
                size='lg'
                isChecked={isRecurringTransfer}
                colorScheme='selectableInput'
                onClick={() => { setIsRecurringTransfer(!isRecurringTransfer) }}
              />
              <Text> Make this a recurring transfer </Text>
            </Flex>
        }
        {
          isRecurringTransfer &&
            <RecurringRoolFormSection
              formState={recurringForm}
              setState={setRecurringForm}
            />
        }
      </Center>
      <Flex width='100%'>
        <Button
          text='Make Transfer'
          onClick={handleTransferInitiation}
          beforeIcon={<TransferIconFilled color={Color.WHITE}/>}
          isDisabled={!isFormValid() || isTransferCreationLoading}
          isLoading={isTransferCreationLoading}
          size={ButtonSize.LARGE}
          onClickEventType={!isRecurringTransfer ? Event.INITIATE_TRANSFER_CLICK : Event.TRANSFER_PAGE_CREATE_RULE}
        />
      </Flex>
      <Flex width='100%' my={16}>
        <RecentTransfersComponent franchiseGroupId={franchiseGroupId}/>
      </Flex>
    </Flex>
  )
}

function getFormattedDropdownForType (type: TransferType): PopoverMenuItemContent {
  switch (type) {
    case TransferType.SAMEDAY_ACH:
      return {
        title: ACH_DROPDOWN_TITLE,
        subtitle: ACH_DROPDOWN_SUBTITLE
      }
    case TransferType.WIRE:
      return {
        title: WIRE_DROPDOWN_TITLE,
        subtitle: WIRE_DROPDOWN_SUBTITLE
      }
    default:
      console.error('Unsupported transfer type for transfer page selector')
      return { title: '', subtitle: '' }
  }
}

function needsWireVerification (
  transferType: TransferType,
  selectedCounterparty: Account | null): boolean {
  return (
    transferType === TransferType.WIRE &&
    selectedCounterparty?.wireAccountNumber == null && selectedCounterparty?.wireRoutingNumber == null
  )
}
