import React, { type LegacyRef, type ReactElement, type RefObject } from 'react'
import {
  Flex,
  Heading,
  Link,
  Text,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Box
} from '@chakra-ui/react'
import { Event } from 'metrics/metrics'
import { sendSupportEmail } from './utils'
import {
  BorderRadius,
  Color,
  IconSize
} from '@/theme/theme'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import HelpCenterIcon from '@/library/icons/HelpCenterIcon'
import { HELP_CENTER_LINK } from '@/utils/constants'

export default function HelpCenterPopoverComponent (): ReactElement {
  const initialFocusRef = React.useRef()
  return (
    <Popover initialFocusRef={initialFocusRef as unknown as RefObject<{ focus: any }>}>
      <PopoverTrigger>
        <Box
          position='fixed'
          bottom='6'
          right='6'
          bg={Color.BRIGHT_BLUE}
          borderRadius='100%'
          p={4}
          _hover={{ cursor: 'pointer', bg: Color.BRIGHT_BLUE_HOVER }}
        >
          <HelpCenterIcon color={Color.WHITE} size={IconSize.SMALL}/>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        boxShadow='0px 0px 40px rgba(0, 0, 0, 0.1)'
        mr={6}
        mb={2}
        borderRadius={BorderRadius.CARD}
        bg={Color.LIGHT_GREY}
        borderColor={Color.LIGHT_GREY}
      >
        <PopoverBody
          bg={Color.LIGHT_GREY}
          borderColor={Color.LIGHT_GREY}
          borderRadius={BorderRadius.CARD}
          p={8}
        >
          <Flex flexDir='column' w='100%' gap={8} alignItems='center'>
            <Flex flexDir='column' alignItems='center' gap={2} w='100%'>
              <Heading size='md' color={Color.DARK_BLUE}>Contact Us</Heading>
              <Text textAlign='center'>
                Reach out to us by email or phone.
              </Text>
              <PopoverCloseButton mr={2} my={2} color={Color.DARK_GREY} size='md'/>
            </Flex>
            <Flex flexDir='column' justifyContent='center' alignItems='center' gap={1}>
              <Text color={Color.DARK_BLUE}>support@altir.app</Text>
              <Text color={Color.DARK_BLUE}>1-862-409-4490</Text>
            </Flex>
            <Flex flexDir='column' gap={4} w='100%' ref={initialFocusRef as unknown as LegacyRef<HTMLDivElement>}>
              <Link href={HELP_CENTER_LINK} isExternal >
                <Button
                  size={ButtonSize.SMALL}
                  text='Visit Help Center'
                  onClickEventType={Event.VISIT_HELP_CENTER_CLICK}
                />
              </Link>
              <Button size={ButtonSize.SMALL}
                text='Open Email App'
                variant={ButtonVariant.WHITE}
                onClick={sendSupportEmail}
                onClickEventType={Event.CONTACT_US_CLICK}
              />
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
