import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Link, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { useQuery } from '@apollo/client'
import RecipientDropDown from './components/RecipientDropDown'
import AddNewRecipientModal from '../transfer/components/recipients/AddNewRecipientModal'
import Page from '@/library/page/Page'
import Button from '@/library/button/Button'
import { Color, ContainerWidth, FontWeight, IconSize } from '@/theme/theme'
import PlusIcon from '@/library/icons/PlusIcon'
import { ROUTES } from '@/api/routes'
import { GET_RECENT_RECIPIENTS_PAGE }
  from '@/graphql/queries/GetRecipientsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { DateTimeTemplate, getFormattedDateString } from '@/utils/dateUtils'
import {
  type GetRecentRecipientsPageVariables,
  type GetRecentRecipientsPage
} from '@/graphql/__generated__/GetRecentRecipientsPage'
import { isPendingExternalTransfer } from '@/utils/transferUtils'
import { CounterpartyType, TransferStatus } from '@/graphql/__generated__/globalTypes'
import { useAltirStore } from '@/hooks/store/useAltirStore'

export enum RecipientTransferStatus {
  PENDING,
  ERROR,
  SENT
}

export default function RecipientsPage (): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)
  const navigate = useNavigate()
  const { data, loading, error, refetch } =
     useQuery<GetRecentRecipientsPage, GetRecentRecipientsPageVariables>(GET_RECENT_RECIPIENTS_PAGE, {
       variables: {
         franchiseGroupId,
         transferLimit: 3,
         counterpartyTypes: [CounterpartyType.EXTERNAL_VENDOR, CounterpartyType.EXTERNAL_PERSONAL]
       }
     })

  const amplifyAccountId = data?.currentUser?.franchiseGroup?.amplifyAccount?.amplifyAccount?.id

  const recipients = data?.currentUser?.franchiseGroup?.counterparties.map(
    recipient => {
      return {
        id: recipient.id,
        name: recipient.name,
        isVerified: recipient.isOwnershipVerified,
        transactions: recipient.transfers?.map(transfer => {
          const achNumber = recipient.achAccountNumber
          return {
            amount: transfer.amount.amountFormatted ?? '',
            date: getFormattedDateString(transfer.createdAt, DateTimeTemplate.MONTH_YEAR_SHORT) ?? '',
            transferStatus: getTransferStatus(transfer.status),
            recipientAccountLastFour: achNumber?.substring(achNumber.length - 4) ?? ''
          }
        }) ?? []
      }
    }
  ) ?? []

  const [isAddNewRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false)

  // Feature flags
  const isWiresEnabled = data?.currentUser?.rolloutConfiguration?.enableWires === true

  return (
    <Page maxWidth={ContainerWidth.FULL_PAGE}>
      <AltirSkeleton
        isLoading={loading}
        error={error}
      >
        <Flex flexDir='column' gap={6}>
          <Flex justifyContent='space-between' alignItems='center'>
            <Heading color={Color.DARK_BLUE} size='md'>My Recipients</Heading>
            <Button
              text='Add a New Recipient'
              beforeIcon={<PlusIcon color={Color.WHITE} size={IconSize.SMALL}/>}
              width='auto'
              onClick={() => { setIsAddRecipientModalOpen(true) }}
            />
          </Flex>
          <Flex flexDir='column' gap={2}>
            {recipients.map(recipient => {
              return <RecipientDropDown key={nanoid()} recipient={recipient}/>
            })}
          </Flex>
          <Flex justifyContent='center' alignItems='center' w='100%'>
            <Text textAlign='center' maxW='400px'>
              You can view your own accounts that you have linked through this process on the{' '}
              <Link
                as='span'
                onClick={() => { navigate(ROUTES.TREASURY) }}
                color={Color.BRIGHT_BLUE_HOVER}
                fontWeight={FontWeight.NORMAL}
              >
                Treasury Page
              </Link>
            </Text>
          </Flex>
        </Flex>
        <AddNewRecipientModal
          isOpen={isAddNewRecipientModalOpen}
          onClose={() => { setIsAddRecipientModalOpen(false) }}
          amplifyAccountId={amplifyAccountId}
          franchiseGroupId={franchiseGroupId}
          onSuccess={
            refetch
          }
          isWiresEnabled={isWiresEnabled}
        />
      </AltirSkeleton>
    </Page>
  )
}

function getTransferStatus (transferStatus: TransferStatus | null): RecipientTransferStatus {
  if (transferStatus == null) {
    return RecipientTransferStatus.SENT
  }

  if (transferStatus === TransferStatus.ERROR) {
    return RecipientTransferStatus.ERROR
  }
  if (isPendingExternalTransfer(transferStatus)) {
    return RecipientTransferStatus.PENDING
  }
  return RecipientTransferStatus.SENT
}
