import { CounterpartyTypeSearchCriteria } from './AccountSelectorModal'
import { CounterpartyType, TransferDirection } from '@/graphql/__generated__/globalTypes'
import { type Account } from '@/types/types'
import { isCounterpartyTypeOwnedBankAccount, isCounterpartyTypeValidForDebit } from '@/utils/financialAccountUtils'

export const COUNTERPARTY_NEEDS_VERIFICATION_TEXT_NEW =
  // eslint-disable-next-line max-len
  'This account is being verified. Any transfers you initiate may be delayed until the verification process is complete.'

export function getCounterpartyTypeOptions (transferDirection?: TransferDirection): CounterpartyTypeSearchCriteria[] {
  return transferDirection === TransferDirection.CREDIT
    ? [
        CounterpartyTypeSearchCriteria.ALL,
        CounterpartyTypeSearchCriteria.ACCOUNT,
        CounterpartyTypeSearchCriteria.VENDOR
      ]
    : [CounterpartyTypeSearchCriteria.ACCOUNT]
}

export function filterCounterparties (
  counterparties: Account[],
  type: CounterpartyTypeSearchCriteria,
  searchQuery: string,
  transferDirection?: TransferDirection
): Account[] {
  return counterparties
    // Filter based on transfer direction
    .filter(counterparty => {
      if (transferDirection === TransferDirection.CREDIT) return true
      return isCounterpartyTypeValidForDebit(counterparty.counterparty?.counterpartyType)
    })
    // Filter based on selected type
    .filter(counterparty => {
      const counterpartyType = counterparty.counterparty?.counterpartyType
      if (type === CounterpartyTypeSearchCriteria.VENDOR) {
        return counterpartyType === CounterpartyType.EXTERNAL_VENDOR
      } else if (type === CounterpartyTypeSearchCriteria.ACCOUNT) {
        return isCounterpartyTypeOwnedBankAccount(counterpartyType)
      }
      return true
    })
    // Filter based on search query text
    .filter(counterparty => {
      const formattedQuery = searchQuery.toLowerCase()
      return counterparty.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.institution?.name?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.lastFour?.includes(formattedQuery) === true ||
        counterparty.counterparty?.nickname?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.achAccountNumber?.toLowerCase().includes(formattedQuery) === true ||
        counterparty.counterparty?.nameOnAccount?.toLocaleLowerCase().includes(formattedQuery) === true
    })
}
