export const ASSET_HOSTING_URL = 'https://assets.altir.app'

export const ACH_AUTHORIZATION_URL = `${ASSET_HOSTING_URL}/assets/ach_authorization_agreement_11_17_2023.pdf`
export const WIRE_AUTHORIZATION_URL = `${ASSET_HOSTING_URL}/assets/wire_authorization_agreement_7_24_2024.pdf`
export const E_SIGN_AGREEMENT_URL = `${ASSET_HOSTING_URL}/assets/electronic_consent_11_17_2023.pdf`
export const PRIVACY_POLICY_URL = `${ASSET_HOSTING_URL}/assets/altir_privacy_policy.pdf`
export const TERMS_OF_SERVICE_URL = `${ASSET_HOSTING_URL}/assets/altir_terms_of_service_10_3_23.pdf`

// FirstBankDocs
export const FIRST_BANK_TESTING_ADDENDUM_URL = `${ASSET_HOSTING_URL}/assets/firstbank_testing_addendum_01_29_2023.pdf`
export const FIRST_BANK_CUSTOMER_AGREEMENT_URL = `${ASSET_HOSTING_URL}/assets/firstbank_customer_agreement_3_29_2024.pdf`
