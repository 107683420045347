import React, { type ReactElement } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import MoreInfoTooltip from './MoreInfoTooltip'
import EditIcon from '../icons/EditIcon'
import { FontWeight, Color, IconSize } from '@/theme/theme'
import SensitiveTextComponent from '@/library/text/SensitiveTextComponent'

export interface StackedInfoAttributeProps {
  header?: string
  subtext?: string
  sensitiveSubtext?: string
  infoTooltip?: string
  linkOptions?: {
    onLinkClick: () => void
    linkText: string
  }
  editOptions?: {
    onEdit: () => void
    editText?: string
  }
  customColor?: Color
}

export default function StackedInfoAttribute (
  {
    header,
    subtext,
    sensitiveSubtext,
    linkOptions,
    editOptions,
    infoTooltip,
    customColor
  }: StackedInfoAttributeProps
): ReactElement {
  return (
    <Flex direction='column' width='100%'>
      <Flex flexDir='row' gap={1} align='center'>
        <Heading size='xs' color={Color.DARK_GREY}>{header}</Heading>
        {infoTooltip != null && <MoreInfoTooltip tooltipText={infoTooltip} iconSize={IconSize.SMEXTRA_SMALL}/>}
      </Flex>
      <Flex gap={2} width='100%'>
        {/* Subtext */}
        {
          subtext != null &&
            <Text color={customColor ?? Color.DARK_BLUE} fontSize='md' noOfLines={1}> {subtext} </Text>
        }
        {/* Sensitive Subtext */}
        {sensitiveSubtext != null && <SensitiveTextComponent text={sensitiveSubtext} description={header}/>}
        {/* Text With edit option */}
        {editOptions != null &&
          <Flex width='100%' alignSelf='end' align='top' cursor='pointer' onClick={editOptions.onEdit} gap={1}>
            {editOptions.editText != null &&
            <>
              <Text color={Color.DARK_BLUE} fontSize='md' maxWidth='100%' noOfLines={1}>
                {editOptions.editText}
              </Text>
              <EditIcon size={IconSize.SMALL} color={Color.DARK_GREY}/>
            </>}
          </Flex>
        }
        {/* Link Text */}
        {linkOptions != null &&
          <Box _hover={{ cursor: 'pointer' }}>
            <Text
              color={Color.BRIGHT_BLUE_HOVER}
              fontWeight={FontWeight.NORMAL}
              onClick={linkOptions.onLinkClick}
            >{linkOptions.linkText}</Text>
          </Box>
        }
      </Flex>
    </Flex>
  )
}
