import React from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, type ReactElement } from 'react'
import { usePlaidLink, type PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { Event } from 'metrics/metrics'
import AccountLinkLoaderModal from '../financial_institution/AccountLinkLoaderModal'
import { InstitutionConnectionProvider, type LinkSessionMetadataInput } from '@/graphql/__generated__/globalTypes'
import { useInstitutionCreateLink } from '@/hooks/useInstitutionCreateLink'
import { type ErrorWithContent } from '@/types/types'
import { getErrorToast, getSuccessToast } from '@/utils/toastUtils'

export enum PlaidLinkButtonVariant {
  DEFAULT,
  TRANSPARENT,
  CTA
}

const defaultErrorMessage = {
  title: 'Something went wrong while linking your account',
  subtitle: 'Please try again later. If the issue persists, contact us.'
}
interface PlaidComponentProps {
  franchiseGroupId: number
  plaidAccessToken?: string
  onSuccess: () => void
  onError?: (error: ErrorWithContent) => void
  customButton: ReactElement
}

export function PlaidComponent ({
  plaidAccessToken,
  franchiseGroupId,
  onSuccess,
  onError,
  customButton
}: PlaidComponentProps
): ReactElement {
  const toast = useToast()
  const {
    initialize,
    isUpdateMode,
    isInitializationMutationLoading,
    onSuccessfulPlaidAccountLogin,
    isLinkMutationLoading,
    linkToken,
    logError,
    logEvent
  } = useInstitutionCreateLink({
    selectedFranchiseGroupId: franchiseGroupId,
    connectionProvider: InstitutionConnectionProvider.PLAID,
    plaidAccessToken,
    onSuccess: handleSuccess,
    onError: handleError
  })

  function handleSuccess (): void {
    onSuccess()
    toast(getSuccessToast('Account Linked'))
  }

  // Plaid Link will not start to load until plaidLinkToken is not null
  const { open: openPlaidLink, ready: isPlaidLinkReady, exit: closePlaidLink } = usePlaidLink({
    token: linkToken ?? null,
    onSuccess: onSuccessfulPlaidAccountLogin,
    onExit: (error, metadata) => {
      if (error == null) {
        logEvent({ metadata }); return
      }
      if (error?.error_code === 'INVALID_LINK_TOKEN') {
        handlePlaidTokenInvalidation()
      }
      handleError({
        customContent: {
          ...defaultErrorMessage,
          subtitle: error?.display_message
        },
        error: Error('Plaid Client Link Error')
      })
      logError({ error, metadata })
    },
    onEvent: (eventName) => { logEvent({ eventName }) }
  })

  function handlePlaidTokenInvalidation (): void {
  // https://plaid.com/docs/link/handle-invalid-link-token/
    closePlaidLink()
    initialize()
    openPlaidLink()
  }

  function handleError (errorWithContent: ErrorWithContent): void {
    if (onError != null) {
      onError(errorWithContent)
    }
    toast(getErrorToast('Account Link Failed'))
  }

  // Wait until component mounts to fetch link token
  useEffect(() => {
    initialize()
  }, [])

  const plaidLinkButton = React.cloneElement(customButton, {
    onClickEventType: isUpdateMode ? Event.PLAID_RELINK_OPEN_CLICK : Event.PLAID_LINK_OPEN_CLICK,
    isLoading: !isPlaidLinkReady && !isInitializationMutationLoading
  })
  return (
    <Flex onClick={() => openPlaidLink()} width='100%'>
      <AccountLinkLoaderModal isOpen={isLinkMutationLoading}/>
      {plaidLinkButton}
    </Flex>
  )
}

export function formatOnSuccessMetadata (metadata: PlaidLinkOnSuccessMetadata): LinkSessionMetadataInput {
  return {
    institutionId: metadata.institution?.institution_id ?? '',
    accounts: metadata.accounts.map(account => ({
      id: account.id,
      mask: account.mask,
      name: account.name,
      subtype: account.subtype,
      type: account.type
    }))
  }
}
