import { gql } from '@apollo/client'
import { AUTHORIZATION_FRAGMENT } from '../../fragments/AuthorizationFragment'

export const GET_ACCOUNT_DETAILS_PAGE_DATA = gql`
    ${AUTHORIZATION_FRAGMENT}
    query GetAccountDetailsPageData($accountId: Int!, $franchiseGroupId: Int!, $organizationId: String!) {
        financialAccount(accountId: $accountId) {
            liveBalance {
                availableBalance {
                    amount
                    amountFormatted
                }
                currentBalance {
                    amount
                    amountFormatted
                }
            }
        }
        currentUser {
            authorization(organizationId: $organizationId, franchiseGroupId: $franchiseGroupId) {
                ...AuthorizationFragment
            }
        }
    }
`
