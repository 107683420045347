import { DateTimeTemplate, getFormattedDateString } from './dateUtils'
import { getMask } from './financialAccountUtils'
import { getCurrencyFormatted } from './stringUtils'
import { TransferDirection, TransferRuleErrorType, TransferRuleLastEvalResult, TransferRuleType }
  from '@/graphql/__generated__/globalTypes'
import {
  type GetTreasuryPageData_currentUser_franchiseGroup_financialAccounts_transferRules as TransferRule
} from '@/graphql/__generated__/GetTreasuryPageData'

export enum GenericTransferRuleType {
  BALANCE,
  TIME
}

export interface FormattedBalanceRule {
  to: string
  from: string
  balance: string
  mostRecentTransfer: string
  sentenceDescription: string
  updatedAt: string
}

export interface FormattedTimeRule {
  to: string
  from: string
  amount: string
  dayOfWeek?: string
  dateOfMonth?: string
  ruleStartDate?: string
  mostRecentTransfer: string
  nextTransfer: string
  frequency: string
  updatedAt: string
}

export function getFormattedBalanceRule (transferRule: TransferRule): FormattedBalanceRule {
  const formatted = {
    balance: transferRule.targetBalance != null
      ? getCurrencyFormatted(transferRule.targetBalance, { minimumFractionDigits: 2 })
      : 'N/A',
    to: toAccountName(transferRule),
    from: fromAccountName(transferRule),
    mostRecentTransfer: getFormattedDateString(transferRule.mostRecentTransferEST, DateTimeTemplate.FULL) ?? 'N/A',
    updatedAt: getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.FULL) ?? 'N/A'

  }
  const sentenceDescription = transferRule.transferRuleType === TransferRuleType.TARGET_BALANCE
    ? `When ${formatted.from} exceeds ${formatted.balance}, excess funds will be transferred into ${formatted.to}.`
    : `When ${formatted.to} falls below ${formatted.balance}, funds will be pulled from ${formatted.from}.`

  return {
    ...formatted,
    sentenceDescription
  }
}

export function getFormattedTimeRule (transferRule: TransferRule): FormattedTimeRule {
  return {
    amount: transferRule.transferAmount != null
      ? getCurrencyFormatted(transferRule.transferAmount, { minimumFractionDigits: 2 })
      : 'N/A',
    to: toAccountName(transferRule),
    from: fromAccountName(transferRule),
    mostRecentTransfer: getFormattedDateString(transferRule.mostRecentTransferEST, DateTimeTemplate.FULL) ?? 'N/A',
    nextTransfer: getFormattedDateString(transferRule.nextScheduledTransferEST, DateTimeTemplate.FULL) ?? 'N/A',
    frequency: formatTransferRuleType(transferRule.transferRuleType),
    dayOfWeek: transferRule.targetDay ?? '',
    dateOfMonth: transferRule.targetDate != null
      ? formatDateOfMonthToString(transferRule.targetDate)
      : undefined,
    ruleStartDate: getFormattedDateString(transferRule.ruleStartDate, DateTimeTemplate.FULL) ?? undefined,
    updatedAt: getFormattedDateString(transferRule.updatedAt, DateTimeTemplate.FULL) ?? 'N/A'
  }
}

export function formattedCounterpartyForRule (rule: TransferRule): string {
  const counterpartyName = rule.counterparty.name
  const counterpartyAchNumber = getMask(rule.counterparty.achAccountNumber) ?? ''
  return `${counterpartyName} ${counterpartyAchNumber}`
}

export function fromAccountName (rule: TransferRule): string {
  return rule.direction === TransferDirection.DEBIT
    ? rule.counterparty.nickname ?? formattedCounterpartyForRule(rule)
    : 'Amplify'
}

export function toAccountName (rule: TransferRule): string {
  return rule.direction === TransferDirection.DEBIT
    ? 'Amplify'
    : rule.counterparty.nickname ?? formattedCounterpartyForRule(rule) ?? ''
}

export function formatTransferRuleType (ruleType: TransferRuleType): string {
  switch (ruleType) {
    case TransferRuleType.DAILY: return 'Daily'
    case TransferRuleType.WEEKLY: return 'Weekly'
    case TransferRuleType.MONTHLY: return 'Monthly'
    case TransferRuleType.ONE_TIME: return 'Once'
    case TransferRuleType.TARGET_BALANCE :
    case TransferRuleType.MIN_TARGET_BALANCE: {
      return 'Balance-Based'
    }
    default:
      throw Error('Invalid transfer rule type')
  }
}

export function formatDateOfMonthToString (date: number): string {
  switch (date) {
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    case 29:
    case 30:
    case 31:
      return String(date) + 'th (or last of the month)'
    default:
      return String(date) + 'th'
  }
}

export function isBalanceRule (ruleType: TransferRuleType): boolean {
  return ruleType === TransferRuleType.TARGET_BALANCE || ruleType === TransferRuleType.MIN_TARGET_BALANCE
}

export function isUnaddressedTransferRuleError (rule: TransferRule): boolean {
  return rule.lastEvaluationStatus === TransferRuleLastEvalResult.ERROR &&
    rule.lastEvaluationErrorType !== TransferRuleErrorType.LOGIN_RECENTLY_RESOLVED
}
