/* eslint-disable max-len */
import React, { type ReactElement } from 'react'
import { Icon } from '@chakra-ui/react'
import { type IconProps } from './types'
import { IconSize } from '../theme/theme'

export default function CircleXIconTransparent ({ color, size = IconSize.MEDIUM }: IconProps): ReactElement {
  return (
    <Icon boxSize={size} display='flex' alignItems='center' justifyContent='center'>
      <svg width='100%' height='100%' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M12 20.5C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 16.9183 7.58172 20.5 12 20.5ZM12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5ZM8.29289 8.79289C8.68342 8.40237 9.31658 8.40237 9.70711 8.79289L12 11.0858L14.2929 8.79289C14.6834 8.40237 15.3166 8.40237 15.7071 8.79289C16.0976 9.18342 16.0976 9.81658 15.7071 10.2071L13.4142 12.5L15.7071 14.7929C16.0976 15.1834 16.0976 15.8166 15.7071 16.2071C15.3166 16.5976 14.6834 16.5976 14.2929 16.2071L12 13.9142L9.70711 16.2071C9.31658 16.5976 8.68342 16.5976 8.29289 16.2071C7.90237 15.8166 7.90237 15.1834 8.29289 14.7929L10.5858 12.5L8.29289 10.2071C7.90237 9.81658 7.90237 9.18342 8.29289 8.79289Z'/>
      </svg>
    </Icon>
  )
}
