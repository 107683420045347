import { type ApolloError, useQuery } from '@apollo/client'
import { useAltirStore } from '../store/useAltirStore'
import {
  type GetTransactionsForAccount_currentUser_selectedOrganization_transactions_transactions,
  type GetTransactionsForAccount,
  type GetTransactionsForAccountVariables
} from '@/graphql/__generated__/GetTransactionsForAccount'
import { GET_TRANSACTIONS_FOR_ACCOUNT } from '@/graphql/queries/transactions/GetTransactionsForAccount'
import { TransactionSortType } from '@/graphql/__generated__/globalTypes'

interface TransactionsForAccount {
  data: GetTransactionsForAccount_currentUser_selectedOrganization_transactions_transactions[]
  loading: boolean
  error: ApolloError | undefined
}

export function useFetchAccountTransactions (
  accountId: number,
  options: {
    startDate?: string
    endDate?: string
    limit?: number
    shouldIncludePending?: boolean
  }
): TransactionsForAccount {
  const organizationId = useAltirStore(state => state.selectedOrganizationState.selectedOrganizationId)
  const {
    data,
    loading,
    error
  } = useQuery<GetTransactionsForAccount, GetTransactionsForAccountVariables>(
    GET_TRANSACTIONS_FOR_ACCOUNT,
    {
      variables: {
        organizationId,
        filter: {
          bankAccountIds: [accountId],
          dateRange: { startDate: options.startDate, endDate: options.endDate }
        },
        limit: options.limit,
        sort: { type: TransactionSortType.Date, ascending: false },
        includePending: options.shouldIncludePending ?? false
      },
      fetchPolicy: 'network-only'
    }
  )
  return {
    data: data?.currentUser?.selectedOrganization?.transactions.transactions ?? [],
    loading,
    error
  }
}
