import React from 'react'
import { Flex } from '@chakra-ui/react'
import { type ReactElement } from 'react'
import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import { Color, IconSize } from '@/theme/theme'

export default function NavBackButton (): ReactElement {
  return (
    <Flex
      alignItems='center'
      _hover={{ cursor: 'pointer' }}
      onClick={() => { window.history.back() }}
    >
      <LeftArrowIcon color={Color.DARK_GREY} size={IconSize.SMALL}/>
    </Flex>
  )
}
