import React, { useState, type ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { AccountSelectorModal } from './AccountSelectorModal'
import { SelectableAccountRow } from './account_row/SelectableAccountRow'
import { Color } from '@/theme/theme'
import { type Account } from '@/types/types'
import { TransferDirection } from '@/graphql/__generated__/globalTypes'

export interface AccountSelectorComponentProps {
  amplifyAccount?: Account
  counterparties?: Account[]
  selectedCounterparty?: Account
  onSelectedCounterpartyChange?: (counterparty: Account | null) => void
  transferDirection?: TransferDirection
  refetch?: () => void
  customHeaderText?: string
  headerColor?: Color
}

export default function AccountSelectorComponent (
  {
    amplifyAccount,
    counterparties,
    onSelectedCounterpartyChange,
    selectedCounterparty,
    transferDirection,
    customHeaderText,
    headerColor = Color.DARK_GREY,
    refetch = () => {}
  }: AccountSelectorComponentProps
): ReactElement {
  const [isCounterpartySelectorModalOpen, setIsCounterpartySelectorModalOpen] = useState(false)
  function handleInputClick (): void {
    if (counterparties != null) {
      setIsCounterpartySelectorModalOpen(true)
    }
  }

  function handleCounterpartySelection (counterparty: Account): void {
    if (onSelectedCounterpartyChange != null) {
      onSelectedCounterpartyChange(counterparty)
    }
    setIsCounterpartySelectorModalOpen(false)
  }

  function getContent (): ReactElement | null {
    if (amplifyAccount != null) {
      return (
        <SelectableAccountRow
          account={amplifyAccount}
          isClickable={false}
        />
      )
    }
    if (selectedCounterparty != null) {
      return (
        <SelectableAccountRow
          account={selectedCounterparty}
          isClickable={true}
        />
      )
    }
    return <SelectableAccountRow isClickable={true} />
  }

  function getHeaderText (): string {
    if (customHeaderText != null) {
      return customHeaderText
    }
    const isAmplifyRow = amplifyAccount != null
    return (isAmplifyRow && transferDirection === TransferDirection.CREDIT) ||
      (!isAmplifyRow && transferDirection === TransferDirection.DEBIT)
      ? 'From'
      : 'To'
  }

  return (
    <Flex width='100%' flexDirection='column' gap={2}>
      <AccountSelectorModal
        isOpen={isCounterpartySelectorModalOpen}
        onClose={() => { setIsCounterpartySelectorModalOpen(false) }}
        onSelectCounterParty={handleCounterpartySelection}
        counterparties={counterparties}
        selectedCounterparty={selectedCounterparty}
        transferDirection={transferDirection}
        refetch={refetch}
      />
      <Text size='md' color={headerColor}>{getHeaderText()}</Text>
      <Flex width='100%' onClick={handleInputClick}>
        {getContent()}
      </Flex>
    </Flex>
  )
}
