import React, { type ReactElement } from 'react'
import { Text, InputRightElement, NumberInput, NumberInputField, InputGroup } from '@chakra-ui/react'
import { type Path, type FieldValues, type UseFormRegister } from 'react-hook-form'
import { stripDollarsAndDashesFromNumericString } from './utils'
import FormLabelWrapper from '../FormLabelWrapper'
import { type BaseFormInputProps } from '../types'
import { BorderRadius, Color, FormInputHeight } from '@/theme/theme'
import { isEmpty, isValidNumberString } from '@/utils/stringUtils'

export enum NumberInputSizeVariant {
  DEFAULT,
  HERO
}

type FormNumberInputProps<HookFormValues extends FieldValues> = {
  value?: string
  maxValue?: number
  maxNumberOfDigits?: number
  onChange?: (value: string) => void
  autoFormatRule?: (value: string) => string
  parseFormattedValue?: (value: string) => string
  // Fixed character or string indicating unit (ex %) to supplement the numeric amount
  unitSuffix?: string
  allowAutoComplete?: boolean
  // Hook form register function
  registerLabel?: Path<HookFormValues>
  register?: UseFormRegister<HookFormValues>
  registerRequired?: string | { value: boolean, message: string }

  // Styles
  variant?: NumberInputSizeVariant
} & BaseFormInputProps

export default function FormNumberInput<HookFormValues extends FieldValues> ({
  value,
  label,
  onChange,
  autoFormatRule = (value) => value,
  parseFormattedValue = stripDollarsAndDashesFromNumericString,
  unitSuffix,
  placeholder,
  isRequired,
  fieldName,
  fieldError,
  isDisabled = false,
  backgroundColor = Color.LIGHT_GREY,
  maxValue,
  maxNumberOfDigits,
  register,
  registerLabel,
  registerRequired,
  allowAutoComplete = true,
  variant = NumberInputSizeVariant.DEFAULT
}: FormNumberInputProps<HookFormValues>): ReactElement {
  function internalOnChange (newValue: string, onChange: (value: string) => void): void {
    // Parse value
    const parsedValue = parseFormattedValue(newValue)

    // Prevent non-digits
    if (!isEmpty(parsedValue) && !isValidNumberString(parsedValue)) return

    // Validate that form isn't being cleared by blur
    if ((value?.length ?? 0) - parsedValue.length > 1) return

    // Ensure value doesn't exceed max digits
    if (maxNumberOfDigits != null && parsedValue.length > maxNumberOfDigits) return

    onChange(parsedValue)
  }

  const isInErrorState = fieldError != null

  // Hook form registration
  const registeredForm = registerLabel != null && register != null &&
    register(registerLabel, {
      required: registerRequired,
      setValueAs: (v) => v === '' ? undefined : parseInt(v, 10)
    })

  const { fontSize, inputHeight } = getSizeProps(variant)
  return (
    <FormLabelWrapper
      fieldName={fieldName}
      label={label}
      isRequired={isRequired}
      errorMessage={fieldError}
    >
      <InputGroup alignItems='center'>
        <NumberInput
          onChange={onChange != null ? (value) => { internalOnChange(value, onChange) } : undefined}
          value={value != null ? autoFormatRule(value) : value}
          variant='filled'
          w='100%'
          isDisabled={isDisabled}
          max={maxValue}
          clampValueOnBlur={false}
        >
          <NumberInputField
            placeholder={placeholder}
            _hover={{ backgroundColor }}
            _focus={{ backgroundColor: Color.WHITE, borderColor: Color.DARK_GREY }}
            backgroundColor={backgroundColor}
            h={inputHeight}
            color={Color.DARK_BLUE}
            borderStyle={isInErrorState ? 'solid' : 'transparent'}
            borderColor={isInErrorState ? Color.ERROR_RED : backgroundColor}
            borderRadius={BorderRadius.CARD}
            autoComplete={allowAutoComplete ? 'on' : 'off'}
            px='24px'
            sx={{
              '::placeholder': {
                color: Color.DARK_GREY
              }
            }}
            fontSize={fontSize}
            {...registeredForm}
          />
          {unitSuffix != null &&
          <InputRightElement pt={2}>
            <Text fontSize={fontSize}>{unitSuffix}</Text>
            </InputRightElement>
          }
        </NumberInput>
      </InputGroup>
    </FormLabelWrapper>
  )
}

export function getSizeProps (variant: NumberInputSizeVariant): { fontSize: string, inputHeight: FormInputHeight } {
  switch (variant) {
    case NumberInputSizeVariant.DEFAULT:
      return {
        fontSize: 'md',
        inputHeight: FormInputHeight.DEFAULT
      }
    case NumberInputSizeVariant.HERO:
      return {
        fontSize: '4xl',
        inputHeight: FormInputHeight.HERO
      }
  }
}
