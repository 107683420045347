import { Divider, Flex, Text } from '@chakra-ui/react'
import React, { type ReactElement, useEffect } from 'react'
import {
  type GetAvailableStatements_availableStatements as Statement
} from '@/graphql/__generated__/GetAvailableStatements'
import {
  BorderRadius,
  Color
} from '@/theme/theme'

interface StatementSelectionComponentProps {
  index: number
  statement: Statement
  isSelected: boolean
  isCurrent: boolean | false
  setSelected: (i: number) => void
}

export default function StatementSelectionBox (
  {
    index,
    statement,
    isSelected,
    isCurrent,
    setSelected
  }: StatementSelectionComponentProps
): ReactElement {
  useEffect(() => {
    // load MTD statement by default
    setSelected(0)
  }, [])

  return (
    (
      <Flex
        key={statement.description}
        bg={Color.WHITE}
        marginRight={4}
        px={5}
        py={4}
        borderRadius={BorderRadius.CARD}
        flexDirection='column'
        borderWidth={2}
        borderColor={isSelected ? Color.DARK_GREY : Color.WHITE}
        onClick={() => { setSelected(index) }}
        _hover={{ cursor: 'pointer' }}
        height='100%'
      >
        <Text
          color={Color.DARK_BLUE}
          width={36}
        >
          {isCurrent ? 'Month to Date' : statement.description}
        </Text>
        <Divider
          my={2}
          borderWidth={1}
          borderColor={Color.DARK_GREY}
        />
        <Text
          fontSize='sm'
          color={Color.DARK_GREY}
        >
          {statement.startDate}-{isCurrent ? 'Today' : statement.endDate}
        </Text>
        {
            index !== 0 &&
            <Text
              paddingTop={1}
              fontSize='sm'
              color={Color.DARK_GREY}
            >
              Statement
            </Text>
          }
      </Flex>
    )

  )
}
