import React, { type ReactElement } from 'react'
import PopoverMenuHierarchicalMultiSelector,
{ type MenuSelectionItem, type MenuSelectionGroup }
  from '@/library/popoverMenu/PopoverMenuHierarchicalMultiSelector'
import { type GetTransactionsPageData_currentUser_selectedOrganization_financialAccounts as FinancialAccount }
  from '@/graphql/__generated__/GetTransactionsPageData'
import { nonNull } from '@/utils/arrayUtils'
import { getMask } from '@/utils/financialAccountUtils'

interface FlatMenuSelectionGroup {
  title: string
  menuOption: MenuSelectionItem
}
interface TransactionsBankAccountSelectorComponentProps {
  queryValue: number[]
  onSubmit: (bankAccountIds?: number[]) => void
  financialAccounts: FinancialAccount[]
  areFinancialAccountsLoading?: boolean
}

export default function TransactionsBankAccountSelectorComponent (
  { queryValue, onSubmit, financialAccounts, areFinancialAccountsLoading }:
  TransactionsBankAccountSelectorComponentProps
): ReactElement {
  const menuSelectionGroups = FlatMenuSelectionGroupToMenuSelectionGroupArray(
    financialAccountsToFlatMenuSelectionGroup(financialAccounts)
  )
  function handleSubmit (selectedItems: string[]): void {
    const bankAccountsToFilterBy = selectedItems.length > 0
      ? selectedItems.map(item => Number(item))
      : undefined
    onSubmit(bankAccountsToFilterBy)
  }

  return (
    <PopoverMenuHierarchicalMultiSelector
      popoverMenuTitle='Accounts'
      onSubmit={handleSubmit}
      menuSelectionGroups={menuSelectionGroups}
      isCheckBox={true}
      initialState={queryValue?.map(accountId => String(accountId)) ?? []}
      menuSelectionGroupsLoading={areFinancialAccountsLoading}
      allowInteriorScroll={true}
    />
  )
}

function financialAccountsToFlatMenuSelectionGroup (
  financialAccounts: FinancialAccount[]
): FlatMenuSelectionGroup[] {
  return financialAccounts
    // TODO (PAL or PJ): support external counterparties in the selector
    .filter(account => account.accountId != null && account.institution?.name != null)
    .map(financialAccount => {
      const institutionName = financialAccount.institution?.name
      const accountId = financialAccount.accountId
      const name = financialAccount.name
      const mask = getMask(financialAccount.lastFour ?? null)

      // TODO PAL create an accountSubType that is not formatted so we can choose when to add 'Account' afterwards
      const accountType = financialAccount.accountSubTypeFormatted?.replace(' Account', '')

      const textArray: string[] = nonNull([mask, accountType, name])
      if (accountId == null || institutionName == null) {
        throw Error(`Invalid Financial Account: ${String(financialAccounts)}`)
      }
      return { title: institutionName, menuOption: { id: String(accountId), textArray } }
    })
}

function FlatMenuSelectionGroupToMenuSelectionGroupArray (
  flatMenuOptionArray: FlatMenuSelectionGroup[]
): MenuSelectionGroup[] {
  return flatMenuOptionArray.reduce<MenuSelectionGroup[]>((previous, currentValue, index) => {
    const menuItem = previous.find(item => item.menuSelectionGroupHeading === currentValue.title)
    if (menuItem != null) {
      menuItem.menuSelectionItems.push(currentValue.menuOption)
    } else {
      previous.push({ menuSelectionGroupHeading: currentValue.title, menuSelectionItems: [currentValue.menuOption] })
    }
    return previous
  }, [])
}
