import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import ChooseAnAccountContent from './ChooseAnAccountContent'
import AccountRowContent from './AccountRowContent'
import { BorderRadius, Color } from '@/theme/theme'
import { AccountLinkStatus } from '@/graphql/__generated__/globalTypes'
import { type Account } from '@/types/types'
import { ERROR_BORDER } from '@/utils/errorUtils'

export interface AccountRowProps {
  account?: Account
  isClickable?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  isExternalTransfersEnabled?: boolean
}

export function SelectableAccountRow (
  {
    account: nullableAccount,
    isExternalTransfersEnabled = false,
    isClickable = true,
    isSelected = false,
    isDisabled = false
  }: AccountRowProps
): ReactElement {
  const isAccountLoginRequired = nullableAccount?.status === AccountLinkStatus.LOGIN_REQUIRED
  const border = isAccountLoginRequired
    ? ERROR_BORDER
    : isSelected ? `1.5px solid ${String(Color.BRIGHT_BLUE)}` : ''

  return (
    <Flex
      bg={Color.WHITE}
      border={border}
      borderRadius={BorderRadius.CARD}
      p={6}
      width='100%'
      _hover={{
        cursor: isClickable ? 'pointer' : 'auto',
        backgroundColor: isClickable ? Color.GREY : Color.WHITE
      }}
      alignItems='center'
    >
      {nullableAccount == null
        ? <ChooseAnAccountContent/>
        : <AccountRowContent
            account={nullableAccount}
            isDisabled={isDisabled}
            isExternalTransfersEnabled={isExternalTransfersEnabled}
          />}
    </Flex>
  )
}
