import { ApolloError } from '@apollo/client'
import { GraphQLErrorCode } from '../graphql/__generated__/globalTypes'
import { Color } from '@/theme/theme'
import { type ErrorContent, type ErrorWithContent } from '@/types/types'

export const ERROR_BORDER = `1.5px solid ${String(Color.ERROR_RED)}`

export enum ErrorCopy {
  UNAUTHORIZED_TITLE = 'Action not allowed',
  UNAUTHORIZED_SUBTITLE = `
    You do not have permission to perform this action. Contact your administrator to change your role.
  `,
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  TRY_AGAIN_LATER = 'Please try again later. If the issue persists, contact us.',
  UNABLE_TO_SUBMIT_FORM = 'Unable to submit form'
}

export function isDuplicateAccountError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.DUPLICATE_ACCOUNT
}

export function isAuthenticationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.AUTHENTICATION_FAILED
}

export function isAuthorizationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.AUTHORIZATION_ERROR
}

export function isMaximumTransferRuleExceededError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.MAX_TRANSFER_RULES_REACHED
}

export function isTransferRuleMissingParamsError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.TRANSFER_RULE_MISSING_PARAMS
}

export function isTransferRuleCircularDependencyError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.TRANSFER_RULE_CIRCULAR_DEPENDENCY
}

export function isEmailAlreadyExistsError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.EMAIL_ALREADY_EXISTS
}

export function isEmailInvalidError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.INVALID_EMAIL
}

export function isVerificationEmailExpiredError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.EMAIL_VERIFICATION_EXPIRED
}

export function isUserAlreadyHasOrganizationError (error?: ApolloError): boolean {
  return getErrorCode(error) === GraphQLErrorCode.USER_ALREADY_HAS_ORGANIZATION
}

export function getErrorMetadata (error: ApolloError): any {
  return error.graphQLErrors[0]?.extensions.metadata
}

export function getErrorCode (error?: ApolloError): GraphQLErrorCode | null {
  if (error == null) {
    return null
  }
  const graphQLErrors = error.graphQLErrors
  if (graphQLErrors.length < 1) return null

  const altirError = graphQLErrors.find(e => Object.keys(GraphQLErrorCode).includes(e.extensions?.code))
  if (altirError == null) return null

  return altirError.extensions.code
}

export function getUpdateUserError (error: ApolloError): ErrorWithContent {
  if (isEmailAlreadyExistsError(error)) {
    return {
      customContent: {
        title: ErrorCopy.UNABLE_TO_SUBMIT_FORM,
        subtitle: 'That email already exists in our system. Please try a different one.'
      },
      error
    }
  } else if (isEmailInvalidError(error)) {
    return {
      customContent: {
        title: ErrorCopy.UNABLE_TO_SUBMIT_FORM,
        subtitle: 'Please enter a valid email.'
      },
      error
    }
  }

  return {
    error
  }
}

export function getErrorContent (
  error?: Error | ErrorWithContent
): ErrorContent {
  const rootError = getRootError(error)
  if (isApolloError(rootError)) {
    if (isAuthorizationError(rootError)) {
      return {
        title: ErrorCopy.UNAUTHORIZED_TITLE,
        subtitle: ErrorCopy.UNAUTHORIZED_SUBTITLE
      }
    }
  }

  if (
    error != null &&
    isErrorWithContent(error) &&
    error.customContent != null
  ) {
    return { title: error.customContent.title, subtitle: error.customContent.subtitle }
  }

  // TODO (PJ): parse raw error and display content
  return {
    title: ErrorCopy.SOMETHING_WENT_WRONG,
    subtitle: ErrorCopy.TRY_AGAIN_LATER
  }
}

function getRootError (error?: Error | ErrorWithContent): Error | undefined {
  return isErrorWithContent(error) ? error.error : error
}

function isErrorWithContent (error?: Error | ErrorWithContent): error is ErrorWithContent {
  return (error as ErrorWithContent)?.error instanceof Error
}

function isApolloError (error?: Error): error is ApolloError {
  return (error as ApolloError) instanceof ApolloError
}
