import { gql } from '@apollo/client'
import { TRANSFER_RULE_FRAGMENT } from '../TransferRuleFrament'

export const FINANCIAL_ACCOUNT_EXTENDED_FRAGMENT = gql`
    ${TRANSFER_RULE_FRAGMENT}
    fragment FinancialAccountExtendedFragment on FinancialAccount {
        accountId
        counterpartyId
        franchiseGroupId
        name
        accountType
        accountSubTypeFormatted
        transferRules {
            ...TransferRuleFragment
        }
        lastFour
        achAccountNumber
        achRoutingNumber
        institution {
            name
            logoAssetUrl
        }
        liveBalance {
            availableBalance {
                amountFormatted
                amount
                currency
            }
            currentBalance {
                amountFormatted
                amount
                currency
            }
            # TODO PAL: create a new balanceUpdateAt field
            balanceUpdatedAt: updatedAt
        }
        transactionsUpdatedAt
        status
        connectionProvider
        defaultStoreLocation{
            id
            storeId
            name
        }
        plaidItemId
        plaidAccessToken
    }
`
