import React, { type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { type Recipient } from './RecipientDropDown'
import RecipientPaymentHistory from './RecipientPaymentHistory'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import RightArrowIcon from '@/library/icons/RightArrowIcon'
import { ROUTES } from '@/api/routes'
import TransferIcon from '@/library/icons/TransferIcon'
import { Color, IconSize } from '@/theme/theme'
import { Graphic } from '@/library/utility/Graphic'
import { HANDHOLDING_PEOPLE } from '@/theme/graphics'

interface RecipientDropDownContentProps {
  recipient: Recipient
}

export default function RecipientDropDownContent ({ recipient }: RecipientDropDownContentProps): ReactElement {
  const navigate = useNavigate()

  return (
    <Flex flexDir='column' gap={6}>
      {
            !recipient.isVerified &&
            <Flex w='100%' justifyContent='center'>
              <Flex maxW='276px'>
                <Flex flexDir='column' gap={4}>
                  <Graphic src={HANDHOLDING_PEOPLE} h='53px'/>
                  <Flex flexDir='column' gap={2} alignItems='center' textAlign='center'>
                    <Heading color={Color.DARK_BLUE} size='md'>This is a new recipient.</Heading>
                    <Text fontSize='sm'>
                      For your security, we are in the process of verifying this recipient.
                      This should not interfere with your ability to transfer.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
        }
      <RecipientPaymentHistory transactions={recipient.transactions}/>
      <Flex gap={4}>
        <Button
          text='Make Transfer'
          beforeIcon={<TransferIcon size={IconSize.SMALL}/>}
          onClick={() => { navigate(ROUTES.TRANSFER, { state: { recipientCounterpartyId: recipient.id } }) }}
          size={ButtonSize.SMALL}
        />
        <Button
          text='View All'
          variant={ButtonVariant.WHITE_OUTLINE}
          afterIcon={<RightArrowIcon/>}
          onClick={() => { navigate(ROUTES.TRANSACTIONS) }}
          size={ButtonSize.SMALL}
        />
      </Flex>
    </Flex>
  )
}
