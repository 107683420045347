import React, { type ReactElement } from 'react'
import { Center } from '@chakra-ui/react'
import ChecksFAQComponent from './components/ChecksFAQComponent'
import Page from '@/library/page/Page'

export default function ChecksPage (): ReactElement {
  return (
    <Page>
      <Center w='100%' gap={3}>
        <Center flex={2}>
          {/* TODO (PJ): Fetch data */}
        </Center>
        <Center flex={1}>
          <ChecksFAQComponent/>
        </Center>
      </Center>
    </Page>
  )
}
