import { useLazyQuery, useMutation } from '@apollo/client'
import { useState } from 'react'
import { GET_EXPORT_JOB_STATUS } from '@/graphql/queries/GetExportJobStatus'
import {
  type ExportJobStatus,
  type ExportJobStatusVariables,
  type ExportJobStatus_exportJob
} from '@/graphql/__generated__/ExportJobStatus'
import { CREATE_EXPORT_JOB } from '@/graphql/mutations/CreateExportJob'
import { type CreateExportJob, type CreateExportJobVariables } from '@/graphql/__generated__/CreateExportJob'

const POLL_INTERVAL = 200

// TODO PAL Pass enum from Back End
export enum JobStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export interface UseFileExportHookStates {
  createExportJob: (exportJobMutationVariables?: CreateExportJobVariables) => void
  fileDownloadUrl?: string
  error?: Error
  loading: boolean
}

/**
 * This hook triggers a mutation to create a File Export Job and the polls the jobId
 * until it reaches a Non-Pending state
 * @param defaultExportJobMutationVariables Variables required to create the export job
 * @param onSuccess Optional callback param that is triggered on a successful export job
 * @param onError Optional callback param that is triggered on an Unsuccessful export job
 * @returns
 *   createExportJob: this triggers the mutation to begin
 *   fileDownloadUrl: the url through which the resultant export job can be accessed
 *   error: error state
 *   loading: loading state
 */
export default function useFileExport (
  defaultExportJobMutationVariables?: CreateExportJobVariables,
  onSuccess?: (fileUrl: string) => void,
  onError?: (error: Error) => void
): UseFileExportHookStates {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string | undefined>(undefined)

  // Issue Mutation to create the file export job
  const [initiateExportJob] = useMutation<CreateExportJob, CreateExportJobVariables>(
    CREATE_EXPORT_JOB, {
      variables: defaultExportJobMutationVariables,
      onError: (error) => { handleError(error) },
      onCompleted: (data) => {
        void getJobStatus({ variables: { jobId: data?.export.id } })
      }
    })

  // Create a lazy query to poll for job status
  const [getJobStatus, { stopPolling: exportJobStatusStopPolling }] =
    useLazyQuery<ExportJobStatus, ExportJobStatusVariables>(GET_EXPORT_JOB_STATUS, {
      pollInterval: POLL_INTERVAL,
      onError: (error) => { setError(error) },
      onCompleted: (data) => {
        handleSuccessOrErrorOnJobStatus(
          data.exportJob,
          handleSuccess,
          handleError
        )
      }
    })

  function handleSuccess (exportJob?: ExportJobStatus_exportJob): void {
    exportJobStatusStopPolling()
    if (exportJob?.fileUrl == null) {
      setError(Error('Null file Url received'))
      return
    }
    setFileDownloadUrl(exportJob?.fileUrl)
    setLoading(false)
    if (onSuccess != null) onSuccess(exportJob?.fileUrl)
  }

  function handleError (error: Error): void {
    setError(error)
    exportJobStatusStopPolling()
    setLoading(false)
    if (onError != null) onError(error)
  }

  function handleSuccessOrErrorOnJobStatus (
    exportJob: ExportJobStatus_exportJob | null,
    onSuccess: (exportJob?: ExportJobStatus_exportJob) => void,
    onError: (error: Error) => void): void {
    if (exportJob == null) {
      onError(Error('Export Job is Null')); return
    }
    switch (exportJob?.status as JobStatus | undefined) {
      case JobStatus.FAILED:
        onError(Error('Export Job Failed'))
        break
      case JobStatus.SUCCESS:
        onSuccess(exportJob)
        break
      case JobStatus.PENDING:
      default:
        break
    }
  }

  function createExportJob (exportJobMutationVariables?: CreateExportJobVariables): void {
    setLoading(true)
    void initiateExportJob({ variables: exportJobMutationVariables })
  }
  return { createExportJob, fileDownloadUrl, error, loading }
}
