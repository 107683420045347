import React, { type ReactElement } from 'react'
import { Flex } from '@chakra-ui/react'
import CloseIcon from '../icons/CloseIcon'
import { BorderRadius, Color, IconSize } from '@/theme/theme'

interface ModalCloseHeaderProps {
  onClose: () => void
  iconSize?: IconSize
}

export default function ModalCloseHeader ({
  onClose,
  iconSize = IconSize.MEDIUM
}: ModalCloseHeaderProps): ReactElement {
  return (
    <Flex w='100%' justifyContent='end' alignItems='start'>
      <Flex
        onClick={onClose}
        _hover={{ cursor: 'pointer', bg: Color.GREY }}
        p={1}
        borderRadius={BorderRadius.BUTTON_HOVER}
        height='auto'
      >
        <CloseIcon color={Color.DARK_GREY} size={iconSize}/>
      </Flex>
    </Flex>
  )
}
