import React, { useState, type ReactElement } from 'react'
import { useQuery } from '@apollo/client'
import AddNewRecipientModal from './AddNewRecipientModal'
import RecipientsCardContent from './RecipientsCardContent'
import { Color, IconSize } from '@/theme/theme'
import {
  type GetRecentRecipientsVariables,
  type GetRecentRecipients
} from '@/graphql/__generated__/GetRecentRecipients'
import { GET_RECENT_RECIPIENTS } from '@/graphql/queries/GetRecipientsPageData'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import { CounterpartyType } from '@/graphql/__generated__/globalTypes'
import DropDownComponent from '@/library/layout/drop_down/DropDownComponent'
import PersonIcon from '@/library/icons/PersonIcon'

interface RecipientsCardProps {
  amplifyAccountId?: string
  franchiseGroupId: number
  onNewRecipientCreation: () => void
}

export default function RecipientsCard ({
  amplifyAccountId,
  franchiseGroupId,
  onNewRecipientCreation
}: RecipientsCardProps): ReactElement {
  const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false)

  const { data, loading, error, refetch: refetchRecentRecipients } =
    useQuery<GetRecentRecipients, GetRecentRecipientsVariables>(GET_RECENT_RECIPIENTS, {
      variables: {
        franchiseGroupId,
        counterpartyTypes: [
          CounterpartyType.EXTERNAL_VENDOR,
          CounterpartyType.EXTERNAL_PERSONAL
        ]
      }
    })

  const externalVendors = data?.currentUser?.franchiseGroup?.counterparties ?? []
  // Display most recent 3 vendors in this card
  const recentExternalVendors = externalVendors.slice(0, 3)

  // Feature flags
  const isWiresEnabled = data?.currentUser?.rolloutConfiguration?.enableWires === true

  return (
    <AltirSkeleton
      isLoading={loading}
      error={error}
      width='100%'
    >
      <AddNewRecipientModal
        isOpen={isAddRecipientModalOpen}
        onClose={() => { setIsAddRecipientModalOpen(false) }}
        amplifyAccountId={amplifyAccountId}
        franchiseGroupId={franchiseGroupId}
        onSuccess={() => {
          void onNewRecipientCreation
          void refetchRecentRecipients()
        }}
        isWiresEnabled={isWiresEnabled}
      />
      <DropDownComponent
        title='My Recipients'
        icon={<PersonIcon color={Color.DARK_BLUE} size={IconSize.SCHMEDIUM}/>}
        rightDescription={externalVendors.length > 0 ? `(${externalVendors.length})` : undefined}
        dropDownContent={
          <RecipientsCardContent
            recipients={recentExternalVendors}
            onAddNewRecipientClick={() => { setIsAddRecipientModalOpen(true) }}
          />
        }
      />
    </AltirSkeleton>
  )
}
