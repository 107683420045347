import { Heading, Stack } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'
import { useState } from 'react'
import { type CustomStatement } from './StatementTransactionListComponent'
import Button, { ButtonVariant } from '@/library/button/Button'
import { FormInput } from '@/library/form/text/FormInput'
import { FontWeight } from '@/theme/theme'

export interface ExportFormData {
  startDate: string
  endDate: string
}

interface CustomTimeFrameFormComponentProps {
  accountId?: string
  toggle: () => void
  setCustomStatement: (statement: CustomStatement) => void
}

export default function CustomTimeFrameFormComponent (
  {
    accountId,
    toggle,
    setCustomStatement
  }: CustomTimeFrameFormComponentProps): ReactElement {
  const [formData, setFormData] = useState<ExportFormData>({
    startDate: '',
    endDate: ''
  })

  function onChange (event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  async function handleExportFormSubmit (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()

    setCustomStatement({
      accountId,
      description: 'Custom',
      startDate: formData.startDate,
      endDate: formData.endDate
    })
    toggle()
  }
  return (
    <>
      <form onSubmit={handleExportFormSubmit}>
        <Heading
          fontWeight={FontWeight.MEDIUM}
          size='md'
          style={{ paddingLeft: '30%' }}
        >
          Custom Timeframe
        </Heading>
        <Stack spacing={5}>
          <FormInput
            fieldName='startDate'
            value={formData.startDate}
            label='From'
            onChange={onChange}
            placeholder='MM/DD/YYYY'
          />
          <FormInput
            fieldName='endDate'
            value={formData.endDate}
            label='To'
            onChange={onChange}
            placeholder='MM/DD/YYYY'
          />
          <Button text='Set Timeframe' variant={ButtonVariant.PRIMARY}/>
        </Stack>
      </form>
    </>
  )
}
