export function nonNull<T> (array: Array<T | null | undefined>): T[] {
  return array.filter(x => x != null).map(x => x as T)
}

/**
 *
 * @param array<T>
 * @returns Given an array [5, 5, 6, 7], return [5, 6, 7]
 *
 */
export function unique<T> (array: T[]): T[] {
  return Array.from(new Set(array))
}

export function getSortInput<T> (a: T, b: T, isAscending: boolean): [T, T] {
  return isAscending ? [a, b] : [b, a]
}
