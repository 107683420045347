import React, { useState, type ReactElement } from 'react'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import CreateBalanceRuleComponent from './CreateBalanceRuleComponent'
import CreateTimeRuleComponent from './CreateTimeRuleComponent'
import { Color } from '@/theme/theme'
import { TransferDirection } from '@/graphql/__generated__/globalTypes'
import { type GetTransferPageData, type GetTransferPageDataVariables }
  from '@/graphql/__generated__/GetTransferPageData'
import { GET_TRANSFER_PAGE_DATA } from '@/graphql/queries/GetTransferPageData'
import { convertAmplifyAccountToAccountObject, convertCounterpartiesToAccountObjects } from '@/utils/transferUtils'
import AltirSkeleton from '@/library/loading/AltirSkeleton'
import SlideSelect from '@/library/slide_select/SlideSelect'
import { useAltirStore } from '@/hooks/store/useAltirStore'
import { GenericTransferRuleType } from '@/utils/transferRuleUtils'

export interface CreateTransferRuleGenericComponentProps {
  header: string
  subtext: string
  ruleType: GenericTransferRuleType
  preSelectedCounterpartyId?: string
}

export default function CreateTransferRuleGenericComponent (
  {
    header,
    subtext,
    ruleType,
    preSelectedCounterpartyId
  }: CreateTransferRuleGenericComponentProps
): ReactElement {
  const franchiseGroupId = useAltirStore((state) => state.selectedFranchiseGroupId)

  const {
    data,
    loading: pageLoading,
    error: pageDataError
    /* TODO refetch */
  } = useQuery<GetTransferPageData, GetTransferPageDataVariables>(
    GET_TRANSFER_PAGE_DATA,
    {
      skip: franchiseGroupId == null,
      variables: {
        franchiseGroupId
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const amplifyAccount = convertAmplifyAccountToAccountObject(data?.amplifyAccount ?? undefined)
  const counterparties = convertCounterpartiesToAccountObjects(data?.counterparties)
  const [transferDirection, setTransferDirection] = useState(TransferDirection.DEBIT)

  function handleDirectionSwitch (): void {
    if (transferDirection === TransferDirection.DEBIT) {
      setTransferDirection(TransferDirection.CREDIT)
    }
    if (transferDirection === TransferDirection.CREDIT) {
      setTransferDirection(TransferDirection.DEBIT)
    }
  }

  return (
    <AltirSkeleton isLoading={pageLoading} error={pageDataError}>
      <Flex direction='column' gap={5} w='100%'>
        <Flex direction='column' gap={3}>
          <Heading color={Color.DARK_BLUE} size='md'>
            {header}
          </Heading>
          <Text
            fontSize='md'
            color={Color.DARK_GREY}
            textAlign='left'
            whiteSpace='pre-line'
          >
            {subtext}
          </Text>
        </Flex>
        <SlideSelect
          selectedValue={transferDirection}
          options={[TransferDirection.DEBIT, TransferDirection.CREDIT]}
          handleSelection={_value => { handleDirectionSwitch() }}
          formatValue={(value: string) => {
            return value === TransferDirection.CREDIT ? 'Out of Amplify' : 'Into Amplify'
          }}
        />
        {ruleType === GenericTransferRuleType.BALANCE &&
        <CreateBalanceRuleComponent
          transferDirection={transferDirection}
          amplifyAccount={amplifyAccount}
          counterparties={counterparties}
          preSelectedCounterpartyId={preSelectedCounterpartyId}
          updateRuleType={handleDirectionSwitch}
        />}
        {ruleType === GenericTransferRuleType.TIME &&
        <CreateTimeRuleComponent
          transferDirection={transferDirection}
          amplifyAccount={amplifyAccount}
          counterparties={counterparties}
          preSelectedCounterpartyId={preSelectedCounterpartyId}
        />}
      </Flex>
    </AltirSkeleton>
  )
}
