import React, { type ReactElement, type ReactNode } from 'react'
import { Th } from '@chakra-ui/react'
import { Color } from '../theme/theme'

export interface TableHeaderProps {
  text?: string
  children?: ReactNode
}

export default function TableHeader ({ text, children }: TableHeaderProps): ReactElement {
  return (
    <Th
      color={Color.DARK_BLUE}
      textTransform='none'
      pl={6}
    >
      {text != null ? text : children}
    </Th>
  )
}
