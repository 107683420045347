import React, { type ReactElement } from 'react'
import { Link, Text } from '@chakra-ui/react'
import { Color, FontWeight } from '@/theme/theme'

interface LinkTextProps {
  text: string
  href: string
}

export default function LinkText ({ text, href }: LinkTextProps): ReactElement {
  return (
    <Text
      as='span'
      fontWeight={FontWeight.NORMAL}
      color={Color.BRIGHT_BLUE_HOVER}
    >
      <Link href={href} isExternal={true}>
        {text}
      </Link>
    </Text>
  )
}
