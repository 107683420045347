import { type ReactNode } from 'react'

export enum DropDownVariant {
  DARK,
  DEFAULT,
  RED,
  GRAY
}

export interface BaseDropDownProps {
  // Header Components
  title?: string
  subtitle?: string
  rightDescription?: string
  icon?: ReactNode
  rightCallToActionComponent?: ReactNode

  // Custom Header (Replaces above props)
  customHeader?: ReactNode

  // State
  isComplete?: boolean
  isDisabled?: boolean
  hasNotification?: boolean
  disabledTooltipText?: string
  dropDownContent?: ReactNode

  // Styles
  borderStyle?: string
  padding?: number
  variant?: DropDownVariant
}
