import React, { type ReactElement } from 'react'
import { Flex, MenuItemOption, MenuOptionGroup, Text } from '@chakra-ui/react'
import MenuHeadingMultiSelect from './PopoveMenuHeadingMultiSelect'
import { type MenuSelections, type MenuSelectionItem } from '../PopoverMenuHierarchicalMultiSelector'
import CircleCheckSolidIcon from '../../icons/CircleCheckSolidIcon'
import CircleEmptyIcon from '../../icons/CircleEmptyIcon'
import { Color } from '@/theme/theme'

interface PopoverMenuSelectionGroupProps {
  menuSelectionGroupHeading?: string
  updateSelection: (value: string[], menuSelectionItems: MenuSelectionItem[]) => void
  menuSelectionItems: MenuSelectionItem[]
  selectedItems: MenuSelections
  isCheckBox: boolean
}

export default function PopoverMenuSelectionOptions (
  {
    menuSelectionGroupHeading,
    updateSelection,
    menuSelectionItems,
    isCheckBox,
    selectedItems
  }: PopoverMenuSelectionGroupProps
): ReactElement {
  function handleSelect (values: string | string[]): void {
    if (Array.isArray(values)) {
      updateSelection(values, menuSelectionItems)
    }
  }

  const hasHeading = menuSelectionGroupHeading != null
  return (
    <MenuOptionGroup
      title={menuSelectionGroupHeading}
      hidden={isCheckBox} // hide on check box to allow multi-select
      type={isCheckBox ? 'checkbox' : 'radio'}
      value={selectedItems}
      onChange={handleSelect}
    >
      {/* Only Display Multi Selection on check box mode */}
      {isCheckBox && hasHeading &&
        <MenuHeadingMultiSelect
          title={menuSelectionGroupHeading}
          selectedItems={selectedItems}
          menuOptions={menuSelectionItems}
          updateSelection={handleSelect}
        />}

      {menuSelectionItems.map(({ id, textArray }, index) => {
        const isSelected = selectedItems.includes(id)
        return (
        // We set the icon to null to suppress the built in chakra icon
          <MenuItemOption key={index} icon={null} isChecked={isSelected} value={id}>
            <Flex gap={4} pl={hasHeading ? 4 : 0}>
              {isSelected
                ? <CircleCheckSolidIcon color={Color.BRIGHT_BLUE} />
                : <CircleEmptyIcon color={Color.DARK_GREY} />}
              <Flex flexDir='row' gap={4} width='full' >
                {textArray.map((text, index) => {
                  return (
                    <Flex key={text} w={index === textArray.length - 1 ? undefined : 24}>
                      <Text textAlign='left' color={Color.DARK_BLUE} noOfLines={1} maxW={52}>{text}</Text>
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>
          </MenuItemOption>
        )
      })}
    </MenuOptionGroup>
  )
}
