import React, { useState, type ReactElement } from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import TransactionsBankAccountSelectorComponent from './TransactionsBankAccountSelectorComponent'
import TransactionsCategorySelectorComponent from './TransactionsCategorySelectorComponent'
import TransactionDownloadComponent from './transaction_downloads/TransactionDownloadComponent'
import TransactionsAmountFilterComponent from './TransactionsAmountFilterComponent'
import TransactionsSortByComponent from './TransactionsSortByComponent'
import TransactionsDateFilterComponent from './TransactionsDateFilterComponent'
import { type TransactionsQueryVariables } from './TransactionsComponent'
import TransactionsBusinessSelectorComponent from './TransactionsBusinessSelectorComponent'
import { type GetTransactionsPageData } from '@/graphql/__generated__/GetTransactionsPageData'
import Button, { ButtonSize, ButtonVariant } from '@/library/button/Button'
import SearchBar from '@/library/form/text/SearchBar'
import CloseThinIcon from '@/library/icons/CloseThinIcon'
import {
  type NumberRangeInput,
  type DerivedTransactionCategory, type TransactionSortInput, TransactionSortType, type DateRangeInput
} from '@/graphql/__generated__/globalTypes'

interface TransactionsSortAndFilterComponentProps {
  transactionsPageData?: GetTransactionsPageData
  isPageDataLoading?: boolean
  transactionsQueryVariables: TransactionsQueryVariables | undefined
  updateTransactionsQueryVariables: (variablesPartial: TransactionsQueryVariables) => void
  totalTransactionCount?: number
}

export default function TransactionsSortAndFilterComponent (
  {
    transactionsPageData,
    isPageDataLoading,
    transactionsQueryVariables,
    updateTransactionsQueryVariables,
    totalTransactionCount
  }: TransactionsSortAndFilterComponentProps
): ReactElement {
  const sort = transactionsQueryVariables?.sort
  const filter = transactionsQueryVariables?.filter
  const financialAccounts = transactionsPageData?.currentUser?.selectedOrganization?.financialAccounts
  const businesses = transactionsPageData?.currentUser?.selectedOrganization?.franchiseGroups
  const organizationId = transactionsPageData?.currentUser?.selectedOrganization?.id
  const [searchText, setSearchText] = useState <string | undefined>(filter?.keyword ?? undefined)

  const isBusinessSelectorVisible = (businesses?.length ?? 0) > 0

  function clearTransactionsQueryVariables (): void {
    updateTransactionsQueryVariables({ filter: { }, sort: { type: TransactionSortType.Date, ascending: false } })
    setSearchText(undefined)
  }

  function searchRawText (searchTerm?: string): void {
    updateTransactionsQueryVariables({ sort, filter: { ...filter, keyword: searchTerm } })
  }
  function filterTransactionsByBusiness (franchiseGroupIds?: number[]): void {
    updateTransactionsQueryVariables({ sort, filter: { ...filter, franchiseGroupIds } })
  }

  function filterTransactionsByBankAccount (bankAccountIds?: number[]): void {
    updateTransactionsQueryVariables({ sort, filter: { ...filter, bankAccountIds } })
  }

  function filterTransactionsByCategoryLabels (categoryLabels?: DerivedTransactionCategory[]): void {
    updateTransactionsQueryVariables({ sort, filter: { ...filter, categoryLabels } })
  }

  function filterTransactionsByAmountRange (amount?: NumberRangeInput): void {
    updateTransactionsQueryVariables({ sort, filter: { ...filter, amount } })
  }
  function sortResults (transactionsSortInput: TransactionSortInput): void {
    updateTransactionsQueryVariables({
      sort: {
        type: transactionsSortInput.type,
        ascending: transactionsSortInput.ascending
      },
      filter
    })
  }

  function filterTransactionsByDateRange (dateRange?: DateRangeInput): void {
    updateTransactionsQueryVariables({ filter: { ...filter, dateRange } })
  }
  return (
    <Grid templateRows='2' gap={2}>
      {/* Top Row */}
      <GridItem rowSpan={1} >
        <Grid templateColumns='repeat(9, 1fr)' gap={2} alignItems='center'>

          {/* Search */}
          <GridItem colSpan={5}>
            <SearchBar
              onChange={setSearchText}
              onSubmit={searchRawText}
              queryValue={searchText}
            />
          </GridItem>

          {/* Sort By */}
          <GridItem colSpan={2}>
            <TransactionsSortByComponent onChange={sortResults} queryValue={sort ?? undefined} />
          </GridItem>

          {/* Download */}
          <GridItem colSpan={2}>
            <TransactionDownloadComponent
              totalTransactionCount={totalTransactionCount}
              queryVariables={transactionsQueryVariables}
              isPageDataLoading={isPageDataLoading}
              organizationId={organizationId}
            />
          </GridItem>
        </Grid>
      </GridItem>
      {/* Bottom Row */}
      <GridItem rowSpan={1}>
        <Flex flexDir='row' gap={2}>
          <Grid templateColumns={`repeat(${isBusinessSelectorVisible ? 5 : 4}, minmax(0,1fr))`} width='100%' gap={2}>

            {/* Business */}
            {isBusinessSelectorVisible &&
            <GridItem colSpan={1}>
              <TransactionsBusinessSelectorComponent
                businesses={businesses ?? []}
                areFinancialAccountsLoading={isPageDataLoading}
                onSubmit={filterTransactionsByBusiness}
                queryValue={filter?.franchiseGroupIds ?? []}
              />
            </GridItem>}

            {/* Accounts */}
            <GridItem colSpan={1}>
              <TransactionsBankAccountSelectorComponent
                financialAccounts={financialAccounts ?? []}
                areFinancialAccountsLoading={isPageDataLoading}
                onSubmit={filterTransactionsByBankAccount}
                queryValue={filter?.bankAccountIds ?? []}
              />
            </GridItem>

            {/* Amount */}
            <GridItem colSpan={1}>
              <TransactionsAmountFilterComponent
                queryValue={filter?.amount ?? undefined}
                onSubmit={filterTransactionsByAmountRange}
              />
            </GridItem>

            {/* Dates */}
            <GridItem colSpan={1}>
              <TransactionsDateFilterComponent
                queryValue={filter?.dateRange ?? undefined}
                onSubmit={filterTransactionsByDateRange}
              />
            </GridItem>

            {/* Category */}
            <GridItem colSpan={1}>
              <TransactionsCategorySelectorComponent
                onSubmit={filterTransactionsByCategoryLabels}
                queryValue={filter?.categoryLabels ?? undefined}
              />
            </GridItem>
          </Grid>
          {/* Clear Query */}
          {/* <Flex align='end' px={2}> */}
          <Button
            beforeIcon={<CloseThinIcon />}
            variant={ButtonVariant.LINK_TRANSPARENT}
            size={ButtonSize.MEDIUM}
            text='Clear All'
            onClick={clearTransactionsQueryVariables}
          />
          {/* </Flex> */}
        </Flex>

      </GridItem>
    </Grid>
  )
}
