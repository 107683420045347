import { gql } from '@apollo/client'

export const CREATE_EXTERNAL_COUNTERPARTY = gql`
mutation CreateExternalCounterparty(
    $accountHolderFullName: String!, 
    $accountNickname: String!, 
    $accountNumber: String!, 
    $amplifyAccountId: String!, 
    $counterpartyType: CounterpartyType!, 
    $documentLink: String!, 
    $routingNumber: String!,
    $achSpecificFields: AchParams
    $wireSpecificFields: WireParams
    ) {
    createExternalCounterparty(
        accountHolderFullName: $accountHolderFullName, 
        accountNickname: $accountNickname, 
        accountNumber: $accountNumber, 
        amplifyAccountId: $amplifyAccountId, 
        counterpartyType: $counterpartyType, 
        documentLink: $documentLink, 
        routingNumber: $routingNumber,
        achSpecificFields: $achSpecificFields,
        wireSpecificFields: $wireSpecificFields
    )
}
`
